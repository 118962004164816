import React from 'react';
import { Link } from 'react-router-dom';
import './CdsTitle.css';
import { UserDetails } from '../utility';

type props = {
    iconClass: string;
    title: string;
    link: string;
    linkto: string;
    headerDataTestId?: string;
    linkDataTestId?: string;
};

const CdsTitle: React.FC<props> = (props) => {
    const refresPage = () => {
        window.location.reload();
    };
    const userData = UserDetails();

    return (
        <>
            <div className="row my-3 justify-content-between">
                <div className="col-auto">
                    <div className="row g-2">
                        <div className="col-auto">
                            <h3
                                data-testid={props.headerDataTestId}
                                className={props.iconClass}
                            >
                                {' '}
                                {props.title}{' '}
                            </h3>
                        </div>
                        <div className="col-auto">
                            <h3
                                data-testid="btnRefresh"
                                onClick={() => refresPage()}
                            >
                                <i className="bi bi-arrow-clockwise"></i>
                            </h3>
                        </div>
                    </div>
                </div>
                {userData.RoleID == '5' && props.link =='Add Workflow'?null: (
                <div className="col-auto marginright">
                    <h3 className="h4">
                        <Link
                            data-testid={props.linkDataTestId}
                            to={props.linkto}
                        >
                            {props.link}
                        </Link>
                    </h3>
                </div>
                )}
            </div>
        </>
    );
};

export default CdsTitle;
