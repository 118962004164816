import axios from 'axios';
import { type } from 'os';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FIND_ALL_USER } from '../../../sagas/types/user';
import { RootState } from '../../../sagas/slice/rootReducer';
import { useFormik } from 'formik';
import CdsTitle from '../../../components/CdsTitle';
import { UserDetails } from '../../../utility';

type UserListProps = {};

const UserList: React.FC<UserListProps> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const goToCreateUser = () => {
        navigate('/cp/users/createuser', { replace: true });
    };
    const initialValue = {
        UserName: '',
    };

    useEffect(() => {
        
        //window.location.reload();
        const userData = UserDetails();
        if (userData.RoleID == '5') {
            // admin ??
            dispatch({
                type: FIND_ALL_USER,
                reqData: {
                    UserName: '',
                    CompanyID: userData.CompanyID,
                },
            });
        } else {
            // customer
            dispatch({
                type: FIND_ALL_USER,
                reqData: {
                    UserName: '',
                    CompanyID: userData.CompanyID,
                },
            });
        }
    }, [dispatch]);

    const rows = useSelector((state: RootState) => state.users.users);
    console.log(rows);

    const myFormik = useFormik({
        initialValues: initialValue,
        onSubmit: (values) => {
            // const body = {
            //     UserName: userData.UserName,
            //     CompanyID: userData.CompanyID,
            // };
            
            console.log(values);
            searchUser(values.UserName);
        },
    });
    const searchUser = (UserName: string) => {
        
        const userData = UserDetails();
        dispatch({
            type: FIND_ALL_USER,
            reqData: {
                UserName: UserName,
                CompanyID: userData.CompanyID,
            },
        });
    };
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;

    return (
        <div className="container-fluid">
            <CdsTitle
                linkto="/cp/users/createuser"
                title="User"
                iconClass="bi bi-people"
                link="Add User"
                headerDataTestId = 'UserTitle'
                linkDataTestId = 'addUserlink'
            ></CdsTitle>
            <div className="card mt-3  rounded-3">
                <div className="card-header">
                    <form
                        onSubmit={handleSubmit}
                        className="row my-2 justify-content-between"
                    >
                        <div className="col">
                            <div className="input-group">
                                <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                >
                                    <i className="bi bi-search"></i>
                                </span>
                                <input
                                     data-testid = "searchUser"
                                    type="text"
                                    className="form-control"
                                    id="UserName"
                                    name="UserName"
                                    value={values.UserName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter User Name"
                                ></input>
                            </div>
                        </div>
                        <div className="col-2">
                            <button
                             data-testid = "btnSearch"
                                type="submit"
                                className="w-100 btn btn-default"
                            >
                                Search
                            </button>
                        </div>
                    </form>
                </div>
                <div className="card-body">
                    <table data-testid ="Usertable" className="table table-striped">
                        <thead>
                            <tr className="">
                                <th scope="col">
                                    <h6 className="my-2 semi-bold">
                                        User Name
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">
                                        Company Name
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">
                                        User Email
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">
                                        User Mobile
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">
                                        Role Name
                                    </h6>
                                </th>
                                {/* <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">Status</h6>
                                </th> */}
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">Edit</h6>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows?.map((item: any,index:number) => {
                                return (
                                    <tr data-testid={`user-row-${index}`}>
                                        <td data-testid={`user-username-${index}`}>{item.UserName}</td>
                                        <td data-testid={`user-Customername-${index}`}>{item.CustomerName}</td>
                                        <td data-testid={`user-Email-${index}`}>{item.UserEmail}</td>
                                        <td data-testid={`user-Mobile-${index}`}>{item.UserMobile}</td>
                                        <td data-testid={`user-Rolename-${index}`}>{item.RoleName}</td>
                                        {/* <td>{item.UserStatus}</td> */}
                                        <td>
                                            {
                                                <Link data-testid={`btnEdit-${index}`}
                                                    to={`/cp/users/edituser/${item.UserID}`}
                                                >
                                                    <button  className="btn">
                                                        <i className="fa-2x bi bi-pencil-square"></i>
                                                    </button>
                                                </Link>
                                            }
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default UserList;
