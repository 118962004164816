import { all, call, put, takeLatest } from "redux-saga/effects";
import { createUserRequest, findAllUsersRequest, findUserByIdRequest, updateUserRequest } from "../requests/User";
import User from "../../model/UserModel";
import { addUserFailed, addUserSuccess, fetchAllUserFailed, fetchAllUserSuccess, findAllUsersSlice, resetSaveUser, triggerSaveUser } from "../slice/user/Users";
import { CREATE_USER, FIND_ALL_USER, FIND_USER_BY_ID, UPDATE_USER } from "../types/user";
import { setUserSlice } from "../slice/user/User";
import { getErrorMessage } from "../../utility";


  function* fetchUserListSaga(action: any) {
    try {
        
      const response: Array<User> = yield call(findAllUsersRequest, action.reqData);
      yield put(fetchAllUserSuccess(response));
    } catch (e: any) {
      yield put(fetchAllUserFailed(e));
    }
  }
  export function* findUserByIdSaga(action) {
    
    yield put(resetSaveUser());
    const response = yield call(findUserByIdRequest, action.id) ;
    
    yield put(setUserSlice(response));
}
export function* createUserSaga(action) {
  try {
    
      yield triggerSaveUser();
      console.log(action.user);
      const response = yield createUserRequest(action.user);
      if (response) {
          yield put(addUserSuccess());
      } else {
          yield put(
              addUserFailed(getErrorMessage('Error in creating new user.'))
          );
      }
  } catch (error) {
      addUserFailed(getErrorMessage('Error in creating new user.', error));
  }
}
export function* updateUserSaga(action) {
  try {
      yield put(triggerSaveUser());
      console.log(action.user);
      
      const response = yield updateUserRequest(action.user);
      if (response) {
          yield put(addUserSuccess());
      } else {
          yield put(
              addUserFailed(getErrorMessage('Error in updating user.'))
          );
      }
  } catch (error) {
      addUserFailed(getErrorMessage('Error in updating user.', error));
  }
}


function* userSaga(){
yield all ([takeLatest(FIND_ALL_USER , fetchUserListSaga)]);
yield all ([takeLatest(FIND_USER_BY_ID , findUserByIdSaga)]);
yield all ([takeLatest(CREATE_USER , createUserSaga)]);
yield all ([takeLatest(UPDATE_USER , updateUserSaga)]);

}

export default userSaga;