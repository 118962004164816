import { boolean } from 'yup';
import Workflow from '../../model/WorkflowModel';
import axios from 'axios';
import API from '../../constants/Api';
import { ApiResponse } from '../../model/ApiResponseModel';
import WorkflowStatus from '../../model/WorkflowStatusModel';

export async function createWorkflowRequest(
    payload: Workflow
): Promise<string> {
    const body = {
        FileTransmission: payload.FileTransmission,
        TransmissionTypeID: payload.FileTransmissionID,
        NeedEncryption: payload.NeedEncryption,
        ForwardFlow: payload.ForwardFlow,
        ReverseFlow:payload.ReverseFlow,
        FilesInBankFormat: payload.FilesInBanksFormat,
        OperatingSystemID: payload.OperatingSystemID,
        ERPComment: payload.ErpComment,
        AdditionalRequirement: payload.AdditionalRequirement,
        UserID: payload.UserID,
        WorkflowStatus: payload.WorkflowStatus,
        IsHeaderPresent: payload.IsHeaderPresent,
        FileType: payload.FileType,
    };
    return await axios({
        method: API.TYPE.POST,
        url: API.WORKFLOW.CREATE_WORKFLOW,
        data: body,
    }).then(
        (response) => {
            
            console.log(response.data);
            return response.data;
        },
        (err) => {
            return false;
        }
    );
}
export async function workFlowListRequest(
    requestPayload: any
): Promise<ApiResponse> {
    
    const body = {
        CompanyID: requestPayload.CompanyID,
        WorkflowNo: requestPayload.WorkflowNo,
    };
    const result: ApiResponse = {
        data: null,
        error: null,
    };
    return await axios({
        method: API.TYPE.POST,
        url: API.WORKFLOW.WORKFLOW_LIST,
        data: body,
    }).then(
        (response) => {
            result.data = response;
            return result;
        },
        (err) => {
            result.error = {
                message: 'stack-trace',
                stackTrace: err.message,
            };
            return result;
        }
    );
}
export async function updateWorkflowStatusRequest(
    payload: WorkflowStatus
): Promise<ApiResponse> {
    const body = {
        StatusId: payload.StatusId,
        WorkflowID: payload.WorkflowNo,
        CompanyID: payload.CompanyID,
        CustomerName: payload.CustomerName
    };
    
    const result: ApiResponse = {
        data: null,
        error: null,
    };
    return await axios({
        method: API.TYPE.POST,
        url: API.WORKFLOW.UPDATE_WORKFLOW_STATUS,
        data: body,
    }).then(
        (response) => {
            
            result.data = response;
            return result;
        },
        (err) => {
            result.error = {
                message: 'stack-trace',
                stackTrace: err.message,
            };
            
            return result;
        }
    );
}
