import XmlMapper from './FileMapper';
import { mockXml } from '../../constants/MockXml';
import { useEffect, useState } from 'react';
import '@nosferatu500/react-sortable-tree/style.css';
import FieldMapper from './FieldMapper';
import { Step, Stepper } from 'react-form-stepper';
import CustomFieldMapper from './CustomFieldMapper';
import CdsButton from '../../components/CdsButton';
import CdsModal from '../../components/CdsModal';
import { generateTreeNew, getParserObject } from './template-mapper-utility';
import { SortableTreeNode } from '../../model/SortableTreeNodeModel';
import TemplateFieldMapperFormDialog from './TemplateFieldMapperFormDialog';
import { CustomerAndBankFieldMapperInfo } from '../../model/CustomerAndBankFieldMapperInfo';
import { CustomerTemplateModel } from '../../model/CustomerTemplateModel';
import {
    FAKE_BANK_TEMPLATE_FIELDS,
    FAKE_BANK_TEMPLATE_MAPPED_FIELDS,
} from '../../constants/MockData';
import { useDispatch, useSelector } from 'react-redux';
import {
    FIND_ALL_CUSTOMER_TEMPLATE_CONFIGURED_HEADERS,
    FIND_ALL_CUSTOMER_TEMPLATE_HEADERS,
    FIND_BANK_TEMPLATE,
} from '../../sagas/types/template-mapper';
import { RootState } from '../../sagas/slice/rootReducer';
import axios from 'axios';

type FileMapperContainerProps = {};
const FileMapperContainer: React.FC<FileMapperContainerProps> = (props) => {
    const dispatch = useDispatch();
    const [customerTemplateFields, setCustomerTemplateFields] = useState<
        Array<CustomerTemplateModel>
    >([]);
    const [customerTemplateMappedFields, setCustomerTemplateMappedFields] =
        useState<Array<CustomerTemplateModel>>([]);
    const [customerBankFieldMapperInfo, setcustomerBankFieldMapperInfo] =
        useState<Array<CustomerAndBankFieldMapperInfo>>([]);
    const [treeData, setTreeData] = useState<Array<SortableTreeNode>>([]);
    const [activeStepId, setActiveStepId] = useState<number>(0);

    const [
        selectedCustomerAndBankFieldMapperInfo,
        setSelectedCustomerAndBankFieldMapperInfo,
    ] = useState<CustomerAndBankFieldMapperInfo>(null);

    const [
        customerAndBankFieldMapperInfoList,
        setcustomerAndBankFieldMapperInfoList,
    ] = useState<Array<CustomerAndBankFieldMapperInfo>>(null);

    const [selectedTreeNodeInfo, setSelectedTreeNodeInfo] =
        useState<SortableTreeNode>(null);
    const [mappedFields, setMappedFields] = useState<Array<string>>(
        new Array<string>()
    );
    const [isDisplayMapperModal, setIsDisplayMapperModal] =
        useState<boolean>(false);

    useEffect(() => {
        if (activeStepId === 1) {
            handleXmlParser();
        }
    }, [activeStepId]);

    const { allHeaders, error, isFetched } = useSelector(
        (state: RootState) => state.customerTemplateAllHeaders
    );
    const {
        configuredHeaders,
        configuredHeaderFetchError,
        isConfiguredHeadersFetched,
    } = useSelector(
        (state: RootState) => state.customerTemplateConfiguredHeaders
    );
    useEffect(() => {
        setCustomerTemplateFields(FAKE_BANK_TEMPLATE_FIELDS); // tbd
        setCustomerTemplateMappedFields(FAKE_BANK_TEMPLATE_MAPPED_FIELDS); // tbd
        dispatch({
            type: FIND_ALL_CUSTOMER_TEMPLATE_HEADERS,
            data: { id: 2 },
        });
        dispatch({
            type: FIND_ALL_CUSTOMER_TEMPLATE_CONFIGURED_HEADERS,
            data: { id: 2 },
        });
    }, [dispatch]);

    useEffect(() => {
        const tbdCB = async () => {
            dispatch({
                type: FIND_BANK_TEMPLATE,
                data: { id: 2 },
            });
        };
        dispatch({
            type: FIND_BANK_TEMPLATE,
            data: { id: 1 },
        });
        //tbdCB();
    }, [dispatch]);
    const handleDownloadFile = () => {
        // with axios library
        axios({
            url: 'http://paybrijapi.copiacs.com/api/bank-template',
            method: 'POST',
            data: {
                CustomerId: '2',
            },
        }).then(
            (response) => {
                getParserObject()
                    .parseStringPromise(response.data)
                    .then(function (result) {
                        console.dir(result);
                        console.log('Done');
                    })
                    .catch(function (err) {
                        // Failed
                    });
            },
            (err) => {
                console.log(err);
            }
        );
    };
    const handleXmlParser = () => {
        getParserObject().parseString(mockXml, (err, result) => {
            if (result) {
                const treeData = generateTreeNew(
                    result,
                    null,
                    handleFieldMapperPopup
                );
                console.table(treeData);
                setTreeData(treeData);
            }
        });
    };

    const handleFieldMapperPopup = (data: SortableTreeNode) => {
        console.log(data);
        // setCustomerAndBankFieldMapperInfo({
        //     bankFieldName: path,
        //     bankFieldPath: path,
        //     customerFieldName: null,
        // });
        setIsDisplayMapperModal(true);
        //
    };

    const handleOnStepperOneNext = (data: any, currentStepId: number) => {
        setMappedFields(data);
        handleOnNext(currentStepId);
    };

    const handleOnStepperTwoNext = (data: any, currentStepId: number) => {
        console.table(data);
        handleOnNext(currentStepId);
    };

    const handleOnNext = (currentStepId: number) => {
        setActiveStepId(currentStepId + 1);
    };

    const handleOnPrevious = (currentStepId: number) => {
        setActiveStepId(currentStepId - 1);
    };

    const renderBankTemplate = () => {
        return (
            <XmlMapper
                treeData={treeData}
                currentStepId={activeStepId}
                customerTemplateFields={mappedFields}
                onStepperPrevious={handleOnPrevious}
                onStepperNext={(data, currentStepId) =>
                    handleOnStepperTwoNext(data, currentStepId)
                }
            />
        );
    };

    const renderCustomerTemplate = () => {
        return (
            <FieldMapper
                source={allHeaders}
                mappedSource={customerTemplateMappedFields}
                onStepperNext={(data, currentStepId) =>
                    handleOnStepperOneNext(data, currentStepId)
                }
                currentStepId={activeStepId}
            ></FieldMapper>
        );
    };

    const renderCustomFieldMapper = () => {
        return (
            <CustomFieldMapper
                currentStepId={activeStepId}
                customerTemplateFields={mappedFields}
            />
        );
    };

    const renderActiveStep = (id) => {
        switch (id) {
            case 0:
                return renderCustomerTemplate();
            case 1:
                return renderBankTemplate();
            case 2:
                return renderCustomFieldMapper();
            default:
                return renderCustomerTemplate();
        }
    };

    const render = () => {
        return (
            <>
                <Stepper activeStep={activeStepId}>
                    <Step
                        label="Customer Template Mapping"
                        onClick={(e) => {
                            setActiveStepId(0);
                            renderActiveStep(0);
                            handleDownloadFile();
                        }}
                    />
                    <Step
                        label="Customer & Bank Template Mapping"
                        onClick={(e) => {
                            setActiveStepId(1);
                            renderActiveStep(1);
                        }}
                    />
                    <Step label="Customize Mapped Template" />
                    <Step label="Preview" />
                    <Step label="Done" />
                </Stepper>
                {renderActiveStep(activeStepId)}
                <CdsModal
                    show={isDisplayMapperModal}
                    handleClose={() => setIsDisplayMapperModal(false)}
                    handleShow={() => setIsDisplayMapperModal(true)}
                    modalTitle="Select Customer Field"
                >
                    <TemplateFieldMapperFormDialog
                        data={customerBankFieldMapperInfo}
                        bankFieldName="fake-name"
                        handleOnCancel={() => setIsDisplayMapperModal(false)}
                    ></TemplateFieldMapperFormDialog>
                </CdsModal>
            </>
        );
    };

    return render();
};

export default FileMapperContainer;
