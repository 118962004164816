import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    createBankRequest,
    deleteBankByIdRequest,
    findAllBanksRequest,
    findBankByIdRequest,
    updateBankRequest,
} from '../requests/Bank';
import {
    addBankFailed,
    //addBankSlice,
    addBankSuccess,
    deleteBankSlice,
    editBankSlice,
    fetchAllBanks,
    fetchAllBanksSuccess,
    findAllBanksFailed,
    findAllBanksSlice,
    resetSaveBank,
    triggerSaveBank,
} from '../slice/bank/Banks';
import { setBankSlice } from '../slice/bank/Bank';
import {
    CREATE_BANK,
    DELETE_BANK_BY_ID,
    FIND_ALL_BANKS,
    FIND_BANK_BY_ID,
    UPDATE_BANK_BY_ID,
} from '../types/bank';
import { getErrorMessage } from '../../utility';

//new
export function* findAllBanksSaga(action) {
    try {
        
        const { data, error } = yield call(findAllBanksRequest, action.reqData);
        if (error) {
            yield put(findAllBanksFailed(error));
        } else {
            yield put(findAllBanksSlice(data));
        }
    } catch (err) {
        yield put(findAllBanksFailed(err));
    }
}

export function* findBankByIdSaga(action) {
    yield put(resetSaveBank());
    const response = yield findBankByIdRequest(action.id);
    yield put(setBankSlice(response));
}

export function* updateBankSaga(action) {
    try {
        yield put(triggerSaveBank());
        console.log(action.bank);
        const response = yield updateBankRequest(action.bank);
        if (response) {
            yield put(addBankSuccess());
        } else {
            yield put(
                addBankFailed(getErrorMessage('Error in updating bank.'))
            );
        }
    } catch (error) {
        addBankFailed(getErrorMessage('Error in updating bank.', error));
    }
}

export function* deleteBankByIdSaga(action) {
    yield deleteBankByIdRequest(action.id);
    yield put(deleteBankSlice(action));
}

export function* createBankSaga(action) {
    try {
        yield triggerSaveBank();
        console.log(action.bank);
        const response = yield createBankRequest(action.bank);
        if (response) {
            yield put(addBankSuccess());
        } else {
            yield put(
                addBankFailed(getErrorMessage('Error in creating new bank.'))
            );
        }
    } catch (error) {
        addBankFailed(getErrorMessage('Error in creating new bank.', error));
    }
}

// all crud
function* bankSaga() {
    /// new
    yield all([takeLatest(CREATE_BANK, createBankSaga)]);
    yield all([takeLatest(FIND_ALL_BANKS, findAllBanksSaga)]);
    yield all([takeLatest(DELETE_BANK_BY_ID, deleteBankByIdSaga)]);
    yield all([takeLatest(UPDATE_BANK_BY_ID, updateBankSaga)]);
    yield all([takeLatest(FIND_BANK_BY_ID, findBankByIdSaga)]);
}

export default bankSaga;
