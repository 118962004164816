import React from 'react';
import { trustedCustData } from '../../model/TrustedCustomersModel';
import '../paybank/paybank.css';
import { useNavigate, useParams } from 'react-router-dom';

const TrustedCustomersViewAll = () => {
    const navigate = useNavigate();
    const handleBackButtonClick = () => {
        navigate(-1);
    };
    return (
        <div className="card-wo-border">
            <nav
                id="#navbar-example2"
                className=" navbg border-bottom bg-white navbar bg-nav navbar-expand-lg bg-body-tertiary sticky-top"
            >
                <div className="container">
                    <div className="row p-0 ">
                        <div className="col-sm-auto">
                            <a href="#carouselExampleCaptions">
                                <img
                                    //width={100}
                                    className="img-fluid img-ht-2"
                                    src={require('../assets/logo.png')}
                                />
                            </a>
                        </div>
                        {/* <div className="col">
                            <a className="navbar-brand" href="#">
                                PayBrij
                            </a>
                        </div> */}
                    </div>
                    <div
                        className="pb-3 m-0  mx-5  collapse navbar-collapse text-right "
                        id="navbarNav"
                    >
                        <ul className="navbar-nav mt-4">
                            <li className="nav-item">
                                <a
                                    className="nav-link active"
                                    aria-current="page"
                                    href="#carouselExampleCaptions"
                                >
                                    <h4 className="">Home</h4>
                                </a>
                            </li>
                            <li className="nav-item mx-2">
                                <a className="nav-link active" href="#features">
                                    <h4 className="">Features</h4>
                                </a>
                            </li>
                            <li className="nav-item mx-2">
                                <a
                                    className="nav-link active"
                                    href="#deployment"
                                >
                                    <h4 className="">Deployment</h4>
                                </a>
                            </li>
                            <li className="nav-item mx-2">
                                <a
                                    href="#howitworks"
                                    className="nav-link active"
                                >
                                    <h4 className="">How it works</h4>
                                </a>
                            </li>
                            <li className="nav-item mx-2">
                                <a href="#faq" className="nav-link active">
                                    <h4 className="">FAQ</h4>
                                </a>
                            </li>
                            <li className="nav-item mx-2">
                                <a
                                    href="#contactus"
                                    className="nav-link active"
                                >
                                    <h4 className="">Contact Us</h4>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="pb-3 row justify-content-start mt-4">
                        <div className="col-sm-auto">
                            <button className="w-100 sign-up p-1 btn-min-width">
                                Sign In
                            </button>
                        </div>
                        <div className="col-sm-auto">
                            <a href="#contactus">
                                <button
                                    //onClick={() => goToCp()}
                                    className="w-100 btn-min-width sign-in"
                                >
                                    Sign Up
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>

            <div className="container">
                <div className="row pr-5">
                    <button
                        onClick={handleBackButtonClick}
                        className="btn btn-sm rounded-4 left mt-3"
                    >
                        Back
                    </button>
                </div>
            </div>

            <div className="container my-5 p-3 rounded-3 ">
                <div className="">
                    <div className="row">
                        <div className="">
                            <h2 className="h2 text-center md-40 fw-bold">
                                Customers Connected to Us
                            </h2>
                            <hr className="line mx-auto "></hr>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col">
                            <h3 className="h3 text-center text-grey">
                                The PayBrij is already working successfully for
                                many large organizations
                            </h3>
                        </div>
                    </div>
                    <div className="row card-wo-border ">
                        <div className="mt-3">
                            <div className="row mt-3 justify-content-center">
                                <div className="col-sm-auto rbor">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto "
                                            src="https://tatainternational.com/wp-content/themes/til/images/TIL-60-years-logo.png"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto rbor">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto"
                                            src=" https://www.kfintech.com/new-assets/images/logo/kfintech-white-tagline.svg"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto rbor">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto"
                                            src="https://www.newlywedsfoods.in/wp-content/uploads/2017/10/NWF-Logo-small.png"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto rbor">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto"
                                            src="https://www.kpit.com/wp-content/themes/kpittheme/redesign/images/logo.svg"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto rbor">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto"
                                            src="https://d1mgel6vch4ba4.cloudfront.net/images/logo_opt.png"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto">
                                    <div className="my-5 ">
                                        {/* <button onClick={()=>viewAllCustomers()} className='btn btn-sm rounded-4 btn-default'>
                                                View All
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="mt-3">
                            <div className="row mt-3 justify-content-center">
                                <div className="col-sm-auto rbor">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto"
                                            src="https://www.hap.in/images/hap-logo.png"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto rbor">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto"
                                            src="https://d1mgel6vch4ba4.cloudfront.net/images/logo_opt.png"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto rbor">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto"
                                            src="https://www.mahanagargas.com/images/logo.png"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto rbor">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto"
                                            src="https://www.indorama.com/frontend/img/home-logo.png"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto rbor">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto"
                                            src="https://www.wns.com/portals/_default/skins/wns-revamp/images/logowhite.svg"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto">
                                    <div className="my-5 ">
                                        {/* <button onClick={()=>viewAllCustomers()} className='btn btn-sm rounded-4 btn-default'>
                                                View All
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="mt-3">
                            <div className="row mt-3 justify-content-center">
                                <div className="col-sm-auto rbor">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto"
                                            src="https://www.grantthornton.in/globalassets/1.-member-firms/global/logos/grant_thornton_logo_20202.png"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto rbor">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto"
                                            src="https://sarex.com/sarexhome/img/logo-old.png"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto rbor">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto"
                                            src="http://img2.yun300.cn/img/as001.png?tenantId=116886&viewType=1&k=1625650370000"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto rbor">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto"
                                            src="https://upload.wikimedia.org/wikipedia/en/thumb/8/87/USV_Limited_Logo.png/300px-USV_Limited_Logo.png"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto rbor">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto"
                                            src="https://www.panchshil.com/img/logo.jpg"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto">
                                    <div className="my-5 ">
                                        {/* <button onClick={()=>viewAllCustomers()} className='btn btn-sm rounded-4 btn-default'>
                                                View All
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row ">
                        <div className="mt-3">
                            <div className="row mt-3 justify-content-center">
                                <div className="col-sm-auto rbor ">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto"
                                            src="https://assets.zee.com/uploads/zee-logo-white.png"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto rbor">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto"
                                            src="https://tse1.mm.bing.net/th?id=OIP.GVi_55YGY8RAx2ElX61ioAHaHa&pid=Api&P=0"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto rbor">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto"
                                            src="https://tse1.mm.bing.net/th?id=OIP.7JbLhAltsqQJE2rXpDwHzAAAAA&pid=Api&P=0"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto rbor">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto"
                                            src="https://glandpharma.com/images/header_logo.webp"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto rbor">
                                    <div className="card slider-card-width">
                                        <img
                                            className="img-fluid my-auto mx-auto  my-auto"
                                            src="https://5.imimg.com/data5/GK/YY/BH/SELLER-21308516/gfhgfh-120x120.png"
                                        ></img>
                                    </div>
                                </div>
                                <div className="col-sm-auto">
                                    <div className="my-5 ">
                                        {/* <button onClick={()=>viewAllCustomers()} className='btn btn-sm rounded-4 btn-default'>
                                                View All
                                        </button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrustedCustomersViewAll;
