import axios, { AxiosError } from 'axios';
import API from '../../constants/Api';
import { ApiResponse } from '../../model/ApiResponseModel';
import Bank from '../../model/BankModel';
import ReduxError from '../../model/ErrorModel';

// filter TBD...
export async function findAllBanksRequest(
    requestPayload: any
): Promise<ApiResponse> {
    
    const result: ApiResponse = {
        data: null,
        error: null,
    };
    const body = {
        BankName: requestPayload,
    };
    return await axios({
        method: API.TYPE.POST,
        url: API.BANK.GET_BANK_LIST,
        data: body,
    }).then(
        (response) => {
            // set response to data
            console.log(response.data.BankDetails);
            result.data = response.data.BankDetails;
            return result;
        },
        (err) => {
            result.error = {
                message: 'some issue',
                stackTrace: err.message,
            };
            return result;
        }
    );
}

export async function createBankRequest(payload: Bank): Promise<boolean> {
    const body = {
        BankName: payload.bankname,
        Address: payload.address,
        City: payload.city,
        PinCode: payload.pincode,
        BankContact: payload.contact,
        Contact_Email: payload.bankemail,
        Contact_Mobile: payload.bankmobileNo,
        IsActive: payload.active,
    };
    return await axios({
        method: API.TYPE.POST,
        url: API.BANK.CREATE_BANK,
        data: body,
    }).then((response) => {
        
        console.log(response.data);
        return response.data;
    });
}

export async function updateBankRequest(payload: Bank): Promise<Bank> {
    const body = {
        ID: payload.id,
        BankName: payload.bankname,
        Address: payload.address,
        City: payload.city,
        PinCode: payload.pincode,
        BankContact: payload.contact,
        Contact_Email: payload.bankemail,
        Contact_Mobile: payload.bankmobileNo,
    };
    return await axios({
        method: API.TYPE.POST,
        url: API.BANK.UPDATE_BANK,
        data: body,
    }).then(
        (response) => {
            console.log(response.data);
            return response.data;
        },
        (err) => {
            return false;
        }
    );
}

export async function findBankByIdRequest(
    selectedBankId: number
): Promise<Bank> {
    return await axios({
        method: API.TYPE.POST,
        url: API.BANK.GET_BANK,
        data: { id: selectedBankId },
    }).then((response) => {
        console.log(response.data);
        // status code , add if
        return response.data.BankDetails[0];
    });
}

export async function deleteBankByIdRequest(payload: number): Promise<boolean> {
    return await axios({
        method: API.TYPE.POST,
        url: API.BANK.DELETE_BANK,
    }).then((response) => {
        console.log(response.data);
        return response.data;
    });
}
