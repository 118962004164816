import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import ReduxError from '../../../model/ErrorModel';
import { CustomerTemplateModel } from '../../../model/CustomerTemplateModel';

// Define a type for the slice state
interface CustomerTemplateConfiguredHeaderState {
    isConfiguredHeadersFetched: boolean;
    configuredHeaders: Array<CustomerTemplateModel>;
    configuredHeaderFetchError: ReduxError | null;
}

// Define the initial state using that type
const initialState: CustomerTemplateConfiguredHeaderState = {
    isConfiguredHeadersFetched: false,
    configuredHeaders: new Array<CustomerTemplateModel>(),
    configuredHeaderFetchError: null,
};

export const customerTemplateConfiguredHeadersSlice = createSlice({
    name: 'customer-template-configured-header',
    initialState,
    reducers: {
        findAllCustomerTemplateConfiguredHeadersSuccess: (
            state,
            action: PayloadAction<Array<CustomerTemplateModel>>
        ) => {
            state.configuredHeaders = action.payload;
            return state;
        },
        findAllCustomerTemplateConfiguredHeadersFailed: (
            state,
            action: PayloadAction<ReduxError>
        ) => {
            state.isConfiguredHeadersFetched = true;
            state.configuredHeaders = [];
            state.configuredHeaderFetchError = action.payload;
        },
    },
});

export const {
    findAllCustomerTemplateConfiguredHeadersSuccess,
    findAllCustomerTemplateConfiguredHeadersFailed,
} = customerTemplateConfiguredHeadersSlice.actions;
export default customerTemplateConfiguredHeadersSlice.reducer;
