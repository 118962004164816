import React, { useEffect } from 'react';
import {
    Link,
    unstable_HistoryRouter,
    useNavigate,
    useParams,
} from 'react-router-dom';
import '../PaymentType.css';
import { useFormik } from 'formik';
import { addNewPaymentTypeSchema } from '../../schema/PaymentType';
import { AnyNsRecord } from 'dns';
import axios from 'axios';
import PaymentTypes from '../../../model/PaymentTypesModel';
import { useDispatch, useSelector } from 'react-redux';
import {
    FIND_PAYMENT_TYPE_BY_ID,
    UPDATE_PAYMENT_TYPE_BY_ID,
} from '../../../sagas/types/payment-types';
import { RootState } from '../../../sagas/slice/rootReducer';
import CdsTitle from '../../../components/CdsTitle';
import CdsButton from '../../../components/CdsButton';

type EditTypeProps = {};
const EditPaymentType: React.FC<EditTypeProps> = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const editPaymentType: PaymentTypes = useSelector(
        (state: RootState) => state.paymentType.paymentType
    );
    const initialValue: PaymentTypes = {
        id: 0,
        paymentType: '',
        description: '',
        //paymentid: '',
    };

    const params = useParams();
    console.log(params);

    const myFormik = useFormik({
        initialValues: initialValue,
        validationSchema: addNewPaymentTypeSchema,
        onSubmit: (values: PaymentTypes) => {
            
            console.log(values);
            dispatch({ type: UPDATE_PAYMENT_TYPE_BY_ID, paymentType: values });
            navigate('/cp/paymenttype');
            //sendData(values);
        },
    });
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;

    useEffect(() => {
        
        dispatch({ type: FIND_PAYMENT_TYPE_BY_ID, id: params.id });
    }, [dispatch]);

    useEffect(() => {
        if (editPaymentType) {
            
            onEditSetPaymentTypeValues(editPaymentType);
        }
    }, [editPaymentType]);

    const onEditSetPaymentTypeValues = (editPaymentType: any) => {
        myFormik.setValues({
            id: editPaymentType.ID,
            paymentType: editPaymentType.PaymentType,
            description: editPaymentType.Description,
            //paymentid: editPaymentType.paymentid,
        });
    };
    //console.log(values);
    return (
        <form onSubmit={handleSubmit} className="container-fluid">
            <CdsTitle
                linkto="/cp/paymenttype"
                title="Payment Type"
                iconClass="bi bi-cash-coin"
                link="Back To List"
                headerDataTestId = 'headingPaymentType'
                linkDataTestId = 'btnViewAllPayment'
            ></CdsTitle>
            <div className="card mt-3 border rounded-4">
                <div className="card-header">
                    <h6 data-testid = 'titleUpdatePaymentType'className="md-18">Update Payment Type</h6>
                </div>
                <div className="card-body">
                    <div className="row my-4">
                        <div className="col">
                            <label htmlFor="paymentType" className="form-label">
                                <h6 data-Testid='labeleditpaymenttype'>Payment Type</h6>
                            </label>
                            <input
                            data-Testid='editinputpaymenttype'
                                type="text"
                                className="form-control"
                                id="paymentType"
                                placeholder="Enter Payment Type"
                                name="paymentType"
                                value={values.paymentType}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            ></input>
                            {errors.paymentType && touched.paymentType ? (
                                <p data-Testid='erroreditPaymentType' className="form-val form-error">
                                    {errors.paymentType}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="description" className="form-label">
                                <h6 data-Testid='labelEditDescription'>Description</h6>
                            </label>
                            <input
                            data-Testid='inputEditDescription'
                                type="text"
                                className="form-control"
                                id="description"
                                name="description"
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Description"
                            ></input>
                            {errors.description && touched.description ? (
                                <p data-Testid='erroreditDescription' className="form-val form-error">
                                    {errors.description}
                                </p>
                            ) : null}
                        </div>
                        {/* <div className="col">
                            <label htmlFor="paymentId" className="form-label">
                                <h6>Payment ID</h6>
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="paymentId"
                                name="paymentid"
                                value={values.paymentid}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Payment ID"
                            ></input>
                            {errors.paymentid && touched.paymentid ? (
                                <p className="form-val form-error">
                                    {errors.paymentid}
                                </p>
                            ) : null}
                        </div> */}
                    </div>
                    <div className="row justify-content-evenly my-4">
                        <div className="col">
                            <div className="justify-content-end  row">
                                <div data-Testid='btnUpdate' className="col-auto">
                                    <CdsButton
                                        title="Update"
                                        type="submit"
                                        handleClick={(e) => handleSubmit()}
                                    />
                                </div>
                            </div>
                        </div>
                        {/* <div className=" col my-4 form-check fo rm-switch form-check-reverse ">
                            <div className="slider">
                                <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchCheckDefault"
                                >
                                    <h6>Status</h6>
                                </label>
                                <input
                                    checked
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    value={values.active}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="flexSwitchCheckDefault"
                                    name="active"
                                />
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </form>
    );
};

export default EditPaymentType;
