import xml2js, { Parser, ParserOptions } from 'xml2js';
import { SortableTreeNode } from '../../../model/SortableTreeNodeModel';
import SortableTreeItem from '../SortableTreeItem';

export const getParserObject = (): Parser => {
    return new xml2js.Parser({
        strict: true,
        preserveChildrenOrder: true,
        // emptyTag: value => {
        //   console.log(value)
        // },
        explicitArray: false,
        // childkey: true,
        ignoreAttrs: true, // can be false in preview step
    });
};

export const generateTreeNew = (
    obj,
    parent?,
    cb?: any
): Array<SortableTreeNode> => {
    console.log(obj);
    // If the argument is not an object or is null, return null
    if (typeof obj !== 'object' || obj === null) {
        return null;
    }

    // Initialize an empty result array
    const result = new Array<SortableTreeNode>();
    let i = 0;
    // Iterate over all the keys in the object
    console.log('B:', obj);
    for (const key in obj) {
        // Initialize a new tree node for this key
        const node: SortableTreeNode = {
            title: key,
            expanded: false,
            children: [],
            path: '',
        };

        // Recursively generate the tree for the child property
        if (parent) {
            node.path = `${parent}_${node.title}`;
        } else {
            node.path = `${node.title}`;
        }

        const childTree = generateTreeNew(obj[key], node.path, cb);
        console.log('K:', childTree);
        // If the child tree is not null, add it to the node's children array
        if (childTree !== null) {
            node.children = childTree;
            node.expanded = true;
        } else {
            node.title = (
                <SortableTreeItem
                    title={node.title}
                    handleClick={(e) => cb(e)}
                    data={node.path}
                />
            );
            console.log('last');
        }

        // Add the node to the result array
        result.push(node);
        // if (childTree !== null && node.expanded) {
        //   node.path = result.length > 0 && result[i].title ? result[i].title : ''
        // }
        i = i + 1;
    }

    // Return the result array
    console.table(result);
    return result;
};
