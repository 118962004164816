import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './sagas/slice/rootReducer';
import rootSaga from './sagas/saga';
//
import { persistReducer, persistStore } from 'redux-persist';
import sessionStorage from 'redux-persist/es/storage/session';
const persistConfig = {
    key: 'root',
    storage: sessionStorage,
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
// TBD store persist
const sagaMiddleware = createSagaMiddleware();
const middleware = [sagaMiddleware, logger];
const store = configureStore({
    reducer: rootReducer,
    middleware,
    devTools: process.env.NODE_ENV !== 'production',
});
sagaMiddleware.run(rootSaga);
export const persistor = persistStore(store);
export default store;
