import React from 'react';
import '../customer.css';
import { useFormik } from 'formik';
import { addCustomerSchema } from '../../schema/Customer';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { CREATE_CUSTOMER } from '../../../sagas/types/customer';
import Customer from '../../../model/CustomerModel';
import CdsTitle from '../../../components/CdsTitle';
import CdsButton from '../../../components/CdsButton';

type CreateCustomerProps = {};

const CreateCustomer: React.FC<CreateCustomerProps> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const initialValue: Customer = {
        id: 0,
        customername: '',
        address: '',
        contact: '',
        city: '',
        pincode: '',
        customermobileNo: '',
        customeremail: '',
        active: '',
    };

    const myFormik = useFormik({
        initialValues: initialValue,
        validationSchema: addCustomerSchema,
        onSubmit: (values: Customer) => {
            console.log(values);
            dispatch({ type: CREATE_CUSTOMER, customer: values });
            navigate('/cp/customer', { replace: true });
        },
    });
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;

    return (
        <form onSubmit={handleSubmit} className="container-fluid">
            <CdsTitle
                linkto="/cp/customer"
                title="Customer"
                iconClass="bi bi-people"
                link="Back to List"
                headerDataTestId = 'titleCustomer'
                linkDataTestId = 'backToList'
            ></CdsTitle>
            <div className="card mt-3 border rounded-4">
                <div className="card-header">
                    <h6 data-testid ="titleAddCustomer" className="md-18">Add Customer Details</h6>
                </div>
                <div className="card-body">
                    <div className="row my-4">
                        <div className="col">
                            <label
                                htmlFor="customerName"
                                className="form-label"
                            >
                                <h6 data-testid ="AddName" >Customer Name</h6>
                            </label>
                            <input
                            data-testid ="inputAddName" 
                                type="text"
                                className="form-control"
                                name="customername"
                                value={values.customername}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="customerName"
                                placeholder="Enter Customer Name"
                            ></input>
                            {errors.customername && touched.customername ? (
                                <p  data-testid ="errorAddName" className="form-val form-error">
                                    {errors.customername}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="address" className="form-label">
                                <h6 data-testid ="AddAddress" >Address</h6>
                            </label>
                            <input
                            data-testid ="inputAddAddress" 
                                type="text"
                                className="form-control"
                                id="address"
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Address"
                            ></input>
                            {errors.address && touched.address ? (
                                <p data-testid ="errorAddAddress" className="form-val form-error">
                                    {errors.address}
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <div className="row my-4">
                        {/* <div className="col">
                            <label htmlFor="contactNo" className="form-label">
                                <h6>Contact Number</h6>
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="contactNo"
                                name="contact"
                                value={values.contact}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Contact Number"
                            ></input>
                            {errors.contact && touched.contact ? (
                                <p className="form-val form-error">
                                    {errors.contact}
                                </p>
                            ) : null}
                        </div> */}

                        <div className="col">
                            <label htmlFor="city" className="form-label">
                                <h6 data-testid ="AddCity" >City</h6>
                            </label>
                            <input
                            data-testid ="inputAddCity" 
                                type="text"
                                className="form-control"
                                id="city"
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter City"
                            ></input>
                            {errors.city && touched.city ? (
                                <p data-testid ="errorAddCity"  className="form-val form-error">
                                    {errors.city}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="pincode" className="form-label">
                                <h6 data-testid ="AddPincode" >Pin Code</h6>
                            </label>
                            <input
                            data-testid ="inputAddPincode" 
                                type="number"
                                className="form-control"
                                id="pincode"
                                name="pincode"
                                value={values.pincode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter PIN Code"
                            ></input>
                            {errors.pincode && touched.pincode ? (
                                <p data-testid ="errorAddPincode" className="form-val form-error">
                                    {errors.pincode}
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <div className="row my-4">
                        <div className="col">
                            <label
                                htmlFor="customerMobileNo"
                                className="form-label"
                            >
                                <h6 data-testid ="AddCustomerNo" >Customer Contact Number</h6>
                            </label>
                            <input
                            data-testid ="inputAddCutomerNo" 
                                type="number"
                                className="form-control"
                                id="customerMobiletNo"
                                name="customermobileNo"
                                value={values.customermobileNo}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Customer Contact Number"
                            ></input>
                            {errors.customermobileNo &&
                            touched.customermobileNo ? (
                                <p data-testid ="errorAddCustomerNo"  className="form-val form-error">
                                    {errors.customermobileNo}
                                </p>
                            ) : null}
                        </div>

                        <div className="col">
                            <label htmlFor="email" className="form-label">
                                <h6 data-testid ="AddCustEmail" >Customer Email</h6>
                            </label>
                            <input
                            data-testid ="inputAddCustEmail" 
                                type="text"
                                className="form-control"
                                id="email"
                                name="customeremail"
                                value={values.customeremail}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Customer Email ID"
                            ></input>
                            {errors.customeremail && touched.customeremail ? (
                                <p data-testid ="errorAddCustEmail"  className="form-val form-error">
                                    {errors.customeremail}
                                </p>
                            ) : null}
                        </div>
                        {/* <div className=" col-1 my-4 form-check form-switch form-check-reverse ">
                            <div className="slider">
                                <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchCheckChecked"
                                >
                                    <h6>Status</h6>
                                </label>
                                <input
                                    checked
                                    className="form-check-input"
                                    type="checkbox"
                                    name="active"
                                    value={values.active}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                />
                            </div>
                        </div> */}
                    </div>
                    <div className="justify-content-end  row">
                        <div data-testid ="btnAddCancel"  className="col-auto">
                            <CdsButton
                                title="Cancel"
                                type="button"
                                handleClick={(e) => navigate('/cp/customer')}
                            />
                        </div>
                        <div data-testid ="btnAddSubmit"  className="col-auto">
                            <CdsButton title="Submit" type="submit" />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default CreateCustomer;
