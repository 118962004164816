import { Navigate, useNavigate, useOutlet } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import CdsSidebar from '../components/CdsSidebar';
import CdsNavbar from '../components/CdsNavbar';
import { useState } from 'react';

export const ProtectedLayout = () => {
    const navigate = useNavigate();
    const { user } = useAuth();
    const outlet = useOutlet();
    const [roleID, setRoleId] = useState();

    if (!user) {
        return <Navigate to="/" />;
    }
    const logoutUser = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('roleID');
        navigate('/');
    };

    const getRoleID = () => {
        return localStorage.getItem('roleID');
    };
    const data = getRoleID();
    console.log(data);

    const getPageTitle = () => {};
    return (
        <>
            <CdsNavbar
                logout={function (): void {
                    logoutUser();
                }}
            ></CdsNavbar>
            <div className="row">
                <div className="sidebarht col-2">
                    <CdsSidebar RoleID={getRoleID()}></CdsSidebar>
                </div>
                <div className="col-10">{outlet}</div>
            </div>
        </>
    );
};
