import { all, call, put, takeLatest } from "redux-saga/effects";
import BankNames from "../../model/BankNameModel";
import PaymentTypeWithId from "../../model/PaymentTypeWithIdModel";
import { findAllBanksFileRequest, findAllBanksNamesRequest, uploadBankFileRequest } from "../requests/BankFile";
import { findAllBankNamesSlice } from "../slice/bank-file/BankNames";
import { findAllPaymentTypesWithIdRequest } from "../requests/BankFile"; 
import { GET_ALL_BANKS, GET_ALL_PAYMENT_TYPES, GET_BANKFILE_LIST, UPLOAD_BANK_FILE } from "../types/bank-file";
import { findAllPaymentTypesWithIdSlice } from "../slice/bank-file/PaymentType";
import { uploadBankFileSlice } from "../slice/bank-file/UploadBankFile";
import BankFileListModel from "../../model/BankFileListModel";
import { findAllBankFileSlice } from "../slice/bank-file/BankFileList";

export function* findAllBankNamesSaga(action){
    const response: Array<BankNames> = yield call(findAllBanksNamesRequest, action.data);
    yield put(findAllBankNamesSlice(response));
}
export function* findAllPaymentTypesWithIdSaga(action){
    const response: Array<PaymentTypeWithId> = yield call(findAllPaymentTypesWithIdRequest, action.data);
    yield put(findAllPaymentTypesWithIdSlice(response));
}
export function* uploadBankFileSaga(action){
    
    yield uploadBankFileRequest(action.upload);
    yield put(uploadBankFileSlice(action));
}
export function* findAllBankFileListSaga(action) {
    const response: Array<BankFileListModel> = yield call(findAllBanksFileRequest, action.reqData);
    yield put(findAllBankFileSlice(response));
}
function* bankFileSaga(){
    yield all([takeLatest(GET_ALL_BANKS, findAllBankNamesSaga)]);
    yield all([takeLatest(GET_ALL_PAYMENT_TYPES, findAllPaymentTypesWithIdSaga)]);
    yield all([takeLatest(UPLOAD_BANK_FILE, uploadBankFileSaga)]);
    yield all([takeLatest(GET_BANKFILE_LIST, findAllBankFileListSaga)]);


}

export default bankFileSaga