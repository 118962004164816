import { colors } from '@mui/material';
import { grey, red } from '@mui/material/colors';
import React from 'react';

import { Link } from 'react-router-dom';

// type CustomerEmailTemplateProps = {

//   title: string;

// };

const CustomerEmailTemplate: React.FC = () => {
    return (

    
    <div style={{maxWidth:'600px',margin:'0 auto', backgroundColor:'white', borderRadius:'4px', boxShadow: '0 0 10px rgba(0, 0, 0, 0 .1)', padding: '20px'}}>

    <img style={{width:'100%', maxHeight:'300px', marginBottom:'30px',borderRadius:'4px', objectFit: 'cover' }} src="../logo.png" alt="Hero Image"></img>

    <h1 style={{fontSize: '32px', color: '#333', marginBottom: '30px', textAlign: 'center'}}>Welcome to  Paybrij</h1>

    <p style={{fontSize:'18px', color: '#666', marginBottom: '15px', lineHeight: '1.5'}}>Dear Customer,</p>

    <p style={{fontSize:'18px' , color:'#666', marginBottom:'15px' , lineHeight: '1.5'}}> Thank you for choosing our PayBrij Application. We're excited to have you on board!</p>

    <p style={{fontSize:'18px', color:'#666', marginBottom:'15px' ,lineHeight: '1.5'}}>At [Brand name], we understand that [problem that the product
    //             solves] is a challenge. That's why we [share how your product is
    //             made, the materials you use, or features]. With our product,
    //             you'll get [insert the benefits of the product and how it can
    //             solve the problem the customer is facing].</p>
    <p>Thank you for joining the Copia community. We're here to
    //             help. And if you have any questions or feedback, contact X at
    //             [email, social media, or phone number].</p>
    <a style={{display: 'inline-block' , backgroundColor: '#4CAF50' , color: '#fff' , textDecoration: 'none', padding: '12px 28px', borderRadius: '4px', marginTop: '25px' , fontSize: '20px' , transition: 'background-color 0.3s ease'}} href="#">Read More</a>
    <p style={{marginTop: '40px' , textAlign: 'center' , color: '#999' , fontSize: '14px'}}>If you wish to unsubscribe, <a href="#">click here</a>.</p>
    <p style={{textAlign: 'right' , fontSize: '16px' , fontStyle: 'italic' , color:'#999' , marginTop: '20px'}}>Best regards, Copia</p>

</div>

    );
};

export default CustomerEmailTemplate;


