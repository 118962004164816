import * as Yup from "yup";

export const contactUsSchema = Yup.object({
    help:Yup.string().required('Please select an option'),
    username: Yup.string().min(2).max(25).required("Please enter username"),
    companyname: Yup.string().min(2).required("Please enter company name"),
    email: Yup.string().email().required("Please enter your email"),
    contact: Yup.string()
        .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
        .required("Please enter your contact number"),
});