export const data = [
    {
        question :'Q1. We have SAP system, do these adapters work with SAP ?',
        key : "1",
        answer : 'Yes, we have standard adapters  for SAP and SAP S/4 HANA. You just need to copy the code inyour SAP system and it would be compatible with your ERP. Apart from SAP, we also have standard adapters for Tally &amp; Oracle ERPs.'
    },
    {
        question :'Q2. We use our homegrown ERP solution will these work with this ERP? ',
        key : "2",
        answer :'Yes, these adapters can work with any ERP or for that matter any IT systems. All you need is extract the relevant file from your ERP in .XLS format and rest will be automatic.'
    },
    {
        question :'Q3. We need to have reports on daily basis and monthly basis, how can it support it? ',
        key : "3",
        answer :'There are multiple reports which are generated on daily and monthly basis. You can select and choose relevant reports needed and system will automatically place these reports on your system at a set frequency'
    },
    {
        question :'Q4. We use Linux operating system, do these adapters work on Linux? ',
        key : "4",
        answer :'Yes, these are OS agnostic and it can work on any Windows or Linux system'
    },
    {
        question :'Q5. Can these adapters send emails with report attachments?',
        key : "5",
        answer :'Yes, this is a standard feature available. You will need to provide a dedicated email address with credentials and allow email this email through your gateway. With that it can be setup to send reports emails as well as many other emails.'
    },
    {
        question :'Q6. If there is a failure, how would we be notified and how corrections can happen? ',
        key : "6",
        answer :'If you have enabled email functionality, system will send emails to designated recipients on any kind of failures. Also, you can always check in log files in the system.'
    },
    {
        question :'Q7. How can we get started?',
        key : "7",
        answer :'Its very simple. Just fill up the form on contact us page and we will connect with you for further information.'
    },
    {
        question : 'Q8 .How data is transmitted to Bank?',
        key : "8",
        answer : 'This system uses PGP encryption and Host to host connectivity to send data to the bank. Its most secured method of data transmission over internet.'
    },
    {
        question : 'Q9 .This is confidential data for our organization, is its shared anywhere?',
        key : "9",
        answer : 'No, The application is hosted on your IT landscape. So all data resides in your landscape and encrypted there. The encrypted data packet is sent to the bank over internet.'
    },
    {
        question : 'Q10 .What is host to host connection?',
        key : "10",
        answer : 'Host-to-Host is an automated, two-way data transfer service for high-volume online banking that gives you direct, anytime access to Business Online from various applications. Host-to-Host is a direct system-to-system integration between your ERP system and the bank, and with no user intermediary involved.'
    },
    {
        question : 'Q11 .Do we need to have network connectivity with the bank?',
        key : "11",
        answer : 'Yes, in order to use Host To Host connection which is secured connection, you need to establish direct connectivity with the bank. Its easy to setup this connectivity. Just fillup the form and Bank representative will connect with your network person to setup the connectivity.'
    },
    {
        question : 'Q12. How to setup network connectivity with the bank?',
        key : "12",
        answer : 'Its easy to setup this connectivity. Just fillup the form and Bank representative will connect with your network person to setup the connectivity.'
    },
    {
        question : 'Q13. Does this system uses any Database?',
        key : "13",
        answer : 'No, this does not use any kind of database.'
    },
    {
        question : 'Q14. Are there any licenses for this system?',
        key : "14",
        answer : 'No, this is completely free from any licenses.'
    },
    {
        question : 'Q15. Are there any third party applications used in the solution?',
        key : "15",
        answer : 'Standard open source third party libraries are used in the system, which are worldwide used in any application.'
    },
    {
        question : 'Q16. After installation, if there is any issue how do we get the support?',
        key : "16",
        answer : 'Once you login to the portal, you can create the support ticket and our support team will fix the issue.'
    },
    {
        question : 'Q17. Currently we use our own file format and don’t want to change it. Can we use this system?',
        key : "17",
        answer : 'Yes, this system supports any input file format and structure. So if your ERP is creating a file output which is currently in use, it can consume that file as well.'
    },
    {
        question : 'Q18. What if we want to use only one of the adapter?',
        key : "18",
        answer : 'Yes, its completely pick and choose method. You don’t need to use all the tools available in the system. Just select ones which are needed for your organization.'
    },
    {
        question : 'Q19. Our organization does not allow installation of any other softwares, how can we handle it?',
        key : "19",
        answer : 'You need to connect with your security team and get their approval for installation of this software in your IT landscape.'
    },
    {
        question : 'Q20. Is this system compliant with VAPT?',
        key : "20",
        answer : 'This is an application and VAPT is more towards the network vulnerability. You can connect with your risk or security team for VAPT with bank network.'
    },
    {
        question : 'Q21. How much time is needed to setup the system?',
        key : "21",
        answer : 'The installation and testing happen with 3 to 5 business days. It depends on your organization’s preparedness to test and deploy the system. But it does not take more than 4 to 6 weeks from start to end.'
    },
]