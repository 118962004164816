import { all, call, put, takeLatest } from "redux-saga/effects";
import { createPaymentTypeRequest,
         findAllPaymentTypesRequest,
         updatePaymentTypeRequest,
         findPaymentTypesByIdRequest,
         deletePaymentByIdRequest} from "../requests/PaymentTypes";
import { deletePaymentSlice,
         editPaymentTypeSlice,
         addPaymentTypeSlice,
         findAllPaymentTypesSlice  } from "../slice/payment-types/paymentTypes"; 
import PaymentTypes from "../../model/PaymentTypesModel";

import { FIND_ALL_PAYMENT_TYPES,
         FIND_PAYMENT_TYPE_BY_ID,
         CREATE_PAYMENT_TYPE,
         UPDATE_PAYMENT_TYPE_BY_ID, 
         DELETE_PAYMENT_TYPE_BY_ID} from "../types/payment-types";  
import { setPaymentTypelice } from "../slice/payment-types/paymentType";
         
export function* findAllPaymentTypesSaga(action){
    const response: Array<PaymentTypes> = yield call(findAllPaymentTypesRequest, action.reqData);
    yield put(findAllPaymentTypesSlice(response));
}

export function* findPaymentTypeByIdSaga(action) {
    
    const response = yield findPaymentTypesByIdRequest(action.id);
    yield put(setPaymentTypelice(response));
}
export function* updatePaymentTypeSaga(action) {
    
    // error case to be handled
    const response = yield updatePaymentTypeRequest(action.paymentType);
    yield put(editPaymentTypeSlice(response));
}
export function* createPaymentTypeSaga(action) {
    
    yield createPaymentTypeRequest(action.paymentType);
    yield put(addPaymentTypeSlice(action));
}
function* paymentTypeSaga(){
    
    yield all ([takeLatest(FIND_ALL_PAYMENT_TYPES , findAllPaymentTypesSaga)]);
    yield all ([takeLatest(CREATE_PAYMENT_TYPE , createPaymentTypeSaga)]);
    yield all([takeLatest(UPDATE_PAYMENT_TYPE_BY_ID, updatePaymentTypeSaga)]);
    yield all([takeLatest(FIND_PAYMENT_TYPE_BY_ID, findPaymentTypeByIdSaga)]);
    yield all([takeLatest(DELETE_PAYMENT_TYPE_BY_ID, deletePaymentByIdSaga)]);
  }

  export function* deletePaymentByIdSaga(action) {
    yield deletePaymentByIdRequest(action.reqData);
    yield put(deletePaymentSlice(action));
}
  
  export default paymentTypeSaga;

