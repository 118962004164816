import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import ReduxError from '../../../model/ErrorModel';
import Workflow from '../../../model/WorkflowModel';
import store from '../../../Store';
import { workFlowListRequest } from '../../requests/Workflow';
import WorkflowList from '../../../model/WorkflowlistModel';

interface workflowState {
    isFetched: boolean;
    wfStatus: {
        isChanged: boolean;
        error: boolean;
    };
    error: ReduxError | null;
    workflowList: Array<WorkflowList>;
    workflowID: string;
    workflow: {
        save: {
            isSaving: boolean | null;
            status: boolean | null;
            error: ReduxError | null;
        };
    };
}
const initialState: workflowState = {
    isFetched: false,
    error: null,
    workflowList: new Array<WorkflowList>(),
    workflowID: '',
    wfStatus: {
        isChanged: false,
        error: null,
    },
    workflow: {
        save: {
            isSaving: null,
            status: null,
            error: null,
        },
    },
};

export const workflowSlice = createSlice({
    name: 'workflow',
    initialState,
    reducers: {
        triggerSaveWorkflow: (state) => {
            state.workflow.save.isSaving = true;
            state.workflow.save.status = null;
            state.workflow.save.error = null;
            return state;
        },
        resetSaveWorkflow: (state) => {
            state.workflow.save.isSaving = null;
            state.workflow.save.status = null;
            state.workflow.save.error = null;
            return state;
        },
        addWorkflowSuccess: (state, action: PayloadAction<string>) => {
            
            state.workflow.save.isSaving = false;
            state.workflow.save.error = null;
            state.workflow.save.status = true;
            state.workflowID = action.payload;
            return state;
        },
        addWorkflowFailed: (state, action: PayloadAction<ReduxError>) => {
            state.workflow.save.isSaving = false;
            state.workflow.save.error = action.payload;
            state.workflow.save.status = false;
            return state;
        },
        findAllWorkflowSlice: (
            state,
            action: PayloadAction<WorkflowList[]>
        ) => {
            
            state.isFetched = true;
            state.workflowList = action.payload;
        },
        findAllWorkflowFailed: (state, action: PayloadAction<ReduxError>) => {
            state.isFetched = true;
            state.workflowList = [];
            state.error = action.payload;
        },
        statusChangeSuccess: (state, action: PayloadAction<string>) => {
            state.wfStatus.isChanged = true;
            return state;
        },
        statusChangeFailure: (state, action: PayloadAction<string>) => {
            state.wfStatus.error = true;
            return state;
        },
    },
});

type RootState = ReturnType<typeof store.getState>;
export const {
    addWorkflowSuccess,
    addWorkflowFailed,
    triggerSaveWorkflow,
    findAllWorkflowSlice,
    findAllWorkflowFailed,
    statusChangeSuccess,
    statusChangeFailure,
    resetSaveWorkflow
} = workflowSlice.actions;
export default workflowSlice.reducer;
