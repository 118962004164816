import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import ReduxError from '../../../model/ErrorModel';
import { SortableTreeNode } from '../../../model/SortableTreeNodeModel';
import UploadBankFile from '../../../model/BankFileListModel';

// Define a type for the slice state
interface BankTemplateState {
    isBankTemplateFetched: boolean;
    bankTemplate: Array<SortableTreeNode>;
    error: ReduxError | null;
}

// Define the initial state using that type
const initialState: BankTemplateState = {
    isBankTemplateFetched: false,
    bankTemplate: new Array<SortableTreeNode>(),
    error: null,
};

export const bankTemplateSlice = createSlice({
    name: 'bank-template',
    initialState,
    reducers:
    {
       
     
        findBankTemplateSuccess: (
            state,
            action: PayloadAction<Array<SortableTreeNode>>
        ) => {
            state.bankTemplate = action.payload;
            return state;
        },
        findBankTemplateFailed: (state, action: PayloadAction<ReduxError>) => {
            state.isBankTemplateFetched = true;
            state.bankTemplate = [];
            state.error = action.payload;
        },
    },
});

export const { findBankTemplateSuccess, findBankTemplateFailed } =
    bankTemplateSlice.actions;

export default bankTemplateSlice.reducer;
