import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import User from '../../../model/UserModel';
import { addUserSchema } from '../../schema/User';
import CdsTitle from '../../../components/CdsTitle';
import CdsButton from '../../../components/CdsButton';
import { UserDetails } from '../../../utility';
import { CREATE_USER } from '../../../sagas/types/user';
import { RootState } from '../../../sagas/slice/rootReducer';
import { GET_ALL_CUSTOMERS } from '../../../sagas/types/customer-file';

type CreateUserProps = {};

const CreateUser: React.FC<CreateUserProps> = (props) => {
    const userData = UserDetails();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = UserDetails();
    const initialValue: User = {
        UserID: '0',
        CustomerID: userInfo.CompanyID,
        CustomerName: userInfo.CustomerName,
        UserName: '',
        UserEmail: '',
        UserMobile: '',
        UserStatus: '',
        RoleName: userInfo.RoleName,
        RoleID: userInfo.RoleID,
        //Password: "",
        //Date: "",
    };
    useEffect(() => {
        dispatch({ type: GET_ALL_CUSTOMERS });
    }, [dispatch]);

    const myFormik = useFormik({
        initialValues: initialValue,
        validationSchema: addUserSchema,
        onSubmit: (values: User) => {
            dispatch({ type: CREATE_USER, user: values });
            navigate('/cp/users', { replace: true });
        },
    });
    
    const customers = useSelector(
        (state: RootState) => state.customerFile.uploadCustomerFile
    );


    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;

    return (
        <form onSubmit={handleSubmit} className="mb-4 container-fluid">
            <CdsTitle
                linkto="/cp/users"
                title="User"
                iconClass="bi bi-people"
                link="Back To List"
                headerDataTestId = 'Titleuser'
                linkDataTestId = 'backToList'
            ></CdsTitle>

            <div className="card mt-3 border rounded-4">
                <div className="card-header">
                    <h6 data-testid='titleAddUser' className="md-18">Add User Details</h6>
                </div>
                <div className="card-body">
                    <div className="row my-1">
                    {userData.RoleID == '1' && (
                        <div className="col">
                            <label className="form-label">
                                <h6 data-testid="labelcustomerName">Customer Name</h6>
                            </label>
                            <input
                            data-testid="inputCustomer"
                                type="text"
                                className="form-control"
                                value={userInfo.CustomerName}
                                disabled={true}
                            ></input>
                        </div>
                        )}

                        {userData.RoleID == '5' && (
                            <div className="col">
                                <label
                                    className="form-label"
                                >
                                    <h6 data-testid="labelCustomerName">
                                        Customer Name
                                    </h6>
                                </label>
                                   <select
                                    data-testid="selectCustomer"
                                    id="CustomerID"
                                    name="CustomerID"
                                    value={values.CustomerID}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="form-select"
                                >
                                    <option selected>
                                        Select Customer Name
                                    </option>
                                    {customers?.map(
                                        (item: any, index: number) => {
                                            return (
                                                <>
                                                    <option
                                                        value={item.CustomerID}
                                                    >
                                                        {item.CustomerName}
                                                    </option>
                                                </>
                                            );
                                        }
                                    )}
                                </select>
                                {errors.CustomerID && touched.CustomerID ? (
                                    <p
                                        data-testid="errorCustomerName"
                                        className="form-val form-error"
                                    >
                                        {errors.CustomerID}
                                    </p>
                                ) : null}
                            </div>
                        )}

                        <div className="col">
                            <label htmlFor="address" className="form-label">
                                <h6 data-testid='labelName'>Name</h6>
                            </label>
                            <input
                            data-testid='inputName'
                                type="text"
                                className="form-control"
                                id="UserName"
                                name="UserName"
                                value={values.UserName}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter name"
                            ></input>
                            {errors.UserName && touched.UserName ? (
                                <p  data-testid='errorName'className="form-val form-error">
                                    {errors.UserName}
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <div className="row my-4">
                        <div className="col">
                            <label htmlFor="contactNo" className="form-label">
                                <h6 data-testid='labelContactNumber'>Contact Number</h6>
                            </label>
                            <input
                            data-testid='inputContactNumber'
                                type="text"
                                className="form-control"
                                id="UserMobile"
                                name="UserMobile"
                                value={values.UserMobile}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Contact Number"
                                maxLength={10}
                            ></input>
                            {errors.UserMobile && touched.UserMobile ? (
                                <p data-testid='errorContactNumber' className="form-val form-error">
                                    {errors.UserMobile}
                                </p>
                            ) : null}
                        </div>

                        <div className="col">
                            <label htmlFor="email" className="form-label">
                                <h6 data-testid='labelEmail'>Email</h6>
                            </label>
                            <input
                            data-testid='inputEmail'
                                type="text"
                                className="form-control"
                                id="UserEmail"
                                name="UserEmail"
                                value={values.UserEmail}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Email"
                            ></input>
                            {errors.UserEmail && touched.UserEmail ? (
                                <p data-testid='errorEmail' className="form-val form-error">
                                    {errors.UserEmail}
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <div className="justify-content-end  row">
                        <div data-testid='btnCancel' className="col-auto">
                            <CdsButton
                                title="Cancel"
                                type="button"
                                handleClick={(e) => navigate('/cp/users')}
                            />
                        </div>
                        <div data-testid='btnSubmit' className="col-auto">
                            <CdsButton title="Submit" type="submit" />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default CreateUser;
