import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { addLoginSchema } from '../../features/schema/Login';
import { useAuth } from '../../hooks/useAuth';
import Login from '../../model/LoginModel';
import '../paybank/paybank.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
//import { FaEye, FaEyeSlash } from 'react-icons/fa';

type LoginPageProps = {};
const LoginPage: React.FC<LoginPageProps> = (props) => {
    const dispatch = useDispatch();
    const { login } = useAuth();
    const navigate = useNavigate();
    const initialValue: Login = {
        username: '',
        password: '',
    };

    const [showPassword, setShowPassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const handleBackClick = () => {
        navigate('/');
    };

    const myFormik = useFormik({
        initialValues: initialValue,
        validationSchema: addLoginSchema,
        onSubmit: async (values: Login) => {
            const error = await login({
                Email: values.username,
                Password: values.password,
            });

            if (error) {
                setErrorMessage(error);
            } else {
                setErrorMessage(null); // Clear error if successful
            }
        },
    });

    useEffect(() => {
        console.log('Error message:', errorMessage);
    }, [errorMessage]);

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;

    const handleForgotPasswordClick = () => {
        navigate('/forgot-password');
    };

    return (
        <div className="bg">
            <form
                onSubmit={handleSubmit}
                className=" bg-white border mx-auto max-width only-hover"
            >
                <div className="mt-4 row justify-content-center">
                    <div className="col-3">
                        <img
                            className="img-fluid"
                            src={require('../assets/logo.png')}
                            alt="Logo"
                        />
                    </div>
                </div>
                <div className="mt-4 row justify-content-center">
                    <div className="col-9">
                        <input
                            data-testid="txtUsername"
                            className="form-control"
                            type="text"
                            placeholder="Username"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="username"
                        />
                        {errors.username && touched.username ? (
                            <p
                                data-testid="ErrorUsername"
                                className="form-val form-error"
                            >
                                {errors.username}
                            </p>
                        ) : null}
                        <div className="password-input-container">
                            <input
                                data-testid="txtpassword"
                                className="mt-2 form-control"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="password"
                            />
                            <i
                                data-testid="btnShowPassword"
                                className={`bi ${
                                    showPassword ? 'bi-eye-slash' : 'bi-eye'
                                } password-icon`}
                                onClick={() => setShowPassword(!showPassword)}
                            ></i>
                            {errors.password && touched.password ? (
                                <p
                                    data-testid="ErrorPassword"
                                    className="form-val form-error"
                                >
                                    {errors.password}
                                </p>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div
                    data-testid="linktoForgotPassword"
                    className="mt-2 text-end pe-5 fw-medium"
                    onClick={handleForgotPasswordClick}
                    style={{
                        fontSize: 'small',
                        cursor: 'pointer',
                        textDecoration: 'underline',
                        color: 'blue',
                    }}
                >
                    Forgot Password
                </div>
                <div className="row mt-2 justify-content-center g-1">
                    <div className="col-sm-auto">
                        <button
                            data-testid="btnBack"
                            className=" btn btn-width btn-default btn-sm"
                            type="button"
                            onClick={handleBackClick}
                        >
                            Back
                        </button>
                    </div>
                    <div className="col-sm-auto">
                        <button
                            data-testid="btnSignIn"
                            type="submit"
                            className=" btn-width btn btn-default btn-sm"
                        >
                            Sign In
                        </button>
                    </div>

                    {errorMessage && (
                        <div className="row justify-content-center mt-3">
                            <p
                                data-testid="errorMsgCredentials"
                                className="text-red text-center"
                            >
                                {errorMessage}
                            </p>
                        </div>
                    )}
                </div>
                <div></div>
            </form>
        </div>
    );
};

export default LoginPage;
