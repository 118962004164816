import * as Yup from 'yup';

export const addNewPaymentTypeSchema = Yup.object({
    paymentType: Yup.string()
        .min(2)
        .max(20)
        .required('Please enter payment type'),
    description: Yup.string()
        .min(2)
        .max(300)
        .required('Please enter your description'),
});
