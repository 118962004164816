import { useFormik } from 'formik';
import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import '../paybank/paybank.css';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ForgotPasswordModel from '../../model/ForgotPasswordModel';
import { addForgotPasswordSchema } from '../../features/schema/ForgotPassword';

type ForgotPageProps = {};
const ForgotPassword: React.FC<ForgotPageProps> = (props) => {
    const { resetPassword } = useAuth();
    const navigate = useNavigate();
    const initialValue: ForgotPasswordModel = {
        username: '',
        password: '',
        confirmPassword: '',
    };

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handleBackClick = () => {
        navigate('/loginpage');
    };

    const myFormik = useFormik({
        initialValues: initialValue,
        validationSchema: addForgotPasswordSchema,
        onSubmit: (values: ForgotPasswordModel) => {
            console.log(values);
            resetPassword({
                Email: values.username,
                Password: values.password,
            });
        },
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;

    return (
        <div className="bg">
            <form
                onSubmit={handleSubmit}
                className=" bg-white border mx-auto max-width only-hover"
            >
                <div className="mt-4 row justify-content-center">
                    <div className="col-3">
                        <img
                            //width={100}
                            className="img-fluid"
                            src={require('../assets/logo.png')}
                        />
                    </div>
                </div>
                <div className="mt-4 row justify-content-center">
                    <div className="col-9">
                        <input
                            data-testid='txtUsername'
                            className="form-control"
                            type="text"
                            placeholder="Username"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            id="username"
                        />
                        {errors.username && touched.username ? (
                            <p data-testid="ErrorUsername"
                             className="form-val form-error">
                                {errors.username}
                            </p>
                        ) : null}
                        <div className="password-input-container">
                            <input
                                data-testid='txtPassword'
                                className="mt-2 form-control"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Password"
                                name="password"
                                value={values.password}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="password"
                            />
                            <i
                                 data-testid='showPassword'
                                className={`bi ${
                                    showPassword ? 'bi-eye-slash' : 'bi-eye'
                                } password-icon`}
                                onClick={() => setShowPassword(!showPassword)}
                            ></i>
                            {errors.password && touched.password ? (
                                <p 
                                data-testid="ErrorPassword"
                                className="form-val form-error">
                                    {errors.password}
                                </p>
                            ) : null}
                        </div>
                        <div className="password-input-container">
                            <input
                                data-testid='txtConfirmPassword'
                                className="mt-2 form-control"
                                type={showConfirmPassword ? 'text' : 'password'}
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                value={values.confirmPassword}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="confirmPassword"
                            />
                            <i
                                data-testid='showConfirmPassword'
                                className={`bi ${
                                    showConfirmPassword
                                        ? 'bi-eye-slash'
                                        : 'bi-eye'
                                } password-icon`}
                                onClick={() =>
                                    setShowConfirmPassword(!showConfirmPassword)
                                }
                            ></i>
                            {errors.confirmPassword &&
                            touched.confirmPassword ? (
                                <p 
                                data-testid="ErrorConfirmPassword"
                                className="form-val form-error">
                                    {errors.confirmPassword}
                                </p>
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="row my-4 justify-content-center g-1">
                    <div className="col-sm-auto">
                        <button  data-testid='btnBack'
                            className=" btn btn-width btn-default btn-sm"
                            type="button"
                            onClick={handleBackClick}
                        >
                            Back
                        </button>
                    </div>
                    <div className="col-sm-auto">
                        <button  data-testid='btnUpdatePassword'
                            type="submit"
                            className=" btn-width btn btn-default btn-sm"
                        >
                            Update Password
                        </button>
                    </div>
                </div>
                <div></div>
            </form>
        </div>
    );
};

export default ForgotPassword;
