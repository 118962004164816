import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import Bank from '../../../model/BankModel';
import ReduxError from '../../../model/ErrorModel';
import store from '../../../Store';
import { boolean } from 'yargs';

// Define a type for the slice state
interface BankState {
    isFetched: boolean;
    banks: Array<Bank>;
    error: ReduxError | null;
    bank: {
        save: {
            isSaving: boolean | null;
            status: boolean | null;
            error: ReduxError | null;
        };
    };
}

// Define the initial state using that type
const initialState: BankState = {
    isFetched: false,
    banks: new Array<Bank>(),
    error: null,
    bank: {
        save: {
            isSaving: null,
            error: null,
            status: null,
        },
    },
};



export const banksSlice = createSlice({
    name: 'bank',
    initialState,
    reducers: {
        // old
        fetchAllBanks: (state, action: PayloadAction) => {
            state.isFetched = false;
            state.banks = [];
            state.error = null;
        },
        fetchAllBanksSuccess: (state, action: PayloadAction<Bank[]>) => {
            state.isFetched = true;
            state.banks = action.payload;
        },
        // new
        // how to handle error: tbd
        findAllBanksSlice: (state, action: PayloadAction<Bank[]>) => {
            state.banks = action.payload;
            return state;
        },
        findAllBanksFailed: (state, action: PayloadAction<ReduxError>) => {
            state.isFetched = true;
            state.banks = [];
            state.error = action.payload;
        },
        triggerSaveBank: (state) => {
            state.bank.save.isSaving = true;
            state.bank.save.status = null;
            state.bank.save.error = null;
            return state;
        },
        addBankSuccess: (state) => {
            state.bank.save.isSaving = false;
            state.bank.save.error = null;
            state.bank.save.status = true;
            return state;
        },
        addBankFailed: (state, action: PayloadAction<ReduxError>) => {
            state.bank.save.isSaving = false;
            state.bank.save.error = action.payload;
            state.bank.save.status = false;
            return state;
        },
        resetSaveBank: (state) => {
            state.bank.save.isSaving = null;
            state.bank.save.status = null;
            state.bank.save.error = null;
            return state;
        },
        editBankSlice: (state, action: PayloadAction<Bank>) => {
            state.banks = state.banks.map((item) =>
                item.id == action.payload.id ? action.payload : item
            );
            return state;
        },
        deleteBankSlice: (state, action: PayloadAction<Bank>) => {
            // soft delete
            // TBD -> active/inactive
            state.banks = state.banks.map((item) =>
                item.id == action.payload.id ? action.payload : item
            );
            return state;
        },
    },
});

type RootState = ReturnType<typeof store.getState>;
export const {
    fetchAllBanks,
    fetchAllBanksSuccess,
    findAllBanksFailed,
    findAllBanksSlice,
    addBankSuccess,
    addBankFailed,
    editBankSlice,
    deleteBankSlice,
    resetSaveBank,
    triggerSaveBank,
} = banksSlice.actions;

export default banksSlice.reducer;
