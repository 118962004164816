import axios from 'axios';
import { type } from 'os';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import CdsTitle from '../../components/CdsTitle';
import {
    UPDATE_WORKFLOW_STATUS,
    WORKFLOW_LIST,
} from '../../sagas/types/workflow';
import { RootState } from '../../sagas/slice/rootReducer';
import CdsButton from '../../components/CdsButton';
import { UserDetails } from '../../utility';
import CdsModal from '../../components/CdsModal';
import { Console } from 'console';
import { useFormik } from 'formik';
import WorkflowStatus from '../../model/WorkflowStatusModel';
import workflows, { workflowSlice } from '../../sagas/slice/workflow/workflows';
import workflow, { workflowISlice } from '../../sagas/slice/workflow/workflow';
type WorkFlowsListProps = {};

const WorkFlowsList: React.FC<WorkFlowsListProps> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userData = UserDetails();
    const rows = useSelector(
        (state: RootState) => state.workflows.workflowList
    );
    console.log('workflow', rows);
    const [isConditionedChecked, setIsConditionedChecked] = useState(false);

    const initialValue = {
        WorkflowNumber: '',
    };
    useEffect(() => {
        
        if (userData.RoleID == '1') {
            dispatch({
                type: WORKFLOW_LIST,
                reqData: {
                    CompanyID: userData.CompanyID,
                    WorkflowNo: '',
                },
            });
        } else {
            dispatch({
                type: WORKFLOW_LIST,
                reqData: {
                    CompanyID: userData.CompanyID,
                    WorkflowNo: '',
                },
            });
        }
    }, [dispatch]);

    const searchWorkflow = (WorkflowNum: string) => {
        
        dispatch({
            type: WORKFLOW_LIST,
            reqData: {
                WorkflowNo: WorkflowNum,
                CompanyID: userData.CompanyID,
            },
        });
    };

    const myFormik = useFormik({
        initialValues: initialValue,
        onSubmit: (values) => {
            searchWorkflow(values.WorkflowNumber);
        },
    });
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;
    const goToCreateUser = () => {
        navigate('/cp/workflows/create', { replace: true });
    };

    const downloadInstance = (url) => {
        window.open(url);
        console.log('test');
    };
    const [WorkflowNumber, SetWorkflowNo] = useState();
    const [CompanyID, SetCompanyID] = useState();
    const [CustomerName, SetCustomerName] = useState();
    const [isDisplayMapperModal, setIsDisplayMapperModal] =
        useState<boolean>(false);

    const { isChanged, error } = useSelector(
        (state: RootState) => state.workflows.wfStatus
    );

    const updateStatus = (workflowID,CompanyID,CustomerName) => {
        
        SetWorkflowNo(workflowID);
        SetCompanyID(CompanyID);
        SetCustomerName(CustomerName);
        setIsDisplayMapperModal(true);
    };
    const closeModal = (value) => {
        setIsDisplayMapperModal(false);
        window.location.reload();
    };

    return (
        <div className="container-fluid">
            <CdsTitle
                linkto="/cp/workflows/create"
                title="Workflow"
                iconClass="bi bi-filetype-xml"
                link="Add Workflow"
                headerDataTestId = 'workflowTitle'
                linkDataTestId = 'addWorkFlowLink'
            ></CdsTitle>
            {/* <a href="/cp/workflows/create">To Create</a> */}
            <div className="card mt-3  rounded-3">
                <div className="card-header">
                    <form
                        onSubmit={handleSubmit}
                        className="row my-2 justify-content-between"
                    >
                        <div className="col">
                            <div className="input-group">
                                <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                >
                                    <i className="bi bi-search"></i>
                                </span>
                                <input
                                data-testid='inputSearch'
                                    type="text"
                                    className="form-control"
                                    id="WorkflowNumber"
                                    name="WorkflowNumber"
                                    value={values.WorkflowNumber}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Workflow Number"
                                ></input>
                            </div>
                        </div>
                        <div className="col-2">
                            <button data-testid='btnSearch' className="w-100 btn btn-default">
                                Search
                            </button>
                        </div>
                    </form>
                </div>
                <div className="card-body">
                    <table data-testid='tableWorkFlow' className="table table-striped">
                        <thead>
                            {
                                <tr className="">
                                    <th scope="col">
                                        <h6 className="my-2 semi-bold">
                                            WorkFlow No
                                        </h6>
                                    </th>
                                    <th className="" scope="col">
                                        <h6 className="my-2 semi-bold">
                                            Customer
                                        </h6>
                                    </th>
                                    <th className="" scope="col">
                                        <h6 className="my-2 semi-bold">
                                            Creation Date
                                        </h6>
                                    </th>
                                    <th className="" scope="col">
                                        <h6 className="my-2 semi-bold">
                                            Status
                                        </h6>
                                    </th>
                                    {userData.RoleID == '5' && (
                                        <th>
                                            <h6 className="my-2 semi-bold">
                                                Edit Status
                                            </h6>
                                        </th>
                                    )}

                                    {/* <th className="" scope="col">
                                        <h6 className="my-2 semi-bold">
                                            Next Action
                                        </h6>
                                    </th> */}
                                    <th className="" scope="col">
                                        <h6 className="my-2 semi-bold">
                                            Download
                                        </h6>
                                    </th>
                                </tr>
                            }
                        </thead>
                        <tbody>
                            {rows.map((item,index:number) => {
                                return (
                                    <>
                                        <tr data-testid={`workflow-row${index}`}>
                                            <td data-testid={`workflow-Number${index}`}>{item.WorkflowNo}</td>
                                            <td data-testid={`workflow-CustName${index}`}>{item.CustomerName}</td>
                                            <td data-testid={`workflow-CreatedDate${index}`}>{item.WorkflowCreationDate}</td>
                                            <td data-testid={`workflow-Status${index}`}>{item.WorkflowStatus}</td>
                                            {userData.RoleID == '5' && (
                                                <td data-testid={`btnEdit${index}`}>
                                                    <CdsButton
                                                        title="Edit Status"
                                                        type="button"
                                                        handleClick={() =>
                                                            updateStatus(
                                                                item.WorkflowNo,
                                                                item.CompanyID,
                                                                item.CustomerName
                                                            )
                                                        }
                                                    ></CdsButton>
                                                </td>
                                            )}
                                            {/* <td>{item.NextAction}</td> */}
                                            {item.WorkflowStatusID == '5' ? (
                                                <td data-testid={`btnDownload${index}`}>
                                                    {/* <CdsButton
                                                        title=""
                                                        type="button"
                                                        customClass="bi bi-download"
                                                        handleClick={(url) =>
                                                            downloadInstance(
                                                                'http://paybrijapi.copiacs.com/UploadedFile/PayBrij_Utility.zip'
                                                            )
                                                        }
                                                    ></CdsButton>  */} 
                                                    <CdsButton
                                                        title=""
                                                        type="button"
                                                        customClass="bi bi-download"
                                                        handleClick={() => {
                                                            const companyId = item.CompanyID;
                                                            const formattedCustomerName = item.CustomerName.replace(/\s+/g, '_');
                                                            const downloadUrl = `https://paybrijapi.copiacs.com/UploadedFile/${formattedCustomerName}_${companyId}.zip`;
                                                            //const downloadUrl = `http://paybrijapi.copiacs.com/UploadedFile/${encodeURIComponent(customerName)}.zip`;
                                                            downloadInstance(downloadUrl);
                                                        }}
                                                        ></CdsButton>
                                                </td>
                                            ) : item.WorkflowStatusID < '5' ? (
                                                <td data-testid={`ApprovalPending${index}`}>Approval Pending</td>
                                            ) : (
                                                <td data-testid={`InstanceDownloaded${index}`}>
                                                    Instance already downloaded
                                                </td>
                                            )}
                                        </tr>
                                    </>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
            <CdsModal
                show={isDisplayMapperModal}
                handleClose={() => setIsDisplayMapperModal(false)}
                handleShow={() => setIsDisplayMapperModal(true)}
                modalTitle="Change Status"
                size="lg"
            >
                <div>
                    <ChangeStatus
                        closeModal={closeModal}
                        workflowID={WorkflowNumber}
                        CompanyID={CompanyID}
                        CustomerName={CustomerName}
                    ></ChangeStatus>
                </div>
            </CdsModal>
        </div>
    );
};
const ChangeStatus = (props) => {
    console.log(props.workflowID);
    console.log(props.CompanyID);
    console.log(props.CustomerName);

    const initialValue: WorkflowStatus = {
        StatusId: 1,
        WorkflowNo: null,
        CompanyID: props.CompanyID,
        CustomerName: props.CustomerName,
    };
    const dispatch = useDispatch();
    const { isChanged, error } = useSelector(
        (state: RootState) => state.workflows.wfStatus
    );

    const myFormik = useFormik({
        initialValues: initialValue,
        onSubmit: (values: WorkflowStatus) => {
            
            const data = {
                StatusId: values.StatusId,
                WorkflowNo: props.workflowID,
                CompanyID: props.CompanyID,
                CustomerName: props.CustomerName
            };
            console.log(data);
            dispatch({ type: UPDATE_WORKFLOW_STATUS, workflowStatus: data });
            props.closeModal(true);
        },
    });

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
        dirty,
    } = myFormik;
    const changeStatusApi = (value) => {
        
        console.log(value);
    };
    return (
        <>
            <form onSubmit={handleSubmit}>
                <h3 data-testid='titleWorkflowId'>Workflow ID : {props.workflowID}</h3>
                <select
                data-testid='selectStatus'
                    className="form-select"
                    onChange={(e) => handleChange(e)}
                    name="StatusId"
                    value={values.StatusId}
                    onBlur={handleBlur}
                    id="StatusId"
                >
                    <option>Open this select menu</option>
                    <option value={1}>Draft</option>
                    <option value={3}>Submitted/ATP Approved</option>
                    <option value={5}>Instance Ready</option>
                    <option value={6}>UAT Completed</option>
                    <option value={7}>PROD Completed</option>
                    <option value={8}>Closed</option>
                    <option value={10}>Deployment Completed</option>
                </select>
                <div className="my-2 row justify-content-end">
                    <div data-testid='btnSave' className="col-auto ">
                        <CdsButton title="Save" type="submit"></CdsButton>
                    </div>
                </div>
            </form>
        </>
    );
};
const Modal = () => {
    const [isDisplayMapperModal, setIsDisplayMapperModal] =
        useState<boolean>(false);
    return (
        <>
            <CdsModal
                show={isDisplayMapperModal}
                handleClose={() => setIsDisplayMapperModal(false)}
                handleShow={() => setIsDisplayMapperModal(true)}
                modalTitle="Select Customer Field"
            ></CdsModal>
        </>
    );
};
export default WorkFlowsList;
