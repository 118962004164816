import { AnyAction } from "@reduxjs/toolkit";
import axios from "axios";
import API from "../../constants/Api";
import Customer  from "../../model/CustomerModel";
import { ApiResponse } from "../../model/ApiResponseModel";

export async function findAllCustomersRequest(
  requestPayload: any
): Promise<ApiResponse> {
  
  const result: ApiResponse = {
    data: null,
    error: null,
};
  const body = {
    CustomerName:requestPayload
  };
  return await axios({
    method: API.TYPE.POST,
    url: API.CUSTOMER.GET_CUSTOMER_LIST,
    data: body,
  }).then((response) => {
    // set response to data
    console.log(response.data.CustomerDetails);
    result.data = response.data.CustomerDetails;
    return result.data;
  });
}

export async function createCustomerRequest(payload : Customer):Promise<boolean> {
  
  const body = {
    CustomerName: payload.customername,
    Address: payload.address,
    CustomerContact: payload.contact,
    City: payload.city,
    PinCode: payload.pincode,
    CustomerContact_Mobile: payload.customermobileNo,
    CustomerContact_Email: payload.customeremail,
    IsActive: payload.active,
  }
  return await axios({
    method: API.TYPE.POST,
    url: API.CUSTOMER.CREATE_CUSTOMER,
    data: body,
  }).then((response) => {
    console.log(response.data);
    return response.data;
  });
}

export async function updateCustomerRequest(payload: Customer): Promise<Customer> {
  const body = {
    ID: payload.id,
    CustomerName: payload.customername,
    Address: payload.address,
    CustomerContact: payload.contact,
    City: payload.city,
    PinCode: payload.pincode,
    CustomerContact_Mobile: payload.customermobileNo,
    CustomerContact_Email: payload.customeremail,
    IsActive: payload.active,
  };
  return await axios({
    method: API.TYPE.POST,
    url: API.CUSTOMER.UPDATE_CUSTOMER,
    data: body,
  }).then((response) => {
    console.log(response.data);
    return response.data;
  });
}

export async function findCustomerByIdRequest(
  selectedCustomerId: number
): Promise<Customer> {
  return await axios({
    method: API.TYPE.POST,
    url: API.CUSTOMER.GET_CUSTOMER,
    data: { id: selectedCustomerId },
  }).then((response) => {
    console.log(response.data);
    // status code , add if
    return response.data.CustomerDetails[0];
  });
}

export async function deleteCustomerByIdRequest(payload: number): Promise<boolean> {
  return await axios({
    method: API.TYPE.POST,
    url: API.CUSTOMER.DELETE_CUSTOMER,
  }).then((response) => {
    console.log(response.data);
    return response.data;
  });
}

