import * as Yup from 'yup';

export const addWorkflowSchema = Yup.object({
    FileTransmission: Yup.string().required('Please select Yes or No'),
    NeedEncryption: Yup.string().required('Please select Yes or No'),
    ForwardFlow: Yup.string().required('Please select Yes or No'),
    ReverseFlow: Yup.string().required('Please select Yes or No'),
    FilesInBanksFormat: Yup.string().required('Please select Yes or No'),
    OperatingSystemID: Yup.string().required(
        'Please select server operating system'
    ),
    FileTransmissionID: Yup.string().required('Please select transmission type'),
    IsTermsAndConditionSelected: Yup.boolean().required('Please select Terms & Conditions'),
    IsHeaderPresent: Yup.string().required('Please select Yes or No'),
    FileType: Yup.string().required('Please select file type'),
});
