import * as Yup from "yup";

export const addCustomerSchema = Yup.object({
  customername: Yup.string().min(2).max(25).required("Please enter your name"),
  address: Yup.string().min(5).max(80).required("Please enter your address"),
  // contact: Yup.string().matches(/^\d{10}$/, "Phone number must be exactly 10 digits").required("Please enter your Contact Number"),
  city: Yup.string().min(2).max(15).required("Please enter your city"),
  pincode:  Yup.string().matches(/^\d{6}$/, "Pincode must be exactly 6 digits").required("Please enter your Pincode"),
  customermobileNo: Yup.string().matches(/^\d{10}$/, "Phone number must be exactly 10 digits").required("Please enter your Contact Number"),
  customeremail: Yup.string().email().required("Please enter your email"),
});
