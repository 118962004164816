type SortableTreeItemProps = {
    title: string;
    data: any;
    handleClick: (e: any) => void;
};
const SortableTreeItem: React.FC<SortableTreeItemProps> = (props) => {
    return (
        <div
            style={{
                color: 'green',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                border: '1px dotted #f4f4f4',
            }}
        >
            <div
                style={{
                    color: '#0d6efd',
                    pointerEvents: 'none',
                    paddingRight: '10px',
                }}
            >
                {' '}
                {props.title}
            </div>
            <i className="bi bi-arrow-left"></i>
            <button
                type="button"
                className="btn-sm btn-default"
                onClick={() => props.handleClick(props.data)}
            >
                Map
            </button>
            <i className="bi bi-arrow-right"></i>
            <div
                style={{
                    color: '#0d6efd',
                    pointerEvents: 'none',
                    paddingLeft: '10px',
                }}
            >
                {' '}
                {props.title}
            </div>
        </div>
    );
};

export default SortableTreeItem;
