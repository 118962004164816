import axios from 'axios';
import { type } from 'os';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import {
    fetchAllCustomer,
    selectAllCustomers,
} from '../../../sagas/slice/customer/Customers';
import { RootState } from '../../../sagas/slice/rootReducer';
import { FIND_ALL_CUSTOMERS } from '../../../sagas/types/customer';
import { useFormik } from 'formik';
import CdsTitle from '../../../components/CdsTitle';

type CustomersProps = {};

const CustomerList: React.FC<CustomersProps> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const rows = useSelector((state: RootState) => state.customers.customers);

    const initialValue = {
        CustomerName: '',
    };

    useEffect(() => {
        
        dispatch({ type: FIND_ALL_CUSTOMERS });
    }, [dispatch]);

    const myFormik = useFormik({
        initialValues: initialValue,
        onSubmit: (values) => {
            console.log(values);
            searchCustomer(values.CustomerName);
        },
    });
    const searchCustomer = (CustomerName: any) => {
        
        dispatch({ type: FIND_ALL_CUSTOMERS, reqData: CustomerName });
    };
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;

    return (
        <div className="container-fluid">
            <CdsTitle
                linkto="/cp/customer/createcustomer"
                title="Customer"
                iconClass="bi bi-people"
                link="Add Customer"
                headerDataTestId = 'customerTitle'
                linkDataTestId = 'addCustomerlink'
            ></CdsTitle>
            <div className="card mt-3 rounded-3">
                <div className="card-header">
                    <form
                        onSubmit={handleSubmit}
                        className="row my-2 justify-content-between"
                    >
                        <div className="col">
                            <div className="input-group">
                                <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                >
                                    <i className="bi bi-search"></i>
                                </span>
                                <input
                                data-testid = "searchCustomer"
                                    type="text"
                                    className="form-control"
                                    name="CustomerName"
                                    value={values.CustomerName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="CustomerName"
                                    placeholder="Enter Customer Name"
                                ></input>
                            </div>
                        </div>
                        <div className="col-2">
                            <button data-testid = "btnSearch" className="w-100 btn btn-default">
                                Search
                            </button>
                        </div>
                    </form>
                </div>
                <div className="card-body">
                    <table data-testid = "customerTable" className="table table-striped">
                        <thead>
                            <tr className="">
                                <th scope="col">
                                    <h6 className="my-2 semi-bold">
                                        Customer Name
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">City</h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">Pin Code</h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">
                                        Customer Contact
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">
                                        Customer Email
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">Address</h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">Edit</h6>
                                </th>
                                {/* <th className="" scope="col">
                  <h6 className="my-2 semi-bold">Delete</h6>
                </th>  */}
                            </tr>
                        </thead>
                        <tbody>
                            {rows?.map((item: any,index:number) => {
                                return (
                                    <>
                                        <tr data-testid={`customer-row-${index}`} >
                                            <td data-testid={`customer-Name-${index}`} >{item.CustomerName}</td>
                                            <td data-testid={`customer-City-${index}`}>{item.City}</td>
                                            <td data-testid={`customer-Pincode-${index}`}>{item.PinCode}</td>
                                            {/* <td>{item.ID}</td> */}

                                            <td data-testid={`customer-Mobile-${index}`}>
                                                {item.CustomerContact_Mobile}
                                            </td>
                                            <td data-testid={`customer-Email-${index}`}>
                                                {item.CustomerContact_Email}
                                            </td>
                                            <td data-testid={`customer-Address-${index}`}>{item.Address}</td>
                                            <td>
                                                {
                                                    <Link data-testid={`btnEdit-${index}`}
                                                        to={`/cp/customer/editcustomer/${item.ID}`}
                                                    >
                                                        <button className="btn">
                                                            <i className="fa-2x bi bi-pencil-square"></i>
                                                        </button>
                                                    </Link>
                                                }
                                            </td>
                                            {/* <td>
                        {<button className="btn">
                          <i className="bi bi-trash"></i>
                        </button>}
                      </td>  */}
                                        </tr>
                                    </>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CustomerList;
