import React, { useState } from 'react';
import '../paybank/paybank.css';
import { data } from '../../model/ViewAllFaqModel';
import { useNavigate, useParams } from 'react-router-dom';

const FaqViewAll = () => {
    const navigate = useNavigate();
    const handleBackButtonClick = () => {
        navigate(-1);
    };
    const [search, setSearch] = useState('');
    return (
        <div className="">
            <nav className="border-bottom navbar bg-nav navbar-expand-lg bg-body-tertiary sticky-top">
                <div className="container">
                    <div className="row p-0">
                        <div className="col-sm-auto">
                            <a href="#carouselExampleCaptions">
                                <img
                                    //width={100}
                                    className="img-fluid img-ht-2"
                                    src={require('../assets/logo.png')}
                                />
                            </a>
                        </div>
                        {/* <div className="col">
                            <a className="navbar-brand" href="#">
                                PayBrij
                            </a>
                        </div> */}
                    </div>

                    <div className="mx-5 collapse navbar-collapse text-right ">
                        <ul className="navbar-nav mt-4">
                            <li className="nav-item">
                                <a
                                    className="nav-link active"
                                    aria-current="page"
                                    href="/"
                                >
                                    <h4 className="">Home</h4>
                                </a>
                            </li>
                            <li className="nav-item mx-2">
                                <a className="nav-link active" href="/">
                                    <h4 className="">Features</h4>
                                </a>
                            </li>
                            <li className="nav-item mx-2">
                                <a className="nav-link active" href="/">
                                    <h4 className="">Deployment</h4>
                                </a>
                            </li>
                            <li className="nav-item mx-2">
                                <a className="nav-link active" href="/">
                                    <h4 className="">How it works</h4>
                                </a>
                            </li>
                            <li className="nav-item mx-2">
                                <a className="nav-link active" href="/">
                                    <h4 className="">FAQ</h4>
                                </a>
                            </li>
                            <li className="nav-item mx-2">
                                <a
                                    className="nav-link active"
                                    href="/#contactus"
                                >
                                    <h4 className="">Contact Us</h4>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="row justify-content-start mt-4">
                        <div className="col-sm-auto">
                            <button className="w-100 sign-up p-1 btn-min-width">
                                Sign In
                            </button>
                        </div>
                        <div className="col-sm-auto">
                            <button
                                // onClick={() => goToCp()}
                                className="w-100 btn-min-width sign-in"
                            >
                                Sign Up
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
            {/* view all faq's */}
            <div className="container">
                <div className="row pr-5">
                    <button
                        onClick={handleBackButtonClick}
                        className="btn btn-sm rounded-4 left mt-3"
                    >
                        Back
                    </button>
                </div>
            </div>
            <div className="container my-5 p-3 rounded-3 content">
                <div id="faq" className="">
                    <div className="row">
                        <div className="">
                            <h2 className="h2 text-center md-40 fw-bold">
                                FAQ's
                            </h2>
                            <hr className="line mx-auto "></hr>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col mt-2">
                            <h3 className="h3 text-center text-grey">
                                You can go through these questions or search
                                within the list.
                            </h3>
                        </div>
                    </div>
                    <div className="row">
                        <form className="input-group mt-2 mb-3">
                            <span
                                className="input-group-text"
                                id="basic-addon1"
                            >
                                <i className="bi bi-search"></i>
                            </span>
                            <input
                                onChange={(e) => setSearch(e.target.value)}
                                id="myInput"
                                type="text"
                                className="form-control"
                                aria-label="Username"
                                aria-describedby="basic-addon1"
                            />
                        </form>
                    </div>
                    <div className="row">
                        <div className="height container mt-1">
                            <div
                                className="accordion accordion-flush p-2 "
                                id="accordionFlushExample"
                            >
                                {data
                                    .filter((item) => {
                                        return search.toLowerCase() === ''
                                            ? item
                                            : item.question
                                                  .toLowerCase()
                                                  .includes(search);
                                    })
                                    .map((item) => (
                                        <div
                                            key={item.key}
                                            className="accordion-item  mb-2"
                                        >
                                            <h2 className="accordion-header">
                                                <button
                                                    className="accordion-button collapsed hover"
                                                    type="button"
                                                    data-bs-toggle="collapse"
                                                    data-bs-target={`#${item.key}`}
                                                    aria-expanded="false"
                                                    aria-controls="flush-collapseOne"
                                                >
                                                    {item.question}
                                                </button>
                                            </h2>
                                            <div
                                                id={item.key}
                                                className="accordion-collapse collapse"
                                                data-bs-parent="#accordionFlushExample"
                                            >
                                                <div className="accordion-body">
                                                    {item.answer}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FaqViewAll;
