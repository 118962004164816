import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import Customer from "../../../model/CustomerModel";
import ReduxError from "../../../model/ErrorModel";

interface CustomerState {
    isFetched: boolean;
    customer: Customer;
    error: ReduxError | null;
  }

  const initialState: CustomerState = {
    isFetched: false,
    customer: null,
    error: null,
  };

export const customerSlice = createSlice({
    name : "customer",
    initialState ,
    reducers : {
      setCustomerSlice: (state, action: PayloadAction<Customer>) => {
        
        state.customer = action.payload;
        return state;
      },
        //tbd
    }

})
export const { setCustomerSlice } = customerSlice.actions;
export default customerSlice.reducer;