import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import BankFileListModel from "../../../model/BankFileListModel";
import ReduxError from "../../../model/ErrorModel";
import UploadBankFile from "../../../model/UploadBankFileModel";
import store from "../../../Store";

interface UploadBankFileState {
    isFetched: boolean;
    uploadBankFile: Array<UploadBankFile>;
    error: ReduxError | null;
  }

  const initialState: UploadBankFileState = {
    isFetched: false,
    uploadBankFile: new Array<UploadBankFile>(),
    error: null,
  };

  export const BankFileSlice = createSlice({
    name : "bankFile",
    initialState,
    reducers:{
        uploadBankFileSlice:(state  , action: PayloadAction<UploadBankFile> ) => {
            state.uploadBankFile.push(action.payload);
            return state;
          },
    }
  });
  type RootState = ReturnType<typeof store.getState>;
  export const {
    uploadBankFileSlice,
  } = BankFileSlice.actions;
  
  // Other code such as selectors can use the imported `RootState` type
  export const selectAllBankFile = (state: RootState) => state.uploadBankFile.uploadBankFile;
  
  export default BankFileSlice.reducer;
  
