import { all, call, put, takeLatest } from "redux-saga/effects";
import { createRoleRequest, findAllRolesRequest } from "../requests/Role";
import Role from "../../model/RoleModel";
import { addRoleFailed, addRoleSuccess, fetchAllRoleFailed, fetchAllRoleSuccess } from "../slice/role/Roles";
import { CREATE_ROLE, FIND_ALL_ROLE } from "../types/role";
import { triggerSaveUser } from "../slice/user/Users";
import { getErrorMessage } from "../../utility";


  function* fetchRoleListSaga(action: any) {
    try {
        
      const response: Array<Role> = yield call(findAllRolesRequest, action.reqData);
      yield put(fetchAllRoleSuccess(response));
    } catch (e: any) {
      yield put(fetchAllRoleFailed(e));
    }
  }
  export function* createRoleSaga(action) {
    try {
      
        yield triggerSaveUser();
        console.log(action.bank);
        const response = yield createRoleRequest(action.role);
        if (response) {
            yield put(addRoleSuccess());
        } else {
            yield put(
                addRoleFailed(getErrorMessage('Error in creating new bank.'))
            );
        }
    } catch (error) {
        addRoleFailed(getErrorMessage('Error in creating new bank.', error));
    }
}

function* rolesaga(){
yield all ([takeLatest(FIND_ALL_ROLE , fetchRoleListSaga)]);
yield all ([takeLatest(CREATE_ROLE , createRoleSaga)]);
}

export default rolesaga;