export const mockXml = `<?xml version="1.0" encoding="utf-8" ?>
<PmtInf>
                                                                      <PmtInfId></PmtInfId>
                                                                      <PmtMtd>TRF</PmtMtd>
                                                                      <PmtTpInf>
                                                                          <SvcLvl>
                                                                          <Cd></Cd>
                                                                          </SvcLvl>
                                                                      </PmtTpInf><ReqdExctnDt></ReqdExctnDt>
                                                                      <Dbtr>
                                                                          <Nm></Nm>
                                                                          <PstlAdr><StrtNm></StrtNm><BldgNb></BldgNb><PstCd></PstCd><TwnNm></TwnNm><Ctry></Ctry>
                                                                          </PstlAdr>
                                                                      </Dbtr>
                                                                      <DbtrAcct>
                                                                          <Id>
                                                                              <Othr>
                                                                                  <Id></Id>
                                                                              </Othr>
                                                                          </Id>
                                                                          <Ccy></Ccy>
                                                                      </DbtrAcct>
                                                                      <DbtrAgt>
                                                                          <FinInstnId> 
                                                                          <BIC></BIC>
                                                                          <Nm>HSBC</Nm>
                                                                              <PstlAdr>
                                                                                  <Ctry></Ctry>
                                                                              </PstlAdr>
                                                                          </FinInstnId>
                                                                      </DbtrAgt><UltmtDbtr><Nm></Nm> <PstlAdr><StrtNm></StrtNm><TwnNm></TwnNm><Ctry></Ctry></PstlAdr>
                                                                                      </UltmtDbtr><ChrgBr>DEBT</ChrgBr>
                                                          <CdtTrfTxInf>
                                                          <PmtId>
                                                              <InstrId></InstrId>
                                                              <EndToEndId></EndToEndId>
                                                          </PmtId>
                                                          <Amt>
                                                              <InstdAmt Ccy='INR'></InstdAmt>
                                                          </Amt>
                                                          <CdtrAgt>
                                                              <FinInstnId>
                                                                  <ClrSysMmbId>
                                                                      <MmbId></MmbId>
                                                                  </ClrSysMmbId>
                                                                  <PstlAdr>
                                                                      <Ctry></Ctry>
                                                                  </PstlAdr>
                                                              </FinInstnId>
                                                          </CdtrAgt>
                                                          <Cdtr>
                                                              <Nm></Nm>
                                                              <PstlAdr>
                                                                  <Ctry></Ctry>
                                                              </PstlAdr>
                                                          </Cdtr>
                                                          <CdtrAcct>
                                                              <Id>
                                                                  <Othr>
                                                                      <Id></Id>
                                                                  </Othr>
                                                              </Id>
                                                          </CdtrAcct><RmtInf><Ustrd></Ustrd></RmtInf></CdtTrfTxInf>
                                                                      </PmtInf>`;
