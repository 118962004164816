import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { addBankSchema } from '../../schema/Bank';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../sagas/slice/rootReducer';
import Bank from '../../../model/BankModel';
import { FIND_BANK_BY_ID, UPDATE_BANK_BY_ID } from '../../../sagas/types/bank';
import CdsButton from '../../../components/CdsButton';
import CdsInput from '../../../components/CdsInput';
import CdsTitle from '../../../components/CdsTitle';
//console.log(bankData);

type EditBankProps = {};
const EditBank: React.FC<EditBankProps> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const bank: Bank = useSelector((state: RootState) => state.bank.bank);
    const { status } = useSelector((state: RootState) => state.banks.bank.save);

    const initialValue: Bank = {
        id: 0,
        bankname: '',
        address: '',
        contact: '',
        city: '',
        pincode: '',
        bankmobileNo: '',
        bankemail: '',
        active: '',
    };

    console.log(params);
    
    const myFormik = useFormik({
        initialValues: initialValue,
        validationSchema: addBankSchema,
        onSubmit: (values) => {
            console.log(values);
            
            dispatch({ type: UPDATE_BANK_BY_ID, bank: values });
        },
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;
    // pageload
    useEffect(() => {
        dispatch({ type: FIND_BANK_BY_ID, id: params.id });
    }, [dispatch]);

    // to load data for editing
    useEffect(() => {
        if (bank) {
            onEditSetBankValues(bank);
        }
    }, [bank]);

    useEffect(() => {
        if (status != null && status) {
            console.log('display success message');
            navigate('/cp/bank', { replace: true });
        } else {
            console.log('display failed message');
        }
    }, [status]);

    // typing TBD
    const onEditSetBankValues = (bank: any) => {
        myFormik.setValues({
            id: bank.ID,
            bankname: bank.BankName,
            address: bank.Address,
            contact: bank.BankContact,
            city: bank.City,
            pincode: bank.PinCode,
            bankmobileNo: bank.Contact_Mobile,
            bankemail: bank.Contact_Email,
            active: bank.IsActive,
        });
    };
    return (
        <form onSubmit={handleSubmit} className="mb-4 container-fluid">
            <CdsTitle
                linkto="/cp/bank"
                title="Bank"
                iconClass="bi bi-bank"
                link="View All Banks"
                headerDataTestId = 'headingBank'
                linkDataTestId = 'btnViewAllBanks'
            ></CdsTitle>
            <div className="card mt-3 border rounded-4">
                <div className="card-header">
                    <h6 data-testid='titleBankDetails' className="md-18">Bank Details</h6>
                </div>
                {bank ? (
                    <div className="card-body">
                        <div className="row my-4">
                            <div className="col">
                                <label
                                    htmlFor="bankName"
                                    className="form-label"
                                >
                                    <h6 data-testid='labelName'>Name</h6>
                                </label>
                                <input
                                data-testid='nameInput'
                                    type="text"
                                    className="form-control"
                                    name="bankname"
                                    value={values.bankname}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="bankName"
                                    placeholder="Enter Bank Name"
                                ></input>
                                {errors.bankname && touched.bankname ? (
                                    <p data-testid='errorName' className="form-val form-error">
                                        {errors.bankname}
                                    </p>
                                ) : null}
                            </div>
                            <div className="col">
                                <label htmlFor="address" className="form-label">
                                    <h6 data-testid='headingAddress'>Address</h6>
                                </label>
                                <input
                                data-testid='inputAddress'
                                    type="text"
                                    className="form-control"
                                    id="address"
                                    name="address"
                                    value={values.address}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Address"
                                ></input>
                                {errors.address && touched.address ? (
                                    <p data-testid='errorinAddress' className="form-val form-error">
                                        {errors.address}
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col">
                                <label
                                    htmlFor="contactNo"
                                    className="form-label"
                                >
                                    <h6  data-testid='labelContactNumber'>Contact Number</h6>
                                </label>
                                <input
                                data-testid='contactNumberInput'
                                    type="number"
                                    className="form-control"
                                    id="contactNo"
                                    name="contact"
                                    value={values.contact}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Contact Number"
                                ></input>
                                {errors.contact && touched.contact ? (
                                    <p data-testid='errorContact' className="form-val form-error">
                                        {errors.contact}
                                    </p>
                                ) : null}
                            </div>

                            <div className="col">
                                <label htmlFor="city" className="form-label">
                                    <h6 data-testid='headingCity'>City</h6>
                                </label>
                                <input
                                data-testid='inputCity'
                                    type="text"
                                    className="form-control"
                                    id="city"
                                    name="city"
                                    value={values.city}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter City"
                                ></input>
                                {errors.city && touched.city ? (
                                    <p data-testid='cityError'className="form-val form-error">
                                        {errors.city}
                                    </p>
                                ) : null}
                            </div>
                            <div className="col">
                                <label htmlFor="pincode" className="form-label">
                                    <h6 data-testid='headingPincode'>Pin Code</h6>
                                </label>
                                <input
                                data-testid='PincodeInput'
                                    type="number"
                                    className="form-control"
                                    id="pincode"
                                    name="pincode"
                                    value={values.pincode}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter PIN Code"
                                ></input>
                                {errors.pincode && touched.pincode ? (
                                    <p data-testid='ErrorPincode' className="form-val form-error">
                                        {errors.pincode}
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <div className="row justify-content-evenly my-4">
                            <div className="col-6">
                                <label
                                    htmlFor="bankContactNo"
                                    className="form-label"
                                >
                                    <h6 data-testid='HeadingBankContact'>Bank Contact Number</h6>
                                </label>
                                <input
                                data-testid='InputBankContact'
                                    type="number"
                                    className="form-control"
                                    id="bankmobileNo"
                                    name="bankmobileNo"
                                    value={values.bankmobileNo}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Contact Number"
                                ></input>
                                {errors.bankmobileNo && touched.bankmobileNo ? (
                                    <p data-testid='BankContactError' className="form-val form-error">
                                        {errors.bankmobileNo}
                                    </p>
                                ) : null}
                            </div>

                            <div className="col-6">
                                <label htmlFor="email" className="form-label">
                                    <h6 data-testid='HeadingEmail'>Bank Email</h6>
                                </label>
                                {/* <input
                                type="text"
                                className="form-control"
                                id="email"
                                name="bankemail"
                                value={values.bankemail}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Email ID"
                            ></input> */}
                                <CdsInput
                                    type="text"
                                    id="email"
                                    name="bankemail"
                                    value={values.bankemail}
                                    handleChange={(value) => {
                                        myFormik.setFieldValue(
                                            'bankemail',
                                            value
                                        );
                                        console.log(value);
                                    }}
                                    handleBlur={(value) => {
                                        myFormik.setFieldValue(
                                            'bankemail',
                                            value
                                        );
                                        console.log(value);
                                    }}
                                    placeholder="Enter Email ID"
                                    inputDataTestId="InputEmail"
                                />
                                {errors.bankemail && touched.bankemail ? (
                                    <p data-testid='ErrorEmail' className="form-val form-error">
                                        {errors.bankemail}
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <div className="justify-content-end  row">
                            <div data-testid='btnEditCancel' className="col-auto">
                                <CdsButton
                                    title="Cancel"
                                    type="button"
                                    handleClick={(e) => navigate('/cp/bank')}
                                />
                            </div>
                            <div  data-testid='btnEditSubmit' className="col-auto">
                                <CdsButton
                                    title="Submit"
                                    type="submit"
                                    handleClick={(e) => handleSubmit()}
                                />
                                {/* {isSaving != null && isSaving
                                    ? renderLoader()
                                    : renderSubmit()} */}
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </form>
    );
};

export default EditBank;
