import workflows from './workflows';
import Workflow  from '../../../model/WorkflowModel'
import ReduxError from '../../../model/ErrorModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface workflowstate {
    isFetched: boolean;
    isStatusUpdated: boolean;
    workflow: Workflow
    error: ReduxError | null;
}

// Define the initial state using that type
const initialState: workflowstate = {
    isFetched: false,
    workflow: null,
    isStatusUpdated: false,
    error: null,
};

export const workflowISlice = createSlice({
    name: 'workflow',
    initialState,
    reducers: {
        setworkflowSlice: (state, action: PayloadAction<Workflow>) => {
            state.workflow = action.payload;
            return state;
        },
        updateWorkflowSuccess: (state, action: PayloadAction<Workflow>) => {
            state.isStatusUpdated = true;
            return state;
        },
        updateWorkflowFailure: (state, action: PayloadAction<Workflow>) => {
            state.isStatusUpdated = false;
            return state;
        } 
    },
});

export const { setworkflowSlice , updateWorkflowSuccess, updateWorkflowFailure } = workflowISlice.actions;
export default workflowISlice.reducer;
