import {
    createBrowserRouter,
    createRoutesFromElements,
    defer,
    Route,
} from 'react-router-dom';
import { AuthLayout } from './components/AuthLayout';
import { ProtectedLayout } from './components/ProtectedLayout';
import BankXmlList from './features/bank-file/list/BankXmlList';
import BankXmlUpload from './features/bank-file/upload/BankXmlUpload';
import AddBank from './features/bank/create/AddBank';
import EditBank from './features/bank/edit/EditBank';
import CustomerFileList from './features/customer-file/list/CustomerFileList';
import CustomerFileUpload from './features/customer-file/upload/CustomerFileUpload';
import CreateCustomer from './features/customer/create/createCustomer';
import EditCustomer from './features/customer/edit/editCustomer';
import CustomerList from './features/customer/list/customerList';
import FileMapperContainer from './features/file-mapper/FileMapperContainer';
import AddNewPaymentType from './features/payment-types/create/AddNewPaymentType';
import EditPaymentType from './features/payment-types/edit/editPaymentType';
import PaymentType from './features/payment-types/list/PaymentType';
import ProfilePage from './features/profile/ProfilePage';
import LoginPage from './site/paybank/LoginPage';
import PayBrijSiteContainer from './site/paybank/paybank';
import BankList from './features/bank/list/BankList';
import UserList from './features/users/list/UserList';
import RoleList from './features/role-master/list/RoleList';
import CreateUser from './features/users/create/CreateUser';
import CreateRole from './features/role-master/create/CreateRole';
import FaqViewAll from './site/paybank/FaqViewAll';
import TrustedCustomersViewAll from './site/paybank/TrustedCustomersViewAll';
import EditUser from './features/users/edit/EditUser';
import WorkFlowsList from './features/workflows/list';
import CreateWorkFlows from './features/workflows/create';
import App from './features/template/App';
import EditWorkflow from './features/workflows/edit/EditWorkflow';
import ViewWorkFlow from './features/workflows/view';
import CreateWorkflow from './features/workflows/create/CreateWorkflow';
import BankXmlView from './features/bank-file/view/BankXmlView';
import TestUpload from './features/customer-file/upload-new/testUpload';
import EditProfile from './features/profile/EditProfile';
import ForgotPassword from './site/paybank/FogotPassword';

const getUserData = () =>
    new Promise((resolve) =>
        setTimeout(() => {
            const user = window.localStorage.getItem('user');
            resolve(user);
        }, 3000)
    );

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            element={<AuthLayout />}
            loader={() => defer({ userPromise: getUserData() })}
        >
            <Route>
                <Route path="/" element={<PayBrijSiteContainer />} />
                <Route path="/loginpage" element={<LoginPage />} />
                <Route path="/viewallfaq" element={<FaqViewAll />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route
                    path="/viewallcustomers"
                    element={<TrustedCustomersViewAll />}
                />
            </Route>
            <Route path="/cp" element={<ProtectedLayout />}>
                <Route path="bank" element={<BankList />}></Route>
                <Route
                    path="bank/createbank"
                    element={<AddBank></AddBank>}
                ></Route>
                <Route
                    path="paymenttype"
                    element={<PaymentType></PaymentType>}
                ></Route>
                <Route
                    path="banktemplate"
                    element={<BankXmlList></BankXmlList>}
                ></Route>
                <Route
                    path="banktemplate/viewbank/:id"
                    element={<BankXmlView></BankXmlView>}
                ></Route>
                <Route
                    path="bank/editbank/:id"
                    element={<EditBank></EditBank>}
                ></Route>
                <Route
                    path="banktemplate/banktemplateupload"
                    element={<BankXmlUpload></BankXmlUpload>}
                ></Route>
                <Route
                    path="paymenttype/createpaymenttype"
                    element={<AddNewPaymentType></AddNewPaymentType>}
                ></Route>
                <Route
                    path="paymenttype/editpaymenttype/:id"
                    element={<EditPaymentType></EditPaymentType>}
                ></Route>
                <Route
                    path="customertemplate"
                    element={<CustomerFileList></CustomerFileList>}
                ></Route>
                <Route
                    path="customertemplate/customertemplateupload"
                    element={<CustomerFileUpload></CustomerFileUpload>}
                    //element={<TestUpload />}
                ></Route>
                <Route
                    path="customer"
                    element={<CustomerList></CustomerList>}
                ></Route>
                <Route
                    path="customer/createcustomer"
                    element={<CreateCustomer></CreateCustomer>}
                ></Route>
                <Route
                    path="customer/editcustomer/:id"
                    element={<EditCustomer></EditCustomer>}
                ></Route>
                <Route
                    path="workflows"
                    element={<WorkFlowsList></WorkFlowsList>}
                ></Route>
                <Route
                    path="workflows/create"
                    //element={<CreateWorkflow></CreateWorkflow>}
                    element={<CreateWorkFlows></CreateWorkFlows>}
                ></Route>
                <Route path="template/" element={<App></App>}></Route>
                <Route path="dashboard" element={<ProfilePage />} />
                <Route path="dashboard/editprofile/:id" element={<EditProfile />} />
                <Route path="settings" element={<ProfilePage />} />
                <Route path="mapper" element={<FileMapperContainer />} />
                <Route path="users" element={<UserList />} />
                <Route path="users/createuser/:id" element={<CreateUser />} />
                <Route path="users/edituser/:id" element={<EditUser />} />
                <Route path="users/createuser" element={<CreateUser />} />
                <Route path="roles" element={<RoleList />} />
                <Route path="roles/createrole" element={<CreateRole />} />
                <Route path="paymenttype" element={<PaymentType />} />
                <Route path="customertemplate" element={<CustomerFileList />} />
                {/* <Route
                    path="customertemplate/customertemplateupload"
                    element={<CustomerFileUpload />}
                /> */}
                <Route path="banktemplate" element={<BankXmlList />} />
                <Route
                    path="workflows/edit"
                    element={<EditWorkflow></EditWorkflow>}
                ></Route>
                <Route
                    path="workflows/view"
                    element={<ViewWorkFlow></ViewWorkFlow>}
                ></Route>
                <Route
                    path="workflows/edit/:id"
                    element={<EditWorkflow></EditWorkflow>}
                ></Route>
            </Route>
        </Route>
    )
);
