import { all, call, put, takeLatest } from "redux-saga/effects";
import UploadCustomerFile from "../../model/UploadCustomerFileModal";
import { findAllCustomerFileRequest, findAllCustomerNamesWithIdRequest, uploadCustomerFileRequest } from "../requests/CustomerFile";
import { findAllCustomerFileSlice, findAllCustomerNamesSlice, uploadCustomerFileSlice } from "../slice/customer-file/UploadCustomerFile";
import { GET_ALL_CUSTOMERS, GET_CUSTOMERFILE_LIST, UPLOAD_CUSTOMER_FILE } from "../types/customer-file";
import CustomerFileList from "../../model/CustomerFileListModel";

export function* findAllCustomerNamesSaga(action){
    
    const response: Array<UploadCustomerFile> = yield call(findAllCustomerNamesWithIdRequest, action.data);
    yield put(findAllCustomerNamesSlice(response));
}
export function* uploadCustomerFileSaga(action){
    
    yield uploadCustomerFileRequest(action.upload);
    yield put(uploadCustomerFileSlice(action));
}
export function* findAllCustomerFileListSaga(action) {
    const response: Array<CustomerFileList> = yield call(findAllCustomerFileRequest, action.reqData);
    yield put(findAllCustomerFileSlice(response));
}
function* customerFileSaga(){
    yield all([takeLatest(GET_ALL_CUSTOMERS, findAllCustomerNamesSaga)]);
    yield all([takeLatest(UPLOAD_CUSTOMER_FILE, uploadCustomerFileSaga)]);
    yield all([takeLatest(GET_CUSTOMERFILE_LIST, findAllCustomerFileListSaga)]);
}

export default customerFileSaga;