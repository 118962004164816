import React from 'react';
import SortableTree from '@nosferatu500/react-sortable-tree';
import CdsButton from '../../components/CdsButton';
import { CustomerTemplateModel } from '../../model/CustomerTemplateModel';

type FieldMapperProps = {
    source: Array<CustomerTemplateModel>;
    mappedSource: Array<CustomerTemplateModel>;
    currentStepId: number;
    onStepperNext: (data: any, currentStepId: number) => void;
};
type FieldMapperStateProps = {
    todos: Array<CustomerTemplateModel>;
    completedTasks: Array<CustomerTemplateModel>;
    draggedTask: CustomerTemplateModel | null;
};
export default class FieldMapper extends React.Component<
    FieldMapperProps,
    FieldMapperStateProps
> {
    constructor(props) {
        super(props);
        this.state = {
            todos: [],
            completedTasks: [],
            draggedTask: null,
        };
    }

    componentDidMount() {
        this.setState({ todos: this.props.source });
    }

    onDrag = (event, todo) => {
        event.preventDefault();
        this.setState({
            draggedTask: todo,
        });
    };

    onDragOver = (event) => {
        event.preventDefault();
    };

    onDrop = (event) => {
        const { completedTasks, draggedTask, todos } = this.state;
        this.setState({
            completedTasks: [...completedTasks, draggedTask],
            todos: todos.filter((task) => task !== draggedTask),
            draggedTask: null,
        });
    };
    render() {
        const { todos, completedTasks } = this.state;
        return (
            <div className="container-fluid">
                <form>
                    <div className="card mt-3 border rounded-4">
                        <div className="card-header">
                            <h6 className="md-18">Select Fields</h6>
                        </div>
                        <div className="card-body">
                            <div className="row justify-content-evenly my-4">
                                <div className="col-6">
                                    <label
                                        htmlFor="bankContactNo"
                                        className="form-label"
                                    >
                                        <h6>Drag Field</h6>
                                    </label>
                                    <div>
                                        <ol className="list-group list-group-numbered">
                                            {todos.map((item, index) => (
                                                <li
                                                    className="list-group-item d-flex justify-content-between align-items-start"
                                                    key={index}
                                                    draggable
                                                    onDrag={(event) =>
                                                        this.onDrag(event, item)
                                                    }
                                                >
                                                    <div className="ms-2 me-auto">
                                                        {item.name}
                                                    </div>
                                                </li>
                                            ))}
                                        </ol>
                                    </div>
                                </div>

                                <div className="col-6">
                                    <label
                                        htmlFor="email"
                                        className="form-label"
                                    >
                                        <h6>Drop Field</h6>
                                    </label>
                                    <div>
                                        <ol
                                            onDrop={(event) =>
                                                this.onDrop(event)
                                            }
                                            onDragOver={(event) =>
                                                this.onDragOver(event)
                                            }
                                            className="done list-group list-group-numbered"
                                        >
                                            {completedTasks.map(
                                                (item, index) => (
                                                    <li
                                                        className="list-group-item d-flex justify-content-between align-items-start"
                                                        key={index}
                                                    >
                                                        <div className="ms-2 me-auto">
                                                            {item.name}
                                                        </div>
                                                        <span className="badge bg-danger rounded-pill">
                                                            Remove
                                                        </span>
                                                    </li>
                                                )
                                            )}
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="justify-content-end  row">
                                <div className="col-auto">
                                    <CdsButton
                                        title="Cancel"
                                        type="button"
                                        handleClick={(e) => console.log(e)}
                                    />
                                </div>
                                <div className="col-auto">
                                    <CdsButton
                                        title="Next"
                                        type="button"
                                        handleClick={(e) =>
                                            this.props.onStepperNext(
                                                completedTasks,
                                                this.props.currentStepId
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
