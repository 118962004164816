import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Customer from '../../../model/CustomerModel';
import { RootState } from '../../../sagas/slice/rootReducer';
import {
    FIND_CUSTOMER_BY_ID,
    UPDATE_CUSTOMER_BY_ID,
} from '../../../sagas/types/customer';
import { addCustomerSchema } from '../../schema/Customer';
import CdsTitle from '../../../components/CdsTitle';
import CdsButton from '../../../components/CdsButton';

type EditCustomerProps = {};

const EditCustomer: React.FC<EditCustomerProps> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const customer: Customer = useSelector(
        (state: RootState) => state.customer.customer
    );
    const initialValue: Customer = {
        id: 0,
        customername: '',
        address: '',
        contact: '',
        city: '',
        pincode: '',
        customermobileNo: '',
        customeremail: '',
        active: '',
    };
    const params = useParams();

    const myFormik = useFormik({
        initialValues: initialValue,
        validationSchema: addCustomerSchema,
        onSubmit: (values) => {
            console.log(values);
            //sendData(values, params.ID);
            dispatch({ type: UPDATE_CUSTOMER_BY_ID, customer: values });
            navigate('/cp/customer', { replace: true });
        },
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;

    useEffect(() => {
        dispatch({ type: FIND_CUSTOMER_BY_ID, id: params.id });
    }, [dispatch]);

    //
    useEffect(() => {
        if (customer) {
            onEditSetCustomerValues(customer);
        }
    }, [customer]);

    // useEffect(() => {
    //     if (status != null && status) {
    //         console.log('display success message');
    //         navigate('/cp/bank');
    //     } else {
    //         console.log('display failed message');
    //     }
    // }, [status]);

    const onEditSetCustomerValues = (customer: any) => {
        myFormik.setValues({
            id: customer.ID,
            customername: customer.CustomerName,
            address: customer.Address,
            contact: customer.CustomerContact,
            city: customer.City,
            pincode: customer.PinCode,
            customermobileNo: customer.CustomerContact_Mobile,
            customeremail: customer.CustomerContact_Email,
            active: customer.IsActive,
        });
    };

    return (
        <form onSubmit={handleSubmit} className="container-fluid">
            <CdsTitle
                linkto="/cp/customer"
                title="Customer"
                iconClass="bi bi-people"
                link="Back to List"
                headerDataTestId = 'Titlecustomer'
                linkDataTestId = 'backToList'
            ></CdsTitle>
            <div className="card mt-3 border rounded-4">
                <div className="card-header">
                    <h6 data-testid = "titleEditCustomer" className="md-18">Add Customer Details</h6>
                </div>
                <div className="card-body">
                    <div className="row my-4">
                        <div className="col">
                            <label
                                htmlFor="customerName"
                                className="form-label"
                            >
                                <h6 data-testid = "labelCustomerName">Customer Name</h6>
                            </label>
                            <input
                            data-testid = "inputCustomerName"
                                type="text"
                                className="form-control"
                                name="customername"
                                value={values.customername}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="customerName"
                                placeholder="Enter Customer Name"
                            ></input>
                            {errors.customername && touched.customername ? (
                                <p data-testid = "errorCustomerName" className="form-val form-error">
                                    {errors.customername}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="address" className="form-label">
                                <h6 data-testid = "labelAddress">Address</h6>
                            </label>
                            <input
                            data-testid = "inputAddress"
                                type="text"
                                className="form-control"
                                id="address"
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Address"
                            ></input>
                            {errors.address && touched.address ? (
                                <p data-testid = "errorAddress" className="form-val form-error">
                                    {errors.address}
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <div className="row my-4">
                        <div className="col">
                            <label htmlFor="contactNo" className="form-label">
                                <h6 >Contact Number</h6>
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="contactNo"
                                name="contact"
                                value={values.contact}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Contact Number"
                            ></input>
                            {errors.contact && touched.contact ? (
                                <p  className="form-val form-error">
                                    {errors.contact}
                                </p>
                            ) : null}
                        </div>

                        <div className="col">
                            <label htmlFor="city" className="form-label">
                                <h6 data-testid = "labelCity">City</h6>
                            </label>
                            <input
                            data-testid = "inputCity"
                                type="text"
                                className="form-control"
                                id="city"
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter City"
                            ></input>
                            {errors.city && touched.city ? (
                                <p data-testid = "errorCity" className="form-val form-error">
                                    {errors.city}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="pincode" className="form-label">
                                <h6 data-testid = "labelPinCode">Pin Code</h6>
                            </label>
                            <input
                            data-testid = "inputPincode"
                                type="number"
                                className="form-control"
                                id="pincode"
                                name="pincode"
                                value={values.pincode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter PIN Code"
                            ></input>
                            {errors.pincode && touched.pincode ? (
                                <p data-testid = "errorPincode  " className="form-val form-error">
                                    {errors.pincode}
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <div className="row justify-content-evenly my-4">
                        <div className="col-4">
                            <label
                                htmlFor="customerMobileNo"
                                className="form-label"
                            >
                                <h6 data-testid = "labelContactNumber">Customer Contact Number</h6>
                            </label>
                            <input
                            data-testid = "inputContactNumber"
                                type="number"
                                className="form-control"
                                id="customerMobiletNo"
                                name="customermobileNo"
                                value={values.customermobileNo}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Customer Contact Number"
                            ></input>
                            {errors.customermobileNo &&
                            touched.customermobileNo ? (
                                <p data-testid = "errorContactNumber" className="form-val form-error">
                                    {errors.customermobileNo}
                                </p>
                            ) : null}
                        </div>

                        <div className="col-4">
                            <label htmlFor="email" className="form-label">
                                <h6 data-testid = "labelCustomerEmail">Customer Email</h6>
                            </label>
                            <input
                            data-testid = "inputCustomerEmail"
                                type="text"
                                className="form-control"
                                id="email"
                                name="customeremail"
                                value={values.customeremail}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Customer Email ID"
                            ></input>
                            {errors.customeremail && touched.customeremail ? (
                                <p data-testid = "errorCustomerEmail" className="form-val form-error">
                                    {errors.customeremail}
                                </p>
                            ) : null}
                        </div>
                        <div className=" col-4 my-4 form-check form-switch form-check-reverse ">
                            {/* <div className="slider">
                                <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchCheckDefault"
                                >
                                    <h6>Status</h6>
                                </label>
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="active"
                                    value={values.active}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    role="switch"
                                    id="flexSwitchCheckDefault"
                                />
                            </div> */}
                        </div>
                    </div>
                    <div className="justify-content-end row">
                        <div data-testid = "btnEditCancel" className="col-auto">
                            <CdsButton
                                title="Cancel"
                                type="button"
                                handleClick={(e) => navigate('/cp/customer')}
                            />
                        </div>
                        <div data-testid = "btnEditSubmit" className="col-auto">
                            <CdsButton
                                title="Submit"
                                type="submit"
                                handleClick={(e) => navigate('')}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default EditCustomer;
