import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import ReduxError from "../../../model/ErrorModel";
import User from "../../../model/UserModel";
import store from "../../../Store";
import UserModel from "../../../model/UserModel";

// Define a type for the slice state
interface UserState {
  isFetched: boolean;
  users: Array<User>;
  error: ReduxError | null;
  user: {
    save: {
      isSaving: boolean | null;
      status: boolean | null;
      error: ReduxError | null;
    };
  };
}

// Define the initial state using that type
const initialState: UserState = {
  isFetched: false,
  users: new Array<User>(),
  error: null,
  user: {
    save: {
      isSaving: null,
      error: null,
      status: null,
    },
  },
};


export const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    fetchAllUser: (state) => {
      state.isFetched = false;
      state.users = [];
      state.error = null;
    },
    fetchAllUserSuccess: (state, action: PayloadAction<User[]>) => {
      
      state.isFetched = true;
      state.users = action.payload;
    },
    fetchAllUserFailed: (state, action: PayloadAction<ReduxError>) => {
      state.isFetched = true;
      state.users = [];
      state.error = action.payload;
    },
    resetSaveUser: (state) => {
      state.user.save.isSaving = null;
      state.user.save.status = null;
      state.user.save.error = null;
      return state;
    },
    //new

    findAllUsersSlice: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload;
      return state;
    },
    triggerSaveUser: (state) => {
      state.user.save.isSaving = true;
      state.user.save.status = null;
      state.user.save.error = null;
      return state;
    },
    addUserSuccess: (state) => {
      state.user.save.isSaving = false;
      state.user.save.error = null;
      state.user.save.status = true;
      return state;
    },
    addUserFailed: (state, action: PayloadAction<ReduxError>) => {
      state.user.save.isSaving = false;
      state.user.save.error = action.payload;
      state.user.save.status = false;
      return state;
    },
    editUserSlice: (state, action: PayloadAction<UserModel>) => {
      
      state.users = state.users.map((item) =>
          item.UserID == action.payload.UserID ? action.payload : item
      );
      return state;
  },
  }
});

type RootState = ReturnType<typeof store.getState>;
export const {
  fetchAllUser,
  fetchAllUserSuccess,
  fetchAllUserFailed,
  findAllUsersSlice,
  resetSaveUser,
  triggerSaveUser,
  addUserSuccess,
  addUserFailed,
  editUserSlice
 
} = usersSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAllUsers = (state: RootState) => state.users.users;

export default usersSlice.reducer;
