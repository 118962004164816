import React, { useEffect } from 'react'
import { useFormik } from 'formik';
import CdsTitle from '../../../components/CdsTitle'
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../../sagas/slice/rootReducer';
import { useDispatch, useSelector } from 'react-redux';
import UploadBankFile from '../../../model/UploadBankFileModel';
import { FIND_BANK_TEMPLATE } from '../../../sagas/types/template-mapper';

type BankXmlViewProps = {};
const BankXmlView : React.FC<BankXmlViewProps>= (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    //const banktemplate: UploadBankFile =useSelector((state: RootState) =>state.banktemplate.bankTemplate);
    //const { status } = useSelector(
        //(state: RootState) => state.banktemplate.bankTemplate.save
    //);
    const initialValue: UploadBankFile = {
        BankID: '',

        //BankName: '',
        //PaymentType: '',
        FileName: '',
        PaymentTypeID: '',
        File: '',
        files: '',
        CustomerID: '',
    };

    const myFormik = useFormik({
        initialValues: initialValue,
        
        onSubmit: (values) => {
            console.log(values);
            dispatch({ type: 
                FIND_BANK_TEMPLATE, banktemplate: values });
        },
    });
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    myFormik;

    useEffect(() => {
        dispatch({ type:FIND_BANK_TEMPLATE, id: params.BankID });
    }, [dispatch]);

    useEffect(() => {
        dispatch({ type: FIND_BANK_TEMPLATE, id: params.BankID });
    }, [dispatch]);

  return (
    <div>
      return (
        <div>
            <CdsTitle
                linkto="/cp/workflows"
                title="Bank Template View"
                iconClass="bi bi-bank"
                link="Back To List"
            ></CdsTitle>

            <div className="card mt-3 border rounded-4">
                <div className="card-header">
                    <h6 className="md-18">Bank Template View</h6>
                </div>
                <div className="card-body">
                    <div className="row my-4">
                        <div className="col-6">
                            <label
                                htmlFor="FileTransmission"
                                className="form-label"
                            >
                                <h6>Bank Name</h6>
                            </label>
                            <br />
                            <input
                            type="text"
                            //className="form-control"
                            id="bankname"
                            name="bankname"
                            //value={values.PaymentTypeID}
                            // onChange={handleChange}
                            // onBlur={handleBlur}
                            placeholder="abc"
                            disabled
                            >
                            </input>
                            </div>
                            <div className="col-6">
                            <label
                                htmlFor="FileTransmissionID"
                                className="form-label"
                            >
                                <h6>Payment Type</h6>
                            </label>
                            <br />
                            <input
                              type="text"
                              //className="form-control"
                              id="paymenttype"
                              name="paymenttype"
                              // value={values.city}
                              // onChange={handleChange}
                              // onBlur={handleBlur}
                              placeholder='abc'
                              disabled
                            >
                            </input>
                        </div>
                    </div>
                    <div className="row justify-content-start my-4">
                        <div className="col">
                            <label
                                htmlFor="NeedEncryption"
                                className="form-label"
                            >
                                <h6>XML File Name</h6>
                            </label>
                            <br />
                            <input
                              type="text"
                              //className="form-control"
                              id="xmlfilename"
                              name="xmlfilename"
                              value={values.FileName}
                              // onChange={handleChange}
                              // onBlur={handleBlur}
                            placeholder='abc'
                            disabled
                            >
                            </input>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    </div>
  )
}

export default BankXmlView
