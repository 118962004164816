import { Field, FieldArray, Form, Formik, getIn } from 'formik';
import * as Yup from 'yup';
import CdsButton from '../../components/CdsButton';
import CdsFormErrorMessage from '../../components/CdsFormErrorMessage';
import Bank from '../../model/BankModel';
import CustomizeTemplateMapperForm from './CustomizeTemplateMapperForm';

type CustomFieldMapperProps = {
    customerTemplateFields: Array<string>;
    customerBankTemplateFields?: Array<string>;
    currentStepId: number;
};

const CustomFieldMapper: React.FC<CustomFieldMapperProps> = (props) => {
    const initialValue: Array<Bank> = [
        {
            id: 0,
            bankname: '',
            address: '',
            contact: '',
            city: '',
            pincode: '',
            bankmobileNo: '',
            bankemail: '01',
            active: '',
        },
        {
            id: 1,
            bankname: '',
            address: '',
            contact: '',
            city: '',
            pincode: '',
            bankmobileNo: '',
            bankemail: '02',
            active: '',
        },
    ];
    const validationSchemaFieldArray = Yup.object().shape({
        templateMappingInfo: Yup.array().of(
            Yup.object().shape({
                bankemail: Yup.string()
                    .email('Invalid email')
                    .required('Required'),
            })
        ),
    });

    return (
        <div className="container-fluid">
            <div className="card mt-3 border rounded-4">
                <div className="card-header">
                    <h6 className="md-18">Template mapping customization</h6>
                </div>
                <div className="card-body">
                    <div className="row justify-content-evenly my-4">
                        <div className="col-4">
                            <label className="form-label">
                                <h6>Customer Template Fields</h6>
                            </label>
                            <div>
                                <ol className="done list-group list-group-numbered">
                                    {props.customerTemplateFields.map(
                                        (item, index) => (
                                            <li
                                                className="list-group-item d-flex justify-content-between align-items-start"
                                                key={index}
                                            >
                                                <div className="ms-2 me-auto">
                                                    {item}
                                                </div>
                                            </li>
                                        )
                                    )}
                                </ol>
                            </div>
                        </div>

                        <div className="col-8">
                            <label className="form-label">
                                <h6>Bank Template fields</h6>
                            </label>
                            <div className="accordion" id="accordionExample">
                                <Formik
                                    initialValues={{
                                        customerId: 1,
                                        paymentType: 1,
                                        bankId: 1,
                                        bankName: '',
                                        templateMappingInfo: initialValue,
                                    }}
                                    validationSchema={
                                        validationSchemaFieldArray
                                    }
                                    onSubmit={async (values) => {
                                        console.log('my values', values);
                                        return new Promise((res) =>
                                            setTimeout(res, 2500)
                                        );
                                    }}
                                >
                                    {({
                                        values,
                                        errors,
                                        isSubmitting,
                                        isValid,
                                    }) => (
                                        <Form autoComplete="off">
                                            <FieldArray name="customizeTemplateForm">
                                                {({ form }) => (
                                                    <div>
                                                        {values.templateMappingInfo.map(
                                                            (item, index) => (
                                                                <div
                                                                    key={index}
                                                                >
                                                                    {/* {renderCustomizeTemplateItem(
                                                                        form,
                                                                        index
                                                                    )} */}
                                                                    {
                                                                        <CustomizeTemplateMapperForm
                                                                            index={
                                                                                index
                                                                            }
                                                                            form={
                                                                                form
                                                                            }
                                                                        />
                                                                    }
                                                                </div>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            </FieldArray>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </div>
                    <div className="justify-content-end  row">
                        <div className="col-2">
                            <CdsButton
                                title="Previous"
                                type="button"
                                handleClick={(e) => console.log(e)}
                            />
                        </div>
                        <div className="col-2">
                            <CdsButton
                                title="Next"
                                type="button"
                                handleClick={(e) => console.log(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomFieldMapper;
