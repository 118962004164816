import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik';
import html2pdf from 'html2pdf.js';
import Workflow from '../../model/WorkflowModel';
import { addWorkflowSchema } from '../schema/Workflow';
import { CREATE_WORKFLOW } from '../../sagas/types/workflow';
import './ATP';

import './workflow.css';
import CdsTitle from '../../components/CdsTitle';
import CdsButton from '../../components/CdsButton';
import CdsModal from '../../components/CdsModal';
import { RootState } from '../../sagas/slice/rootReducer';

type CreateWorkFlowsProps = {};

const CreateWorkFlows: React.FC<CreateWorkFlowsProps> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const otherConstantRef = useRef(null);
    const params = useParams();
    const goToList = () => {
        navigate('/cp/workflows', { replace: true });
    };
    const UserData = JSON.parse(localStorage.getItem('user'));
    const { error, isSaving, status } = useSelector(
        (state: RootState) => state.workflows.workflow.save
    );

    useEffect(() => {
        if (status !== null) {
            if (status) {
                navigate('/cp/workflows', { replace: true });
            }
        }
    }, [status]);

    useEffect(() => {
        if (error !== null) {
            setIsDisplayWorkflowModal(true);
        }
    }, [error]);

    const initialValue: Workflow = {
        UserID: UserData[0].UserID,
        FileTransmission: null,
        FileTransmissionID: null,
        NeedEncryption: null,
        ForwardFlow:null,
        ReverseFlow:null,
        FilesInBanksFormat: null,
        OperatingSystemID: null,
        ErpComment: '',
        AdditionalRequirement: '',
        WorkflowStatus: 3,  
        IsTermsAndConditionSelected: null,
        IsHeaderPresent: null,
        FileType: null,
    };

    const myFormik = useFormik({
        initialValues: initialValue,
        validationSchema: addWorkflowSchema,
        onSubmit: (values: Workflow) => {
            debugger;
            const data = {
                UserID: UserData[0].UserID,
                FileTransmission: values.FileTransmission,
                FileTransmissionID: values.FileTransmissionID,
                NeedEncryption: values.NeedEncryption,
                ForwardFlow: values.ForwardFlow,
                ReverseFlow: values.ReverseFlow,
                FilesInBanksFormat: values.FilesInBanksFormat,
                OperatingSystemID: values.OperatingSystemID,
                ErpComment: values.ErpComment,
                AdditionalRequirement: values.AdditionalRequirement,
                WorkflowStatus: values.WorkflowStatus,
                IsHeaderPresent: values.IsHeaderPresent,
                FileType: values.FileType,
            };
            console.log(data);
            dispatch({ type: CREATE_WORKFLOW, workflow: data });
            // if (status && status != null) {
            //     navigate('/cp/workflows', { replace: true });
            // } else if (status == false) {
            //     alert('Failed');
            // }
        },
    });
    const {
        values,
        errors,
        isValid,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
    } = myFormik;

    const [show, setShow] = useState(null);

    const [isConditionedChecked] = useState(false);

    const options = {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric',
        hour12: false,
    };

    const handleTermsLinkClick = (event) => {
        if (values.IsTermsAndConditionSelected) {
            //event.preventDefault();
            setIsDisplayMapperModal(true);
        }
    };

    const downloadATP = (): any => {
        const element = document.getElementById('pdfContainer');
        console.log(element);
        const opt = {
            margin: 0.5,
            filename: 'document.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
        };
        html2pdf().from(element).set(opt).save();
    };

    const handleshow = (event) => {
        initialValue.FileTransmission = event.target.value;
        const getdropdownValue = event.target.value;
        setShow(getdropdownValue);
    };

    const [isDisplayMapperModal, setIsDisplayMapperModal] =
        useState<boolean>(false);

    const [isDisplayWorkflowModal, setIsDisplayWorkflowModal] =
        useState<boolean>(false);

    const handleSave = () => {
        const data = {
            UserID: UserData[0].UserID,
            FileTransmission: values.FileTransmission,
            FileTransmissionID: values.FileTransmissionID,
            NeedEncryption: values.NeedEncryption,
            FilesInBanksFormat: values.FilesInBanksFormat,
            OperatingSystemID: values.OperatingSystemID,
            ErpComment: values.ErpComment,
            AdditionalRequirement: values.AdditionalRequirement,
            WorkflowStatus: values.WorkflowStatus,
            IsHeaderPresent: values.IsHeaderPresent,
        };
        localStorage.setItem('workflowData', JSON.stringify(data));
        alert('Workflow data saved successfully!');
        console.log(handleSave);
    };

    return (
        <>
            <CdsTitle
                linkto="/cp/workflows"
                title="Workflow"
                iconClass="bi bi-filetype-xml"
                link="Back To List"
                headerDataTestId = 'workflowtitle'
                linkDataTestId = 'backtolistLink'
            ></CdsTitle>
            <form onSubmit={handleSubmit}>
                <div className="card mt-3 border rounded-4">
                    <div className="card-header">
                        <h6 data-testid='titleAddWorkFlow'className="md-18">Add Workflow</h6>
                    </div>
                    <div className="card-body">
                        <div className="row my-4">
                            <div className="col-6">
                                <label
                                    htmlFor="FileTransmission"
                                    className="form-label"
                                >
                                    <h6 data-testid='NeedFiletransmission'>Do you need File Transmission? *</h6>
                                </label>
                                <select
                                    data-testid='selectFileTransmission'
                                    className="form-select"
                                    onChange={handleChange}
                                    // onChange={(e) => handleshow(e)}
                                    name="FileTransmission"
                                    value={values.FileTransmission}
                                    onBlur={handleBlur}
                                    id="FileTransmission"
                                >
                                    <option value="">Select</option>
                                    <option value={1}>Yes</option>
                                    {/* <option value={0}>No</option> */}
                                </select>

                                {errors.FileTransmission &&
                                touched.FileTransmission ? (
                                    <p  data-testid='errorFileTransmission' className="form-val form-error">
                                        {errors.FileTransmission}
                                    </p>
                                ) : null}
                            </div>
                            {/* {show === '1' && ( */}
                            <div className="col-6">
                                <label
                                    htmlFor="FileTransmissionID"
                                    className="form-label"
                                >
                                    <h6  data-testid='TransmissionType'>Select Transmission Type *</h6>
                                </label>
                                <select
                                 data-testid='selectTransmissionType'
                                    className="form-select"
                                    name="FileTransmissionID"
                                    value={values.FileTransmissionID}
                                    onBlur={handleBlur}
                                    id="FileTransmissionID"
                                    onChange={handleChange}
                                >
                                    <option value="">Select</option>

                                    <option value={1}>H2H</option>
                                    {/* <option value={2}>API</option> */}
                                </select>
                                {errors.FileTransmissionID &&
                                touched.FileTransmissionID ? (
                                    <p  data-testid='errorTypeTransmission' className="form-val form-error">
                                        {errors.FileTransmissionID}
                                    </p>
                                ) : null}
                            </div>
                            {/* )} */}
                        </div>
                        <div className="row justify-content-start my-4">
                            <div className="col">
                                <label
                                    htmlFor="NeedEncryption"
                                    className="form-label"
                                >
                                    <h6  data-testid='needEncryption'>Need Encryption? *</h6>
                                </label>
                                <select
                                 data-testid='selectNeedEncryption'
                                    className="form-select"
                                    name="NeedEncryption"
                                    value={values.NeedEncryption}
                                    onBlur={handleBlur}
                                    id="NeedEncryption"
                                    onChange={handleChange}
                                >
                                    <option value="">Select</option>

                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                                {errors.NeedEncryption &&
                                touched.NeedEncryption ? (
                                    <p  data-testid='errorEncryption'className="form-val form-error">
                                        {errors.NeedEncryption}
                                    </p>
                                ) : null}
                            </div>

                            <div className="col">
                                <label
                                    htmlFor="OperatingSystemID"
                                    className="form-label"
                                >
                                    <h6  data-testid='OperatingSystem'>Server Operating System *</h6>
                                </label>
                                <select
                                 data-testid='selectOperatingSystem'
                                    className="form-select"
                                    name="OperatingSystemID"
                                    value={values.OperatingSystemID}
                                    onBlur={handleBlur}
                                    id="OperatingSystemID"
                                    onChange={handleChange}
                                >
                                    <option value="">Select</option>
                                    <option value={1}>Windows</option>
                                    <option value={2}>Linux</option>
                                </select>
                                {errors.OperatingSystemID &&
                                touched.OperatingSystemID ? (
                                    <p  data-testid='errorOperatingSystem' className="form-val form-error">
                                        {errors.OperatingSystemID}
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <div className="row justify-content-start my-4">
                            <div className="col">
                                <label
                                    htmlFor="ForwardFlow"
                                    className="form-label"
                                >
                                    <h6  data-testid='ForwardFlowRequired'>Forward Flow Required ? *</h6>
                                </label>
                                <select
                                 data-testid='selectForwardFlow'
                                    className="form-select"
                                    name="ForwardFlow"
                                    value={values.ForwardFlow}
                                    onBlur={handleBlur}
                                    id="ForwardFlow"
                                    onChange={handleChange}
                                >
                                    <option value="">Select</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                                {errors.ForwardFlow &&
                                touched.ForwardFlow ? (
                                    <p  data-testid='errorForwardRequired' className="form-val form-error">
                                        {errors.ForwardFlow}
                                    </p>
                                ) : null}
                            </div>

                            <div className="col">
                                <label
                                    htmlFor="ReverseFlow"
                                    className="form-label"
                                >
                                    <h6  data-testid='ReverseFlowRequired'>Reverse Flow Required ?*</h6>
                                </label>
                                <select
                                 data-testid='selectreverseFlow'
                                    className="form-select"
                                    name="ReverseFlow"
                                    value={values.ReverseFlow}
                                    onBlur={handleBlur}
                                    id="ReverseFlow"
                                    onChange={handleChange}
                                >
                                    <option value="">Select</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                                {errors.ReverseFlow &&
                                touched.ReverseFlow ? (
                                    <p  data-testid='errorReverseFlow' className="form-val form-error">
                                        {errors.ReverseFlow}
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-6">
                                <label
                                    htmlFor="FilesInBanksFormat"
                                    className="form-label"
                                >
                                    <h6  data-testid='BankFormartRequired'>
                                        Does your ERP provide files in Bank
                                        Required format? *
                                    </h6>
                                </label>
                                <select
                                 data-testid='slectBankFormat'
                                    className="form-select"
                                    name="FilesInBanksFormat"
                                    value={values.FilesInBanksFormat}
                                    onBlur={handleBlur}
                                    id="FilesInBanksFormat"
                                    onChange={handleChange}
                                >
                                    <option value="">Select</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                                {errors.FilesInBanksFormat &&
                                touched.FilesInBanksFormat ? (
                                    <p  data-testid='errorBankFormat' className="form-val form-error">
                                        {errors.FilesInBanksFormat}
                                    </p>
                                ) : null}
                            </div>
                            <div className="col-6">
                                <label
                                    htmlFor="IsHeaderPresent"
                                    className="form-label"
                                >
                                    <h6>
                                        Are the headers included in your file? *
                                    </h6>
                                </label>
                                <select
                                    className="form-select"
                                    name="IsHeaderPresent"
                                    value={values.IsHeaderPresent}
                                    onBlur={handleBlur}
                                    id="IsHeaderPresent"
                                    onChange={handleChange}
                                >
                                    <option value="">Select</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                                {errors.IsHeaderPresent &&
                                touched.IsHeaderPresent ? (
                                    <p className="form-val form-error">
                                        {errors.IsHeaderPresent}
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col-6">
                                <label
                                    htmlFor="FileType"
                                    className="form-label"
                                >
                                    <h6>
                                        File type? *
                                    </h6>
                                </label>
                                <select
                                    className="form-select"
                                    name="FileType"
                                    value={values.FileType}
                                    onBlur={handleBlur}
                                    id="FileType"
                                    onChange={handleChange}
                                >
                                    <option value="">Select</option>
                                    <option value={1}>XLSX</option>
                                    <option value={2}>CSV</option>
                                </select>
                                {errors.FileType &&
                                touched.FileType ? (
                                    <p className="form-val form-error">
                                        {errors.FileType}
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col">
                                <label
                                    htmlFor="Textarea"
                                    className="form-label"
                                >
                                    <h6  data-testid='SpecificComments'>ERP Specific Comments</h6>
                                </label>
                                <textarea
                                 data-testid='textSpecificComments'
                                    className="form-control"
                                    id="ErpComment"
                                    name="ErpComment"
                                    value={values.ErpComment}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="ERP Specific Comments"
                                ></textarea>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div>
                                <label
                                    htmlFor="Textarea1"
                                    className="form-label"
                                >
                                    <h6  data-testid='AdditionalRequirements'>Additional Requirements</h6>
                                </label>
                                <textarea
                                 data-testid='textAdditionalRequirement'
                                    className="form-control"
                                    id="AdditionalRequirement"
                                    name="AdditionalRequirement"
                                    value={values.AdditionalRequirement}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Additional Requirements"
                                ></textarea>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="mx-2 form-check form-check-inline">
                                <input
                                 data-testid='selectTerms'
                                    className="form-check-input"
                                    type="checkbox"
                                    id="IsTermsAndConditionSelected"
                                    name="IsTermsAndConditionSelected"
                                    onChange={(e: any) => {
                                        if (e.target.checked) {
                                            myFormik.setFieldValue(
                                                'IsTermsAndConditionSelected',
                                                true
                                            );
                                        } else {
                                            myFormik.setFieldValue(
                                                'IsTermsAndConditionSelected',
                                                null
                                            );
                                        }
                                    }}
                                />
                                <span  data-testid='titleIhaveReadAll' className="form-check-label">
                                    I have read all the&nbsp;
                                    {values.IsTermsAndConditionSelected ? (
                                        <Link
                                         data-testid='btnLinkT&C'
                                            to=""
                                            data-bs-target="#atpmodal"
                                            data-bs-toggle="modal"
                                            onClick={handleTermsLinkClick}
                                        >
                                            Terms and Conditions
                                        </Link>
                                    ) : (
                                        'Terms and Conditions'
                                    )}
                                    {errors.IsTermsAndConditionSelected &&
                                    touched.IsTermsAndConditionSelected ? (
                                        <p  data-testid='errorTerms&Condition' className="form-val form-error">
                                            {errors.IsTermsAndConditionSelected}
                                        </p>
                                    ) : null}
                                </span>
                            </div>
                        </div>
                        <div className="justify-content-end row">
                            <div  data-testid='btnCancel' className="col-auto">
                                <CdsButton
                                    title="Cancel"
                                    type="button"
                                    handleClick={(e) =>
                                        navigate('/cp/workflows')
                                    }
                                />
                            </div>

                            <div  data-testid='btnSubmit' className="col-auto">
                                <CdsButton
                                    title="Submit"
                                    type="submit"
                                    isDisabled={!isValid}
                                    //handleClick={(e) => handleSubmit()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <CdsModal
                headerDataTestId='titleATP'
                show={isDisplayMapperModal}
                handleClose={() => setIsDisplayMapperModal(false)}
                handleShow={() => setIsDisplayMapperModal(true)}
                modalTitle="ATP"
                size="lg"
            >
                <div  data-testid='btnDownloadAtp' id="atp">
                    <CdsButton
                        title="Download ATP"
                        type="button"
                        handleClick={(e) => downloadATP()}
                    ></CdsButton>
                    <AtpPdf data={values} />
                </div>
            </CdsModal>

            <CdsModal
            headerDataTestId='TitleWorkFlow'
                show={isDisplayWorkflowModal}
                handleClose={() => setIsDisplayWorkflowModal(false)}
                handleShow={() => setIsDisplayWorkflowModal(true)}
                modalTitle="Workflow"
                size="lg"
            >
                <p
                    style={{
                        fontWeight: 'bold',
                        marginTop: 20,
                        marginBottom: 20,
                    }}
                >
                    {error?.message}
                </p>
            </CdsModal>
        </>
    );
};
type AtpPdfProps = {
    data: Workflow;
};
const AtpPdf: React.FC<AtpPdfProps> = (props) => {
    console.log(props.data.AdditionalRequirement);
    return (
        <div className="container" id="pdfContainer">
            <div className="row">
                <p data-testid='titlePrint' style={{ fontWeight: 'bold' }}>
                    &lt;Print on Company Letter Head and sign by Authorised
                    signatory&gt;
                </p>
                <p data-testid='todaysDate' style={{ fontWeight: 'bold' }}>
                    Date : {`${new Date().toLocaleDateString('en-GB')}`}{' '}
                    {`${new Date().toTimeString()}`}
                    {/* {todayDate.toLocaleDateString('en-US', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                    })} */}
                </p>
                <div>
                    <span data-testid='toCopia' style={{ fontWeight: 'bold' }}>
                        To : Copia Consultancy Services Private Limited
                    </span>
                    <div data-testid='companyAddress'>
                        Address: 501 Signia Oceans, Sect 10 A Near D Mart,
                        Airoli Navi Mumbai, 400708, India
                    </div>
                </div>
                <p style={{ fontWeight: 'bold' }}>From : </p>
                <p style={{ fontWeight: 'bold' }}>
                    Regarding :{' '}
                    <span data-testid='Regards'>
                        Software Deployment Authorization Cum Enablement Request
                    </span>
                </p>
                <p data-testid='reagrdsPara'>
                    We hereby authorize Copia Consultancy to deploy and enable
                    software services as per the below statement of works in our
                    ERP integration with the bank/s and will be governed by the
                    terms and conditions listed therein. We have read and
                    understood the Terms and Conditions, and hereby agree to be
                    bound by such Terms and Conditions at all times.
                </p>
                <br />
                <p data-testid='titleSow' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                    Statement of Work (SoW)
                </p>

                <div className="col-md-11">
                    <p data-testid='optionTransmissionNeed'>
                        Transmission Needed -{' '}
                        {props.data.FileTransmission == 1 ? 'Yes' : 'No'}
                    </p>
                </div>

                <div className="col-md-11">
                    <p data-testid='optionTransmissiontype'>
                        Transmission Type -{' '}
                        {props.data.FileTransmissionID == 1 ? 'H2H' : 'API'}
                    </p>
                </div>

                <div className="col-md-11">
                    <p data-testid='optionOs'>
                        Server Operating System -{' '}
                        {props.data.OperatingSystemID == 1
                            ? 'Windows'
                            : 'Linux'}
                    </p>
                </div>

                <div className="col-md-11">
                    <p data-testid='optionEncryptionNeeded'>
                        Encryption Needed -{' '}
                        {props.data.NeedEncryption == 1 ? 'Yes' : 'No'}
                    </p>
                </div>

                <div className="col-md-11">
                    <p data-testid='optionBankFormat'>
                        Does ERP provide files in Bank Required format -{' '}
                        {props.data.FilesInBanksFormat == 1 ? 'Yes' : 'No'}
                    </p>
                </div>

                <div className="col-md-11">
                    <p>
                        Are the headers included in your file -{' '}
                        {props.data.IsHeaderPresent == 1 ? 'Yes' : 'No'}
                    </p>
                </div>

                <div className="col-md-11">
                    <p>
                        File type -{' '}
                        {props.data.FileType == 1 ? 'XLSX' : 'CSV'}
                    </p>
                </div>

                {/* <div className="col-md-11">
                    <p>Needed ACK files from Bank -</p>
                </div>

                <div className="col-md-11">
                    <p>Folder name to get ACK Files -</p>
                </div>
                <div className="col-md-11">
                    <p>Needed reports from bank -</p>
                </div>
                <div className="col-md-11">
                    <p>Reports -</p>
                </div>
                <div className="col-md-11">
                    <p>Format - </p>
                </div> */}

                {/* <p style={{ fontWeight: 'bold' }} >Payment Method</p> */}
                <div className="col-md-12">
                    <table data-testid='tablePaymentMethod' className="table table-striped">
                        <thead>
                            <tr>
                                <th>Payment Method</th>
                                {/* <th>Account Number</th> */}
                            </tr>
                        </thead>
                        {/* <tbody>
                            {PaymentMethodsList.map((method, index) => (
                                <tr key={index}>
                                    <td>{method}</td>
                                    <td>{AccountNumberList[index]}</td>
                                </tr>
                            ))}
                        </tbody> */}
                    </table>
                </div>

                <br />

                <p data-testid='paymentMenthodPara'>
                    The above authorization request is made for the stated
                    purpose only and it may not be used for any other purpose.
                    The request is made for the limited and specific purpose of
                    enabling software services for our ERP integration with the
                    bank/s.
                </p>
                <br />

                <p data-testid='titleTermsAndCondition' style={{ fontWeight: 'bold' }}>Terms and Conditions</p>
                <ol data-testid='paraTermsAndCondition'>
                    <li>
                        The authorization is valid until further notice and may
                        be revoked in writing by us at any time. We reserve the
                        right to suspend or terminate the authorization in the
                        event of any breach of the Terms and Conditions or any
                        other agreement between us and Copia Consultancy.
                    </li>
                    <li>
                        The authorization is subject to the payment of fees as
                        agreed between us and Copia Consultancy.
                    </li>
                    <li>
                        We hereby indemnify and hold harmless Copia Consultancy
                        from any and all claims, damages, losses, liabilities,
                        costs, and expenses arising out of or in connection with
                        the deployment and enablement of the software services
                        in our ERP integration with the bank/s.
                    </li>
                </ol>

                <p data-testid='authorisedSignatory' style={{ fontWeight: 'bold', textAlign: 'center' }}>
                    Authorised Signatory
                </p>
                <p data-testid='nameSign' >
                    <span style={{ fontWeight: 'bold' }}>Name :</span>{' '}
                    __________________________
                </p>
                <p  data-testid='titleSign'>
                    <span style={{ fontWeight: 'bold' }}>Title :</span>{' '}
                    __________________________
                </p>
                <p  data-testid='companySign'>
                    <span style={{ fontWeight: 'bold' }}>Company :</span>{' '}
                </p>
            </div>
        </div>
    );
};
export default CreateWorkFlows;
