import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import ReduxError from "../../../model/ErrorModel";
import User from "../../../model/UserModel";


interface UserState {
    isFetched: boolean;
    user: User;
    error: ReduxError | null;
}

// Define the initial state using that type
const initialState: UserState = {
    isFetched: false,
    user: null,
    error: null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserSlice: (state, action: PayloadAction<User>) => {
            state.user = action.payload;
            return state;
        },
    },
});
export const { setUserSlice } = userSlice.actions;
export default userSlice.reducer;
