import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import BankFileListModel from "../../../model/BankFileListModel";
import ReduxError from "../../../model/ErrorModel";
import UploadBankFile from "../../../model/UploadBankFileModel";
import store from "../../../Store";

interface getBankFileState {
    isFetched: boolean;
    bankFileList: Array<BankFileListModel>;
    error: ReduxError | null;
  }

  const initialState: getBankFileState = {
    isFetched: false,
    bankFileList: new Array<BankFileListModel>(),
    error: null,
  };

  export const getBankFileSlice = createSlice({
    name : "bankFile",
    initialState,
    reducers:{
          findAllBankFileSlice: (state, action: PayloadAction<BankFileListModel[]>) => {
            state.bankFileList = action.payload;
            return state;
        },
    }
  });
  type RootState = ReturnType<typeof store.getState>;
  export const {
    findAllBankFileSlice,
  } = getBankFileSlice.actions;
  
  // Other code such as selectors can use the imported `RootState` type
  //export const selectAllBankFile = (state: RootState) => state.bankFileList.bankFileList;
  
  export default getBankFileSlice.reducer;
  
