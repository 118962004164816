import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import Customer from "../../../model/CustomerModel";
import ReduxError from "../../../model/ErrorModel";
import store from "../../../Store";

// Define a type for the slice state
interface CustomerState {
  isFetched: boolean;
  customers: Array<Customer>;
  error: ReduxError | null;
}

// Define the initial state using that type
const initialState: CustomerState = {
  isFetched: false,
  customers: new Array<Customer>(),
  error: null,
};

export const customersSlice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    fetchAllCustomer: (state) => {
      state.isFetched = false;
      state.customers = [];
      state.error = null;
    },
    fetchAllCustomerSuccess: (state, action: PayloadAction<Customer[]>) => {
      state.isFetched = true;
        state.customers = action.payload;
    },
    fetchAllCustomerFailed: (state, action: PayloadAction<ReduxError>) => {
      state.isFetched = true;
        state.customers = [];
        state.error = action.payload;
    },
    //new

    findAllCustomersSlice: (state, action: PayloadAction<Customer[]>) => {
      state.customers = action.payload;
      return state;
    },
    addCustomerSlice:(state  , action: PayloadAction<Customer> ) => {
      
      state.customers.push(action.payload);
      return state;
    },
    editCustomerSlice: (state, action: PayloadAction<Customer>) => {
      state.customers = state.customers.map((item) =>
        item.id == action.payload.id ? action.payload : item
      );
      return state;
    },
    deleteCustomerSlice: (state, action: PayloadAction<Customer>) => {
      // soft delete
      // TBD -> active/inactive
      state.customers = state.customers.map((item) =>
        item.id == action.payload.id ? action.payload : item
      );
      return state;
    },
  }
});

type RootState = ReturnType<typeof store.getState>;
export const {
  fetchAllCustomer,
  fetchAllCustomerSuccess,
  fetchAllCustomerFailed,

  findAllCustomersSlice,
  addCustomerSlice,
  editCustomerSlice,
  deleteCustomerSlice
} = customersSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAllCustomers = (state: RootState) => state.customers.customers;

export default customersSlice.reducer;
