import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ReduxError from "../../../model/ErrorModel";
import PaymentTypes from "../../../model/PaymentTypesModel";
import store from "../../../Store";

interface PaymentTypeState {
    isFetched: boolean;
    paymentType: PaymentTypes;
    error: ReduxError | null;
  }
  
  // Define the initial state using that type
  const initialState: PaymentTypeState = {
    isFetched: false,
    paymentType: null,
    error: null,
  };

  export const paymentTypeSlice = createSlice({
    name: "paymentType",
    initialState,
    reducers: {
      setPaymentTypelice: (state, action: PayloadAction<PaymentTypes>) => {
        
        state.paymentType = action.payload;
        return state;
      },
    },
  });
  export const { setPaymentTypelice } = paymentTypeSlice.actions;
  export default paymentTypeSlice.reducer;