import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Role from '../../../model/RoleModel';
import { useFormik } from 'formik';
import { addRoleSchema } from '../../schema/Role';
import { CREATE_ROLE } from '../../../sagas/types/role';
import CdsTitle from '../../../components/CdsTitle';
import CdsButton from '../../../components/CdsButton';

type CreateRoleProps = {};

const CreateRole: React.FC<CreateRoleProps> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const goToList = () => {
        navigate('/cp/roles', { replace: true });
    };
    const initialValue: Role = {
        RoleID: '0',
        RoleName: '',
        Description: '',
        //IsActive: '1',
    };
    const myFormik = useFormik({
        initialValues: initialValue,
        validationSchema: addRoleSchema,
        onSubmit: (values: Role) => {
            
            console.log(values);
            dispatch({ type: CREATE_ROLE, role: values });
            navigate('/cp/roles', { replace: true });
        },
    });
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;
    return (
        <div>
            <form onSubmit={handleSubmit} className="container-fluid">
                <CdsTitle
                    linkto="/cp/roles"
                    title="Role Master"
                    iconClass="bi bi-people"
                    link="Back To List"
                    headerDataTestId = 'titlerolemaster'
                    linkDataTestId = 'btnbackToList'
                ></CdsTitle>
                <div className="card mt-3 border rounded-4">
                    <div className="card-header">
                        <h6  data-testid='titleAddRole' className="md-18">Add Role Details</h6>
                    </div>
                    <div className="card-body">
                        <div className="row my-4">
                            <div className="col">
                                <label
                                    htmlFor="RoleName"
                                    className="form-label"
                                >
                                    <h6 data-testid='labelRoleName'>Role Name</h6>
                                </label>
                                <input
                                data-testid='inputName'
                                    type="text"
                                    className="form-control"
                                    id="RoleName"
                                    name="RoleName"
                                    value={values.RoleName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Role Name"
                                ></input>
                                {errors.RoleName && touched.RoleName ? (
                                    <p data-testid='errorName' className="form-val form-error">
                                        {errors.RoleName}
                                    </p>
                                ) : null}
                            </div>

                            <div className="col">
                                <label
                                    htmlFor="Description"
                                    className="form-label"
                                >
                                    <h6 data-testid='labelDescription'>Description</h6>
                                </label>
                                <input
                                data-testid='inputDescription'
                                    type="text"
                                    className="form-control"
                                    id="Description"
                                    name="Description"
                                    value={values.Description}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Description"
                                ></input>
                                {errors.Description && touched.Description ? (
                                    <p data-testid='errorDescription' className="form-val form-error">
                                        {errors.Description}
                                    </p>
                                ) : null}
                            </div>
                            {/* <div className=" col-1  my-4 form-check form-switch form-check-reverse ">
                                <div className="slider">
                                    <label
                                        className="form-check-label"
                                        htmlFor="flexSwitchCheckChecked"
                                    >
                                        <h6>Status</h6>
                                    </label>
                                    <input
                                        checked
                                        className="form-check-input"
                                        type="checkbox"
                                        name="IsActive"
                                        //value={values.IsActive}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        role="switch"
                                        id="flexSwitchCheckDefault"
                                    />
                                </div>
                            </div> */}
                        </div>
                        <div className="justify-content-end  row">
                            <div data-testid='btnCancel' className="col-auto">
                                <CdsButton
                                    title="Cancel"
                                    type="button"
                                    handleClick={(e) => navigate('/cp/roles')}
                                />
                            </div>
                            <div data-testid='btnSubmit' className="col-auto">
                                <CdsButton
                                    title="Submit"
                                    type="submit"
                                    handleClick={(e) => handleSubmit()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default CreateRole;
