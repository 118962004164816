type CdsButtonProps = {
    children?: [];
    type: 'submit' | 'button';
    title: string;
    isDisabled?: boolean;
    customClass?: string;
    handleClick?: (e: any) => any;
};

const CdsButton: React.FC<CdsButtonProps> = (props) => {
    const render = () => {
        return (
            <button
                disabled={props?.isDisabled}
                type={props.type}
                className={`btn btn-default  ${
                    props?.customClass ? props?.customClass : ''
                }`}
                onClick={(e) =>
                    props.handleClick ? props.handleClick(e) : null
                }
            >
                {props.title}
            </button>
        );
    };
    return render();
};

export default CdsButton;
