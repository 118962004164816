import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Bank from '../../../model/BankModel';
import ReduxError from '../../../model/ErrorModel';

interface BankState {
    isFetched: boolean;
    bank: Bank;
    error: ReduxError | null;
}

// Define the initial state using that type
const initialState: BankState = {
    isFetched: false,
    bank: null,
    error: null,
};

export const bankSlice = createSlice({
    name: 'bank',
    initialState,
    reducers: {
        setBankSlice: (state, action: PayloadAction<Bank>) => {
            state.bank = action.payload;
            return state;
        },
    },
});
export const { setBankSlice } = bankSlice.actions;
export default bankSlice.reducer;
