import axios from "axios";
import API from "../../constants/Api";
import PaymentTypes from "../../model/PaymentTypesModel";
import { ApiResponse } from "../../model/ApiResponseModel";
import { useNavigate } from "react-router-dom";

export async function findAllPaymentTypesRequest(
    requestPayload: any
  ): Promise<ApiResponse> {
    const result: ApiResponse = {
      data: null,
      error: null,
  };
    const body = {
      PaymentType : requestPayload 
    };
    return await axios({
      method: API.TYPE.POST,
      url: API.PAYMENT_TYPES.GET_PAYMENT_TYPES_LIST,
      data:body
    }).then((response) => {
      // set response to data
      console.log(response.data.PaymentTypes);
      result.data = response.data.PaymentTypes;
     
      return result.data;
    });
  }

  export async function createPaymentTypeRequest(payload : PaymentTypes):Promise<boolean> {
    
    const body = {
        paymentType : payload.paymentType,
        description : payload.description
    }
    return await axios({
      method: API.TYPE.POST,
      url: API.PAYMENT_TYPES.CREATE_PAYMENT_TYPES,
      data: body,
    }).then((response) => {
      console.log(response.data);
      return response.data;
    });
  }

  export async function updatePaymentTypeRequest(payload: PaymentTypes): Promise<PaymentTypes> {
    
    const body = {
      ID: payload.id,
      PaymentType : payload.paymentType,
      Description : payload.description
    };
    return await axios({
      method: API.TYPE.POST,
      url: API.PAYMENT_TYPES.UPDATE_PAYMENT_TYPES,
      data: body,
    }).then((response) => {
      console.log(response.data);
      return response.data;
    });
  }

  export async function findPaymentTypesByIdRequest(
    selectedPaymentTypeId: number
  ): Promise<PaymentTypes> {
    
    return await axios({
      method: API.TYPE.POST,
      url: API.PAYMENT_TYPES.GET_PAYMENT_TYPES,
      data: { id: selectedPaymentTypeId },
    }).then((response) => {
      console.log(response.data);
      // status code , add if
      return response.data.PaymentTypes[0];
    });
  }
  export async function deletePaymentByIdRequest(id: number): Promise<boolean> {
    return await axios({
        method: API.TYPE.POST,
        url: API.PAYMENT_TYPES.DELETE_PAYMENT_TYPES,
        data: {ID:id},
    }).then((response) => {
        console.log(response.data);
        return response.data;
    });
  }