import { useState } from 'react';
type CdsInputProps = {
    id: string;
    name: string;
    type: string;
    value: string;
    isDisabled?: boolean;
    placeholder?: string;
    handleChange: (e: any) => void;
    handleBlur: (e: any) => void;
    inputDataTestId?: string;
};
const CdsInput: React.FC<CdsInputProps> = (props) => {
    const [value, setValue] = useState<string>(props.value || '');
    const render = () => {
        return (
            <input
            data-testid={props.inputDataTestId}
                disabled={props?.isDisabled}
                type={props.type}
                className="form-control"
                id={props.id}
                name={props.name}
                value={props.value}
                onChange={(e) => {
                    setValue(e.target.value);
                    props.handleChange(e.target.value);
                }}
                onBlur={(e) => {
                    setValue(value);
                    props.handleBlur(value);
                }}
                placeholder={props?.placeholder}
            ></input>
        );
    };
    return render();
};

export default CdsInput;
