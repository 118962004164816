import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import BankNames from "../../../model/BankNameModel";
import ReduxError from "../../../model/ErrorModel";
import PaymentTypeWithId from "../../../model/PaymentTypeWithIdModel";
import store from "../../../Store";

interface BankNameState {
    isFetched: boolean;
    bankNames: Array<BankNames>;
    error: ReduxError | null;
  }

  const initialState: BankNameState = {
    isFetched: false,
    bankNames: new Array<BankNames>(),
    error: null,
  };
  export const bankNamesSlice = createSlice({
    name : "banknames",
    initialState,
    reducers : {
        findAllBankNamesSlice: (state, action: PayloadAction<BankNames[]>) => {
            state.bankNames = action.payload;
            return state;
          },
          
  }
})
type RootState = ReturnType<typeof store.getState>;

export const {
    findAllBankNamesSlice
  } = bankNamesSlice.actions;
  
  // Other code such as selectors can use the imported `RootState` type
  export const selectAllBankNames = (state: RootState) => state.bankNames.bankNames;
  
  export default bankNamesSlice.reducer;

  
  