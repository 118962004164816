import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../sagas/slice/rootReducer';
import CdsButton from '../../../components/CdsButton';
import CdsInput from '../../../components/CdsInput';
import CdsTitle from '../../../components/CdsTitle';
import Workflow from '../../../model/WorkflowModel';
import {
    FIND_WORKFLOW_BY_ID,
    UPDATE_WORKFLOW_BY_ID,
} from '../../../sagas/types/workflow';
import { addWorkflowSchema } from '../../schema/Workflow';

//console.log(bankData);

type EditWorkflowProps = {};

const EditWorkflow: React.FC<EditWorkflowProps> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const workflow: Workflow = useSelector(
        (state: RootState) => state.workflow.workflow
    );
    const { status } = useSelector(
        (state: RootState) => state.workflows.workflow.save
    );

    const initialValue: Workflow = {
        UserID: 0,
        FileTransmission: 0,
        FileTransmissionID: 0,
        NeedEncryption: 0,
        ForwardFlow: 0,
        ReverseFlow: 0,
        FilesInBanksFormat: 0,
        OperatingSystemID: 0,
        ErpComment: '',
        AdditionalRequirement: '',
        WorkflowStatus: 0,
        IsHeaderPresent: 0,
        FileType: 0,
    };

    console.log(params);

    const myFormik = useFormik({
        initialValues: initialValue,
        validationSchema: addWorkflowSchema,
        onSubmit: (values) => {
            console.log(values);
            dispatch({ type: UPDATE_WORKFLOW_BY_ID, workflow: values });
        },
    });

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        isValid,
    } = myFormik;

    // pageload

    useEffect(() => {
        dispatch({ type: FIND_WORKFLOW_BY_ID, id: params.id });
    }, [dispatch]);

    // to load data for editing

    useEffect(() => {
        if (workflow) {
            onEditSetWorkflowValues(workflow);
        }
    }, [workflow]);

    useEffect(() => {
        if (status != null && status) {
            console.log('display success message');

            navigate('/cp/bank', { replace: true });
        } else {
            console.log('display failed message');
        }
    }, [status]);

    // typing TBD

    const onEditSetWorkflowValues = (workflow: any) => {
        myFormik.setValues({
            UserID: workflow.UserID,
            FileTransmission: workflow.FileTransmission,
            FileTransmissionID: workflow.FileTransmissionID,
            NeedEncryption: workflow.NeedEncryption,
            ForwardFlow: workflow.ForwardFlow,
            ReverseFlow: workflow.ReverseFlow,
            FilesInBanksFormat: workflow.FilesInBanksFormat,
            OperatingSystemID: workflow.OperatingSystemID,
            ErpComment: workflow.ErpComment,
            AdditionalRequirement: workflow.AdditionalRequirement,
            WorkflowStatus: workflow.WorkflowStatus,
            IsHeaderPresent: workflow.IsHeaderPresent,
            FileType: workflow.FileType,
        });
    };

    const [show, setShow] = useState(null);

    const handleshow = (event) => {
        initialValue.FileTransmission = event.target.value;
        const getdropdownValue = event.target.value;
        setShow(getdropdownValue);
    };

    const [isConditionedChecked, setIsConditionedChecked] = useState(false);
    const handleCheckboxChange = (event) => {
        // validation
        setIsConditionedChecked(event.target.checked);
    };

    const handleTermsLinkClick = (event) => {
        if (isConditionedChecked) {
            //event.preventDefault();
            setIsDisplayMapperModal(true);
        }
    };

    const [isDisplayMapperModal, setIsDisplayMapperModal] =
        useState<boolean>(false);

    return (
        <form onSubmit={handleSubmit} className="mb-4 container-fluid">
            <CdsTitle
                linkto="/cp/workflows"
                title="Workflow"
                iconClass="bi bi-filetype-xml"
                link="Back To List"
            ></CdsTitle>

            <div className="card mt-3 border rounded-4">
                <div className="card-header">
                    <h6 className="md-18">Workflow Details</h6>
                </div>
                {workflow ? (
                    <div className="card-body">
                        <div className="row my-4">
                            <div className="col-6">
                                <label
                                    htmlFor="FileTransmission"
                                    className="form-label"
                                >
                                    <h6>Do you need File Transmission? *</h6>
                                </label>
                                <select
                                    className="form-select"
                                    onChange={(e) => handleshow(e)}
                                    name="FileTransmission"
                                    value={values.FileTransmission}
                                    onBlur={handleBlur}
                                    id="FileTransmission"
                                >
                                    <option>Select</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                                {errors.FileTransmission &&
                                touched.FileTransmission ? (
                                    <p className="form-val form-error">
                                        {errors.FileTransmission}
                                    </p>
                                ) : null}
                            </div>
                            {show === '1' && (
                                <div className="col-6">
                                    <label
                                        htmlFor="FileTransmissionID"
                                        className="form-label"
                                    >
                                        <h6>Select Transmission Type *</h6>
                                    </label>
                                    <select
                                        className="form-select"
                                        name="FileTransmissionID"
                                        value={values.FileTransmissionID}
                                        onBlur={handleBlur}
                                        id="FileTransmissionID"
                                        onChange={handleChange}
                                    >
                                        <option>Select</option>
                                        <option value={1}>H2H</option>
                                        <option value={2}>API</option>
                                    </select>
                                    {errors.FileTransmissionID &&
                                    touched.FileTransmissionID ? (
                                        <p className="form-val form-error">
                                            {errors.FileTransmissionID}
                                        </p>
                                    ) : null}
                                </div>
                            )}
                        </div>
                        <div className="row justify-content-start my-4">
                            <div className="col">
                                <label
                                    htmlFor="NeedEncryption"
                                    className="form-label"
                                >
                                    <h6>Need Encryption? *</h6>
                                </label>
                                <select
                                    className="form-select"
                                    name="NeedEncryption"
                                    value={values.NeedEncryption}
                                    onBlur={handleBlur}
                                    id="NeedEncryption"
                                    onChange={handleChange}
                                >
                                    <option>Select</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                                {errors.NeedEncryption &&
                                touched.NeedEncryption ? (
                                    <p className="form-val form-error">
                                        {errors.NeedEncryption}
                                    </p>
                                ) : null}
                            </div>

                            <div className="col">
                                <label
                                    htmlFor="OperatingSystemID"
                                    className="form-label"
                                >
                                    <h6>Server Operating System *</h6>
                                </label>
                                <select
                                    className="form-select"
                                    name="OperatingSystemID"
                                    value={values.OperatingSystemID}
                                    onBlur={handleBlur}
                                    id="OperatingSystemID"
                                    onChange={handleChange}
                                >
                                    <option>Select</option>
                                    <option value={1}>Windows</option>
                                    <option value={2}>Linux</option>
                                </select>
                                {errors.OperatingSystemID &&
                                touched.OperatingSystemID ? (
                                    <p className="form-val form-error">
                                        {errors.OperatingSystemID}
                                    </p>
                                ) : null}
                            </div>
                        </div>
                        <div className="row my-4">
                            <div className="col">
                                <label
                                    htmlFor="FilesInBanksFormat"
                                    className="form-label"
                                >
                                    <h6>
                                        Does your ERP provide files in Bank
                                        Required format? *
                                    </h6>
                                </label>
                                <select
                                    className="form-select"
                                    name="FilesInBanksFormat"
                                    value={values.FilesInBanksFormat}
                                    onBlur={handleBlur}
                                    id="FilesInBanksFormat"
                                    onChange={handleChange}
                                >
                                    <option>Select</option>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                                {errors.FilesInBanksFormat &&
                                touched.FilesInBanksFormat ? (
                                    <p className="form-val form-error">
                                        {errors.FilesInBanksFormat}
                                    </p>
                                ) : null}
                            </div>
                            <div className="row my-4">
                                <label
                                    htmlFor="Textarea"
                                    className="form-label"
                                >
                                    <h6>ERP Specific Comments</h6>
                                </label>
                                <input
                                    className="form-control"
                                    id="ErpComment"
                                    name="ErpComment"
                                    value={values.ErpComment}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="ERP Specific Comments"
                                ></input>
                            </div>
                        </div>
                        <div className="row my-4">
                            <div>
                                <label
                                    htmlFor="Textarea1"
                                    className="form-label"
                                >
                                    <h6>Additional Requirements</h6>
                                </label>
                                <textarea
                                    className="form-control"
                                    id="AdditionalRequirement"
                                    name="AdditionalRequirement"
                                    value={values.AdditionalRequirement}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    placeholder="Additional Requirements"
                                ></textarea>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="mx-2 form-check form-check-inline">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="inlineCheckbox1"
                                    value="option1"
                                    onChange={handleCheckboxChange}
                                />
                                <span className="form-check-label">
                                    I have read all the&nbsp;
                                    {isConditionedChecked ? (
                                        <Link
                                            to=""
                                            data-bs-target="#atpmodal"
                                            data-bs-toggle="modal"
                                            onClick={handleTermsLinkClick}
                                        >
                                            Terms and Conditions
                                        </Link>
                                    ) : (
                                        'Terms and Conditions'
                                    )}
                                </span>
                            </div>
                        </div>
                        <div className="justify-content-end row">
                            <div className="col-auto">
                                <CdsButton
                                    title="Cancel"
                                    type="button"
                                    handleClick={(e) =>
                                        navigate('/cp/workflows')
                                    }
                                />
                            </div>
                            <div className="col-auto">
                                <CdsButton
                                    title="Save as Draft"
                                    type="submit"
                                    isDisabled={!isValid}
                                    handleClick={(e) => handleSubmit()}
                                />
                            </div>
                            <div className="col-auto">
                                <CdsButton
                                    title="Submit"
                                    type="submit"
                                    isDisabled={!isValid}
                                    handleClick={(e) => handleSubmit()}
                                />
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        </form>
    );
};

export default EditWorkflow;
