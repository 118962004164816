import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import ReduxError from "../../../model/ErrorModel";
import Role from "../../../model/RoleModel";
import store from "../../../Store";

// Define a type for the slice state
interface RoleState {
  isFetched: boolean;
  roles: Array<Role>;
  error: ReduxError | null;
  role: {
    save: {
      isSaving: boolean | null;
      status: boolean | null;
      error: ReduxError | null;
    };
  };
}

// Define the initial state using that type
const initialState: RoleState = {
  isFetched: false,
  roles: new Array<Role>(),
  error: null,
  role: {
    save: {
      isSaving: null,
      error: null,
      status: null,
    },
  },
};

export const rolesSlice = createSlice({
  name: "role",
  initialState,
  reducers: {
    fetchAllRole: (state) => {
      state.isFetched = false;
      state.roles = [];
      state.error = null;
    },
    fetchAllRoleSuccess: (state, action: PayloadAction<Role[]>) => {
      
      state.isFetched = true;
      state.roles = action.payload;
    },
    fetchAllRoleFailed: (state, action: PayloadAction<ReduxError>) => {
      state.isFetched = true;
      state.roles = [];
      state.error = action.payload;
    },
    //new

    findAllRolesSlice: (state, action: PayloadAction<Role[]>) => {
      state.roles = action.payload;
      return state;
    },

    triggerSaveRole: (state) => {
      state.role.save.isSaving = true;
      state.role.save.status = null;
      state.role.save.error = null;
      return state;
    },
    addRoleSuccess: (state) => {
      state.role.save.isSaving = false;
      state.role.save.error = null;
      state.role.save.status = true;
      return state;
    },
    addRoleFailed: (state, action: PayloadAction<ReduxError>) => {
      state.role.save.isSaving = false;
      state.role.save.error = action.payload;
      state.role.save.status = false;
      return state;
    },

  }
});

type RootState = ReturnType<typeof store.getState>;
export const {
  fetchAllRole,
  fetchAllRoleSuccess,
  fetchAllRoleFailed,
  findAllRolesSlice,
  triggerSaveRole,
  addRoleSuccess,
  addRoleFailed
 
} = rolesSlice.actions;

export const selectAllRoles = (state: RootState) => state.roles.roles;

export default rolesSlice.reducer;
