import { combineReducers } from 'redux';
import { banksSlice } from './bank/Banks';
import { bankSlice } from './bank/Bank';
import { customersSlice } from './customer/Customers';
import { customerSlice } from './customer/Customer';
import { paymentTypesSlice } from './payment-types/paymentTypes';
import { paymentTypeSlice } from './payment-types/paymentType';
import { bankNamesSlice } from './bank-file/BankNames';
import { paymentTypeWithIdSlice } from './bank-file/PaymentType';
import { BankFileSlice, uploadBankFileSlice } from './bank-file/UploadBankFile';
import { CustomerFileSlice } from './customer-file/UploadCustomerFile';
import { customerTemplateHeadersSlice } from './template-mapper/CustomerTemplateHeader';
import { customerTemplateConfiguredHeadersSlice } from './template-mapper/CustomerTemplateConfiguredHeader';
import { usersSlice } from './user/Users';
import { rolesSlice } from './role/Roles';
import { bankTemplateSlice } from './template-mapper/BankTemplate';
import { userSlice } from './user/User';
import BankFileList, { getBankFileSlice } from './bank-file/BankFileList';
import { workflowSlice } from './workflow/workflows';
import { workflowISlice } from './workflow/workflow';

const appReducer = combineReducers({
    banks: banksSlice.reducer, // multiple
    bank: bankSlice.reducer, // single
    banktemplate:bankTemplateSlice.reducer, 
    customers: customersSlice.reducer,
    customer: customerSlice.reducer,
    paymentTypes: paymentTypesSlice.reducer,
    paymentType: paymentTypeSlice.reducer,
    bankNames: bankNamesSlice.reducer,
    paymentTypeName: paymentTypeWithIdSlice.reducer,
    bankFile: BankFileSlice.reducer,
    bankFileList: getBankFileSlice.reducer,
    customerFile: CustomerFileSlice.reducer,
    customerTemplateAllHeaders: customerTemplateHeadersSlice.reducer,
    customerTemplateConfiguredHeaders:
    customerTemplateConfiguredHeadersSlice.reducer,
    users: usersSlice.reducer,
    roles: rolesSlice.reducer,
    bankTemplate: bankTemplateSlice.reducer,
    user: userSlice.reducer,
    workflows : workflowSlice.reducer,
    workflow : workflowISlice.reducer

    
});

const rootReducer = (state: any, action: any) => {
    if (action.type === 'HANDLE_LOGOUT') {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
