import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../paybank/paybank.css';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import axios from 'axios';
import API from '../../constants/Api';
import { data } from '../../model/FaqModel';
import { contactUsSchema } from '../../features/schema/Contactus';

type PaybankProps = {};
const PayBrijSiteContainer: React.FC<PaybankProps> = (props) => {
    // const [showSignInSignUp, setShowSignInSignUp] = useState(true);
    const [search, setSearch] = useState('');
    const navigate = useNavigate();
    const settings = {
        autoplay: true,
        className: 'center',
        centerMode: true,
        centerPadding: '5px',
        slidesToShow: 5,
        speed: 500,
        autoplaySpeed: 300,
        dots: true,
    };
    const initialValue = {
        username: '',
        email: '',
        contact: '',
        help: '',
        companyname: '',
    };
    const myFormik = useFormik({
        initialValues: initialValue,
        validationSchema: contactUsSchema,
        onSubmit: (values, actions) => {
            if (values.email) {
                userSetup();
                createBankRequest();
            }
            actions.resetForm();
        },
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;

    const goToCp = () => {
        navigate('/controlpanel', { replace: true });
    };
    const viewAllFaq = () => {
        navigate('/viewallfaq', { replace: true });
    };
    const viewAllCustomers = () => {
        navigate('/viewallcustomers', { replace: true });
    };
    const goToLoginPage = () => {
        // setShowSignInSignUp(true);  // Set showSignInSignUp to true when the login button is clicked
        navigate('loginpage', { replace: true });
    };

    const userSetup = async () => {
        const body = {
            UserName: values.username,
            CustomerName: values.companyname,
            UserMobile: values.contact,
            UserEmail: values.email,
            RoleID: '1',
            Help: values.help,
        };

        try {
            const response = await axios({
                method: API.TYPE.POST,
                url: API.CONTACTUS.CONTACT_US_USER,
                data: body,
            });

            const { isSuccess, Error } = response.data;

            if (isSuccess) {
                alert('User registered successfully');
            } else if (Error) {
                alert(Error.toString());
            }
        } catch (response_1) {
            if (response_1.response.status === 409) {
                alert('email already exists');
            } else {
                alert('Error occurred');
            }
        }
    };

    const createBankRequest = () => {
        const body = {
            Email: values.email,
            UserName: values.username,
            CompanyName: values.companyname,
            UserMobile: values.contact,
            Help: values.help,
        };
        return axios({
            method: API.TYPE.POST,
            url: API.CONTACTUS.CONTACT_US,
            data: body,
        }).then((response) => {
            console.log(response.data);
            const messageContainer =
                document.getElementById('messageContainer');
            if (response) {
                messageContainer.textContent =
                    'Email has been sent to your registered email ID.';
            }
            if (!response) {
                // Resource already exists
                messageContainer.textContent =
                    'Resource already exists with the same data.';
            }

            return response.data;
            //catch added
        });
    };
    return (
        //     <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
        //         <div className="container-fluid">
        //             <a className="navbar-brand" href="#">Your Brand</a>
        // <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        //     <span className="navbar-toggler-icon"></span>
        // </button>
        //             <div className="collapse navbar-collapse" id="navbarNav">
        //                 <ul className="navbar-nav">
        //                     <li className="nav-item">
        //                         <a className="nav-link" href="#">Home</a>
        //                     </li>
        //                     <li className="nav-item">
        //                         <a className="nav-link" href="#">About</a>
        //                     </li>
        //                     <li className="nav-item">
        //                         <a className="nav-link" href="#">Services</a>
        //                     </li>
        //                     <li className="nav-item">
        //                         <a className="nav-link" href="#">Contact</a>
        //                     </li>
        //                 </ul>
        //             </div>
        //         </div>
        //     </nav>)}
        <div className="bg-main">
            <nav
                id="#navbar-example2"
                className=" navbg border-bottom bg-white navbar bg-nav navbar-expand-lg bg-body-tertiary sticky-top"
            >
                <div className="container-fluid">
                    <meta
                        name="viewport"
                        content="width=divice-width"
                        initial-scale
                    ></meta>

                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNav"
                        aria-controls="navbarNav"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    {/* <div className="colllapse navbar-collapse" id="navbar-example2"> */}
                    <div className="row justify-content-md-center">
                        <div className="col-lg-auto">
                            {/* <div className="colllapse navbar-collapse" id="navbar-example2"> */}
                            <a href="#carouselExampleCaptions">
                                <img
                                    // width={100}
                                    className="img-fluid img-ht-2"
                                    src={require('../assets/logo.png')}
                                />
                            </a>
                        </div>
                    </div>

                    <div
                        className="pb-3 m-0  mx-5  collapse navbar-collapse text-rightt "
                        id="navbarNav"
                    >
                        {' '}
                        <div className="col-lg-auto">
                            <ul className="navbar-nav mt-4">
                                <li className="nav-item">
                                    <a
                                        className="nav-link active"
                                        aria-current="page"
                                        href="#carouselExampleCaptions"
                                    >
                                        <h4 className="">Home</h4>
                                    </a>
                                </li>
                                <li className="nav-item mx-2">
                                    <a
                                        className="nav-link active"
                                        href="#features"
                                    >
                                        <h4 className="">Features</h4>
                                    </a>
                                </li>
                                <li className="nav-item mx-2">
                                    <a
                                        className="nav-link active"
                                        href="#deployment"
                                    >
                                        <h4 className="">Deployment</h4>
                                    </a>
                                </li>
                                <li className="nav-item mx-2">
                                    <a
                                        href="#howitworks"
                                        className="nav-link active"
                                    >
                                        <h4 className="">How it works</h4>
                                    </a>
                                </li>
                                <li className="nav-item mx-2">
                                    <a
                                        href="#datasafety"
                                        className="nav-link active"
                                    >
                                        <h4 className="">Data Safety</h4>
                                    </a>
                                </li>
                                <li className="nav-item mx-2">
                                    <a href="#faq" className="nav-link active">
                                        <h4 className="">FAQ</h4>
                                    </a>
                                </li>
                                <li className="nav-item mx-2">
                                    <a
                                        href="#contactus"
                                        className="nav-link active"
                                    >
                                        <h4 className="">Contact Us</h4>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="pb-3 row justify-content-start mt-4">
                        <div className="col-sm-auto">
                            <button
                                data-testId="btnSignIn"
                                onClick={() => goToLoginPage()}
                                className="w-100 sign-up p-1 btn-min-width"
                            >
                                Sign In
                            </button>
                        </div>
                        <div className="col-sm-auto">
                            <a href="#contactus">
                                <button
                                data-testId="btnSignup"
                                    //onClick={() => goToCp()}
                                    className="w-100 btn-min-width sign-in"
                                >
                                    Sign Up
                                </button>
                            </a>
                        </div>
                        {/* <div className="col-sm-auto">
                       <button
                           onClick={() => goToLoginPage()}
                           className="w-50 btn-min-width login"
                       >
                           Login
                       </button>
                   </div>                         */}
                    </div>
                </div>
            </nav>

            {/* hero banner */}
            <div>
                <div
                    id="carouselExampleCaptions"
                    className="carousel carousel-fade"
                >
                    <div className="carousel-indicators">
                        <button
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide-to="0"
                            className="active"
                            aria-current="true"
                            aria-label="Slide 1"
                        ></button>
                        <button
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide-to="1"
                            aria-label="Slide 2"
                        ></button>
                        <button
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide-to="2"
                            aria-label="Slide 3"
                        ></button>
                        {/* <button
                            type="button"
                            data-bs-target="#carouselExampleCaptions"
                            data-bs-slide-to="3"
                            aria-label="Slide 4"
                        ></button> */}
                    </div>
                    <div className="carousel-inner">
                        <div className="hero-color carousel-item active">
                            <figure className="wp-caption">
                                <img
                                    src={require('../assets/Electronic_payment.jpg')}
                                    className="opacity d-block w-100 height"
                                    alt="..."
                                />
                            </figure>
                            <div className="hero-color carousel-caption d-none d-md-block">
                                <figcaption className="wp-caption-text">
                                    <h5 className="md-56 ">PayBrij</h5>
                                    <h3>
                                        Unique B2B platform for vendor payments.
                                    </h3>
                                </figcaption>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <figure className="wp-caption">
                                <img
                                    src={require('../assets/rpa.jpg')}
                                    className="opacity d-block w-100"
                                    alt="..."
                                />
                            </figure>
                            <div className="hero-color carousel-caption d-none d-md-block">
                                <figcaption className="wp-caption-text">
                                    {' '}
                                    <h5 className="md-56">
                                        Automate Vendor Payments
                                    </h5>
                                    <h3>Seamless realtime payment process.</h3>
                                </figcaption>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <figure className="wp-caption">
                                <img
                                    src={require('../assets/intelligent_procurement.jpg')}
                                    className="opacity d-block w-100"
                                    alt="..."
                                />
                            </figure>
                            <div className="hero-color carousel-caption d-none d-md-block">
                                <figcaption className="wp-caption-text">
                                    <h5 className="md-56">
                                        Productivity Using Automation
                                    </h5>
                                    <h3>
                                        Safe & secure vendor payment process.
                                    </h3>
                                </figcaption>
                            </div>
                        </div>
                        {/* <div className="carousel-item">
                            <img
                                src={require('../assets/performance.png')}
                                className="d-block w-100"
                                alt="..."
                            />
                            <div className="hero-color carousel-caption d-none d-md-block">
                                <h5 className="md-56">
                                    Enhance financial transactions
                                </h5>
                                <p style={{ fontWeight: 'bold' }}>
                                    Some representative placeholder content for
                                    the third slide.
                                </p>
                            </div>
                        </div> */}
                    </div>
                    <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="prev"
                    >
                        <span
                            className="carousel-control-prev-icon"
                            aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="next"
                    >
                        <span
                            className="carousel-control-next-icon"
                            aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            {/* main info */}
            <div
                data-bs-spy="scroll"
                data-bs-target="#navbar-example2"
                data-bs-root-margin="0px 0px -40%"
                data-bs-smooth-scroll="true"
                className="scrollspy-example bg-body-tertiary p-3 rounded-2"
            >
                {/* (content) if we add this there will be rockshadow in line no 334*/}
                <div className="container my-5 p-3 rounded-3 ">
                    {/* catch line */}
                    <div className="text-center">
                        <h2 className="md-40">
                            PayBrij is a Portal to Automate Vendor Payments...
                        </h2>
                        <h3 className="h3 text-grey">
                            Unique B2B platform to configure PayBrij Application
                            to do automated vendor payments
                        </h3>
                    </div>
                    {/* overview */}
                    <div className="mt-5">
                        <div className="row mt-1">
                            <div>
                                <h2 className="h2 text-center  md-40 fw-bold">
                                    Overview
                                </h2>
                                <hr className="line mx-auto "></hr>
                            </div>
                            <div className="row mt-2">
                                <div className="col">
                                    <h3 className="h3 text-left text-grey">
                                        PayBrij is a web portal which is used to
                                        configure and deploy the vendor payments
                                        tool named PayBrij. On the connect bank
                                        portal you need to sign up & create your
                                        login. Once you have signed up, you can
                                        initiate a workflow and specify your
                                        payment requirements. Post that, the AI
                                        based bot will create an application
                                        specific to your needs. You can download
                                        the application and do end to end
                                        testing. Once UAT is successful, you can
                                        download the production version and
                                        start using it for your vendor payments.
                                        At any stage during the process, our
                                        team would be available for your
                                        assistance and any support that may be
                                        needed. Working through the portal is
                                        extremely easy & you can be done in few
                                        simple steps.
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* paybrij features */}
                    <div id="features">
                        <div className="mt-5">
                            <div className="row">
                                <div className="">
                                    <h2 className="h2 text-center md-40 fw-bold">
                                        PayBrij Features
                                    </h2>
                                    <hr className="line mx-auto "></hr>
                                </div>
                            </div>
                            <div className="row g-5 mt-1 justify-content-around">
                                <div className="col-sm-5">
                                    <img
                                        className="img-fluid hover rounded-3"
                                        src={require('../assets/deployment.jpg')}
                                    />
                                </div>
                                <div className="col-sm-5 ">
                                    <h2 className="h1 mt-4 md-32">
                                        Universal to deploy
                                    </h2>
                                    <h4 className="h3 text-grey text-left">
                                        The tool can be installed on any windows
                                        or linux OS. Works agnostic to OS
                                        versions. You can install it on Any
                                        Cloud or Web Server or on a laptop.
                                    </h4>
                                </div>
                            </div>
                            <div className="row g-5 mt-4  justify-content-around">
                                <div className="col-sm-5 pt-3">
                                    <h2 className="h1 mt-4 md-32">
                                        All Payment Types
                                    </h2>
                                    <h4 className="text-grey h3 text-left">
                                        PayBrij can handle all payment types
                                        such as NEFT, RTGS, IMPS, Bulk Pay,
                                        Multi currency, International payments
                                        or any other.
                                    </h4>
                                </div>
                                <div className="col-sm-5">
                                    <img
                                        className="img-fluid hover rounded-3"
                                        src={require('../assets/paymenttype.jpg')}
                                    />
                                </div>
                            </div>
                            <div className="row g-5 mt-4  justify-content-around">
                                <div className="col-sm-5">
                                    <img
                                        className="img-fluid hover rounded-3"
                                        src={require('../assets/dataTransfer.png')}
                                    />
                                </div>
                                <div className="col-sm-5 pt-3">
                                    <h2 className="h1 mt-4 md-32">
                                        Data transfer types
                                    </h2>
                                    <h4 className="text-grey h3 text-left">
                                        It can do File Transfer or API based
                                        data transfer to the bank system. It can
                                        accept any file formats such as CSV, TXT
                                        or XLS.
                                    </h4>
                                </div>
                            </div>
                            <div className="row g-5 mt-4  justify-content-around">
                                <div className="col-sm-5 pt-3">
                                    <h2 className="h1 mt-4 md-32">
                                        Secured Data transfer
                                    </h2>
                                    <h4 className="text-grey h3 text-left">
                                        All data from your system to bank is
                                        encrypted with PGP keys and transfer in
                                        a secured transfer mode.
                                    </h4>
                                </div>
                                <div className="col-sm-5">
                                    <img
                                        className="img-fluid  hover rounded-3"
                                        src={require('../assets/security_Data_Transfer.jpg')}
                                    />
                                </div>
                            </div>
                            <div className="row g-5 mt-4  justify-content-around">
                                <div className="col-sm-5">
                                    <img
                                        className="img-fluid hover rounded-3"
                                        src={require('../assets/reverse.png')}
                                    />
                                </div>
                                <div className="col-sm-5 pt-3">
                                    <h2 className="h1 mt-4 md-32">
                                        Reverse Feed
                                    </h2>
                                    <h4 className="text-grey h3 text-left">
                                        Every payment transfer done with the
                                        bank can be tracked via reverse feed
                                        files. Day end report, Mt 940 , Status
                                        check and balance check are available as
                                        a standard functionality.
                                    </h4>
                                </div>
                            </div>
                            <div className="row g-5 mt-4  justify-content-around">
                                <div className="col-sm-5 pt-3">
                                    <h2 className="h1 mt-4 md-32">
                                        Configurable Setup
                                    </h2>
                                    <h4 className="text-grey h3 text-left">
                                        The application is configurable, meaning
                                        you can opt for only required
                                        functionality such as file transfer,
                                        encryption only or file mapping only and
                                        so on.
                                    </h4>
                                </div>
                                <div className="col-sm-5 ">
                                    <img
                                        className="img-fluid hover rounded-3"
                                        src={require('../assets/configurableSetup.jpg')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* steps */}
                    <div id="deployment" className="mt-5">
                        <div className="row">
                            <div className="">
                                <h2 className="h2 text-center md-40 fw-bold">
                                    PayBrij Deployment
                                </h2>
                                <hr className="line mx-auto "></hr>
                            </div>
                        </div>
                        <div className="row mt-5 justify-content-around">
                            <div className="col-sm-auto">
                                <div className="card card-style card-widthh">
                                    <img
                                        src={require('../assets/signup1.png')}
                                        className="img-fluid img-ht mx-auto card-img-top "
                                    />
                                    <div className="card-header">
                                        <h3 className="h3 text-center fw-midium">
                                            1. SignUp
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <h4 className="mt-1 fw-light text-center card-text">
                                            Follow these straightforward
                                            instructions to swiftly navigate
                                            through PayBrij - The Vendor Payment
                                            Application and complete the process
                                            with ease.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <div className="card card-style card-widthh">
                                    <img
                                        src={require('../assets/scope.png')}
                                        className="img-fluid img-ht mx-auto card-img-top "
                                    />
                                    <div className="card-header">
                                        <h3 className="h3 text-center fw-midium">
                                            2. Define Scope
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <h4 className="mt-1 fw-light text-center card-text">
                                            Initiate your workflow on the
                                            Connect Bank portal and establish
                                            all payment requirements.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <div className="card card-style card-widthh">
                                    <img
                                        src={require('../assets/paperwork.png')}
                                        className="img-fluid img-ht mx-auto card-img-top "
                                    />
                                    <div className="card-header">
                                        <h3 className="h3 text-center fw-midium">
                                            3. Complete Paperwork
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <h4 className="mt-1 fw-light text-center card-text">
                                            Submit the Acceptance Test Plan with
                                            all Terms and Conditions to proceed
                                            with the preparation of the
                                            instance.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-around mt-4">
                            <div className="col-sm-auto">
                                <div className="card card-style card-widthh">
                                    <img
                                        src={require('../assets/download.png')}
                                        className="img-fluid img-ht mx-auto card-img-top "
                                    />
                                    <div className="card-header">
                                        <h3 className="h3 text-center fw-midium">
                                            4. Download Application
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <h4 className="mt-1 fw-light text-center card-text">
                                            To align with the project scope, the
                                            system generates a dedicated
                                            application. Please download the app
                                            for testing purposes.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <div className="card card-style card-widthh">
                                    <img
                                        src={require('../assets/testing.png')}
                                        className="img-fluid img-ht mx-auto card-img-top "
                                    />
                                    <div className="card-header">
                                        <h3 className="h3 text-center fw-midium">
                                            5. Complete Testing
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <h4 className="mt-1 fw-light text-center card-text">
                                            Conduct comprehensive User
                                            Acceptance Testing to verify data
                                            accuracy, assess connectivity, and
                                            validate use cases, ensuring the
                                            absence of any errors.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-auto">
                                <div className="card card-style card-widthh">
                                    <img
                                        src={require('../assets/payment.png')}
                                        className="img-fluid img-ht mx-auto card-img-top "
                                    />
                                    <div className="card-header">
                                        <h3 className="h3 text-center fw-midium">
                                            6. Start Payment
                                        </h3>
                                    </div>
                                    <div className="card-body">
                                        <h4 className="mt-1 fw-light text-center card-text">
                                            Upon successful completion of User
                                            Acceptance Testing (UAT), the system
                                            will generate a Production instance
                                            for you. Kindly download it and
                                            commence vendor payments.
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* how it works */}
                    <div id="howitworks" className="mt-5">
                        <div className="row">
                            <div className="">
                                <h2 className="h2 text-center md-40 fw-bold">
                                    How it Works
                                </h2>
                                <hr className="line mx-auto "></hr>
                            </div>
                            <div className="row mt-2">
                                <div className="col mt-2">
                                    <h3 className="h3 text-left text-grey">
                                        PayBrij is a web portal designed for
                                        defining your vendor payment
                                        requirements and configuration. In
                                        modern business operations, the
                                        conventional method of creating files
                                        from the ERP system and uploading them
                                        to the bank portal is obsolete. Instead,
                                        PayBrij enables seamless vendor payment
                                        automation by establishing a connection
                                        with your bank through the ConnectBank
                                        portal. This streamlined process caters
                                        to payments for vendors, employees, and
                                        various other parties, ensuring
                                        efficiency and accuracy.
                                    </h3>
                                </div>
                            </div>
                            <div className=" mt-5">
                                <h2 className="h2 text-center md-40 fw-bold">
                                    How PayBrij Works
                                </h2>
                                <hr className="line mx-auto "></hr>
                            </div>
                            <div className="row mt-2">
                                <div className="col mt-2">
                                    <h3 className="h3 text-left text-grey">
                                        PayBrij serves as a dedicated
                                        application for managing vendor
                                        payments. By accessing the workflow on
                                        the portal, you can download the PayBrij
                                        application. Upon installation, PayBrij
                                        establishes connections with both your
                                        ERP system and bank, facilitating
                                        bidirectional data transfer. The
                                        following steps elaborate on how PayBrij
                                        operates.
                                    </h3>
                                </div>
                            </div>

                            <div className="d-flex justify-content-center">
                                <div className="row mt-5  ">
                                    <div className="col-sm-auto cardd">
                                        <div className="card card-wo-border card-width ">
                                            <img
                                                src={require('../assets/select.png')}
                                                className="img-fluid img-ht-2 mx-auto card-img-top "
                                            />
                                            <div className="m-2">
                                                <h1 className="h3 text-center fw-midium">
                                                    Pick Up File
                                                </h1>
                                            </div>
                                            <div className="card-body cardb mb-2">
                                                <h3
                                                    className="mt-1  text-center card-text"
                                                    style={{ color: '#636360' }}
                                                >
                                                    Once installed, PayBrij
                                                    picks up files from
                                                    designated folder at 15 min
                                                    frequency .It can read any
                                                    type of file from any
                                                    location. It can also
                                                    convert the file in required
                                                    format using mapper
                                                    functionality.
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 col-sm-auto">
                                        <div className="mt-5 text-center arrow">
                                            <img
                                                className="arrow img-fluid mx-auto"
                                                width={50}
                                                src={require('../assets/arrow.png')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-auto cardd">
                                        <div className="card card-wo-border card-width">
                                            <img
                                                src={require('../assets/encrypt.png')}
                                                className="img-fluid img-ht-2 mx-auto card-img-top "
                                            />
                                            <div className="m-2">
                                                <h1 className="h3 text-center fw-midium">
                                                    Encrypt
                                                </h1>
                                            </div>
                                            <div className="card-body cardb mb-3">
                                                <h3
                                                    className="mt-1  text-center card-text"
                                                    style={{ color: '#636360' }}
                                                >
                                                    Once the file is picked up,
                                                    PayBrij uses PGP alogrithm
                                                    and encrypts the file. It
                                                    can be decrypted only with
                                                    other key pair which is
                                                    stored in bank's system. So
                                                    file & its data is
                                                    completely secured during
                                                    the transfer.
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mt-4 col-sm-auto">
                                        <div className="my-5 text-center">
                                            <img
                                                className=" arrow img-fluid mx-auto"
                                                width={50}
                                                src={require('../assets/arrow.png')}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-sm-auto cardd">
                                        <div className="card card-wo-border card-width">
                                            <img
                                                src={require('../assets/transfer.png')}
                                                className="img-fluid img-ht-2 mx-auto card-img-top "
                                            />
                                            <div className="m-2">
                                                <h1 className="h3 text-center fw-midium">
                                                    Transfer File
                                                </h1>
                                            </div>
                                            <div className="card-body cardb mb-2">
                                                <h3
                                                    className="mt-1  text-center card-text "
                                                    style={{ color: '#636360' }}
                                                >
                                                    PayBrij uses another
                                                    encryption key pair to do
                                                    secured File Transfer from
                                                    your ERP to Bank's IT
                                                    system.
                                                </h3>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* data safety */}
                    <div id="datasafety" className="mt-5">
                        <div className="row">
                            <div className="">
                                <h2 className="h2 text-center md-40 fw-bold">
                                    Data Safety
                                </h2>
                                <hr className="line mx-auto "></hr>
                            </div>
                            <div className="row mt-2">
                                <div className="col mt-2">
                                    <h3 className="h3 text-left text-grey">
                                        Rest assured, at PayBrij, we prioritize
                                        your data safety. Your payment files
                                        undergo asynchronous encryption before
                                        being securely transferred to the bank
                                        via API and SFTP, ensuring the utmost
                                        protection of your transactional data.
                                    </h3>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* trusted customers */}
                    <div className="mt-5">
                        <div className="row">
                            <div className="">
                                <h2 className="h2 text-center md-40 fw-bold">
                                    Customers Connected to Us
                                </h2>
                                <hr className="line mx-auto "></hr>
                            </div>
                        </div>
                        <div className="container">
                            <div className="row justify-content-end">
                                <div className="col-sm-auto p-0">
                                    <div className="my-4 ">
                                        <button
                                            onClick={() => viewAllCustomers()}
                                            className="btn btn-sm rounded-4 left"
                                        >
                                            View All
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-2">
                            <div className="col">
                                <h3 className="h3 text-center text-grey"></h3>
                            </div>
                        </div>
                        <div className="row ">
                            <div className="mt-1">
                                <div className="row mt-3 justify-content-center">
                                    <div className="col-sm-auto rbor">
                                        <div className="card slider-card-width">
                                            <img
                                                className="img-fluid my-auto mx-auto  my-auto"
                                                src="https://tatainternational.com/wp-content/themes/til/images/TIL-60-years-logo.png"
                                            ></img>
                                        </div>
                                    </div>
                                    <div className="col-sm-auto rbor">
                                        <div className="card slider-card-width">
                                            <img
                                                className="img-fluid my-auto mx-auto  my-auto"
                                                src="https://tse1.mm.bing.net/th?id=OIP.GVi_55YGY8RAx2ElX61ioAHaHa&pid=Api&P=0"
                                            ></img>
                                        </div>
                                    </div>
                                    <div className="col-sm-auto rbor">
                                        <div className="card slider-card-width">
                                            <img
                                                className="img-fluid my-auto mx-auto  my-auto"
                                                src="https://upload.wikimedia.org/wikipedia/en/thumb/8/87/USV_Limited_Logo.png/300px-USV_Limited_Logo.png"
                                            ></img>
                                        </div>
                                    </div>
                                    <div className="col-sm-auto rbor">
                                        <div className="card slider-card-width">
                                            <img
                                                className="img-fluid my-auto mx-auto  my-auto"
                                                src="http://img2.yun300.cn/img/as001.png?tenantId=116886&viewType=1&k=1625650370000"
                                            ></img>
                                        </div>
                                    </div>
                                    <div className="col-sm-auto rbor">
                                        <div className="card slider-card-width">
                                            <img
                                                className="img-fluid my-auto mx-auto  my-auto"
                                                src=" https://www.kfintech.com/new-assets/images/logo/kfintech-white-tagline.svg"
                                            ></img>
                                        </div>
                                    </div>
                                    <div className="col-sm-auto rbor">
                                        <div className="card slider-card-width">
                                            <img
                                                className="img-fluid my-auto mx-auto  my-auto"
                                                src="https://www.newlywedsfoods.in/wp-content/uploads/2017/10/NWF-Logo-small.png"
                                            ></img>
                                        </div>
                                    </div>

                                    {/* <div className="col-sm-auto">
                                        <div className="my-5 ">
                                            <button onClick={()=>viewAllCustomers()} className='btn btn-sm rounded-4 left'>
                                                View All
                                        </button>
                                           
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                        {/* //////////////////// */}
                        <div className="row ">
                            <div className="mt-1">
                                <div className="row mt-3 justify-content-center">
                                    <div className="col-sm-auto rbor">
                                        <div className="card slider-card-width">
                                            <img
                                                className="img-fluid my-auto mx-auto  my-auto"
                                                src="https://5.imimg.com/data5/GK/YY/BH/SELLER-21308516/gfhgfh-120x120.png"
                                            ></img>
                                        </div>
                                    </div>
                                    <div className="col-sm-auto rbor">
                                        <div className="card slider-card-width">
                                            <img
                                                className="img-fluid my-auto mx-auto  my-auto"
                                                src="https://glandpharma.com/images/header_logo.webp"
                                            ></img>
                                        </div>
                                    </div>
                                    <div className="col-sm-auto rbor">
                                        <div className="card slider-card-width">
                                            <img
                                                className="img-fluid my-auto mx-auto  my-auto"
                                                src="https://tse1.mm.bing.net/th?id=OIP.7JbLhAltsqQJE2rXpDwHzAAAAA&pid=Api&P=0"
                                            ></img>
                                        </div>
                                    </div>
                                    <div className="col-sm-auto rbor">
                                        <div className="card slider-card-width">
                                            <img
                                                className="img-fluid my-auto mx-auto  my-auto"
                                                src="https://d1mgel6vch4ba4.cloudfront.net/images/logo_opt.png"
                                            ></img>
                                        </div>
                                    </div>
                                    <div className="col-sm-auto rbor">
                                        <div className="card slider-card-width">
                                            <img
                                                className="img-fluid my-auto mx-auto  my-auto"
                                                src="https://www.hap.in/images/hap-logo.png"
                                            ></img>
                                        </div>
                                    </div>
                                    <div className="col-sm-auto rbor">
                                        <div className="card slider-card-width">
                                            <img
                                                className="img-fluid my-auto mx-auto  my-auto"
                                                src="https://www.indorama.com/frontend/img/home-logo.png"
                                            ></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* FAQ's */}
                    <div id="faq" className="mt-5">
                        <div className="row">
                            <div className="col ">
                                <div className="my-1 ">
                                    <h2 className="h2  flo md-40 fw-bold">
                                        FAQ's
                                    </h2>
                                    <hr className="line mx-auto "></hr>
                                </div>
                            </div>
                            <div className="col">
                                <div className="container">
                                    <div className="row justify-content-end">
                                        <div className="col-sm-auto p-0">
                                            <div className="my-1 ">
                                                <button
                                                    onClick={() => viewAllFaq()}
                                                    className="btn btn-sm rounded-4 left"
                                                >
                                                    View All
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row mt-1">
                            <div className="col mt-1">
                                <h3 className="h3 text-center text-grey"></h3>
                            </div>
                        </div>

                        <div className="row">
                            <div className="height container mt-1">
                                <div
                                    className="accordion accordion-flush p-2 "
                                    id="accordionFlushExample"
                                >
                                    {data
                                        .filter((item) => {
                                            return search.toLowerCase() === ''
                                                ? item
                                                : item.question
                                                      .toLowerCase()
                                                      .includes(search);
                                        })
                                        .map((item) => (
                                            <div
                                                key={item.key}
                                                className="accordion-item  mb-2"
                                            >
                                                <h2 className="accordion-header">
                                                    <button
                                                        className="accordion-button collapsed hover"
                                                        type="button"
                                                        data-bs-toggle="collapse"
                                                        data-bs-target={`#${item.key}`}
                                                        aria-expanded="false"
                                                        aria-controls="flush-collapseOne"
                                                    >
                                                        {item.question}
                                                    </button>
                                                </h2>
                                                <div
                                                    id={item.key}
                                                    className="accordion-collapse collapse"
                                                    data-bs-parent="#accordionFlushExample"
                                                >
                                                    <div className="accordion-body">
                                                        {item.answer}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <button onClick={() => viewAllFaq()}>View All</button> */}
                    {/* Contact Us */}
                </div>
            </div>
            {/* Footer */}
            <div className="container-fluid">
                <div className="row gy-0 p-0">
                    <div id="contactus" className="cont mt-0 p-0">
                        <div className=" rounded-3 ">
                            <div className="container p-5">
                                <div
                                    id="contactus"
                                    className="bg-contact rounded-2 mt-0 p-5"
                                >
                                    <div className="bg-white rounded-3 ">
                                        <div className="row p-5">
                                            <form
                                                onSubmit={handleSubmit}
                                                className="col"
                                            >
                                                <div className="row">
                                                    <div className="col form-group">
                                                        <div className="col">
                                                            <h2
                                                                data-testid="titleContact"
                                                                className="semi-bold md-40"
                                                            >
                                                                Contact us
                                                            </h2>
                                                            <h5 data-testid="titleFillForm" className="h4 text-grey">
                                                                Fill out the
                                                                form and one of
                                                                our experts will
                                                                contact you.
                                                            </h5>
                                                            <br />
                                                        </div>
                                                        <label
                                                        data-testid="labelhelp"
                                                            className="h4 text-grey"
                                                            htmlFor="help"
                                                        >
                                                            How can we help
                                                            you:*
                                                        </label>

                                                        <select
                                                        data-testid="selectHelp"
                                                            className="form-control"
                                                            name="help"
                                                            id="help"
                                                            autoComplete="off"
                                                            value={values.help}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                        >
                                                            <option>
                                                                Select
                                                            </option>
                                                            <option
                                                                value={
                                                                    'General Enquiry'
                                                                }
                                                            >
                                                                General Enquiry
                                                            </option>
                                                            <option
                                                                value={
                                                                    'Registration'
                                                                }
                                                            >
                                                                Registration
                                                            </option>
                                                        </select>
                                                        {errors.help &&
                                                        touched.help ? (
                                                            <p data-testid="errorHelp" className="form-val form-error">
                                                                {errors.help}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>

                                                <div className="mt-4 row">
                                                    <div className="col form-group">
                                                        <label
                                                        data-testid="labelName"
                                                            className="h4 text-grey"
                                                            htmlFor="username"
                                                        >
                                                            Name:*
                                                        </label>
                                                        <input
                                                        data-testid="inputName"
                                                            type="username"
                                                            className="form-control"
                                                            name="username"
                                                            id="username"
                                                            autoComplete="off"
                                                            placeholder="Name"
                                                            value={
                                                                values.username
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.username &&
                                                        touched.username ? (
                                                            <p  data-testid="errorName"className="form-val form-error">
                                                                {
                                                                    errors.username
                                                                }
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                    <div className="col form-group">
                                                        <label
                                                        data-testid="labelCompanyName"
                                                            className="h4 text-grey"
                                                            htmlFor="companyname"
                                                        >
                                                            Company Name:*
                                                        </label>
                                                        <input
                                                        data-testid="inputCompanyName"
                                                            type="companyname"
                                                            className="form-control"
                                                            name="companyname"
                                                            id="companyname"
                                                            autoComplete="off"
                                                            placeholder="Company name"
                                                            value={
                                                                values.companyname
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.companyname &&
                                                        touched.companyname ? (
                                                            <p data-testid="errorCompanyName"  className="form-val form-error">
                                                                {
                                                                    errors.companyname
                                                                }
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="mt-4 row">
                                                    <div className="col form-group">
                                                        <label
                                                        data-testid="labelEmail"
                                                            className="h4 text-grey"
                                                            htmlFor="email"
                                                        >
                                                            Email Address:*
                                                        </label>
                                                        <input
                                                        data-testid="inputEmail"
                                                            type="emailaddress"
                                                            className="form-control"
                                                            name="email"
                                                            id="email"
                                                            autoComplete="off"
                                                            placeholder="Email"
                                                            value={values.email}
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.email &&
                                                        touched.email ? (
                                                            <p data-testid="errorEmail" className="form-val form-error">
                                                                {errors.email}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                    <div className="col form-group">
                                                        <label
                                                        data-testid="labelMobile"
                                                            className="h4 text-grey"
                                                            htmlFor="contact"
                                                        >
                                                            Mobile Number:*
                                                        </label>
                                                        <input
                                                        data-testid="inputMobile"
                                                            type="tel"
                                                            className="form-control"
                                                            name="contact"
                                                            id="contact"
                                                            autoComplete="off"
                                                            placeholder="Mobile number"
                                                            value={
                                                                values.contact
                                                            }
                                                            onChange={
                                                                handleChange
                                                            }
                                                            onBlur={handleBlur}
                                                        />
                                                        {errors.contact &&
                                                        touched.contact ? (
                                                            <p data-testid="errorMobile" className="form-val form-error">
                                                                {errors.contact}
                                                            </p>
                                                        ) : null}
                                                    </div>
                                                </div>
                                                <div className="row mt-4">
                                                    <div className="col"></div>
                                                </div>
                                                <div className="row mt-2 p-3">
                                                    <div className="col form-check">
                                                        <input
                                                        data-testid="inputCheckbox"
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="flexCheckChecked"
                                                            defaultChecked
                                                        ></input>
                                                        <label
                                                        data-testid="labelReceive"
                                                            className="h4 text-grey form-check-label"
                                                            htmlFor="flexCheckChecked"
                                                        >
                                                            I would like to
                                                            receive relevant
                                                            updates from
                                                            PayBrij.
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="d-flex">
                                                    <button
                                                    data-testid="btnSubmit"
                                                        type="submit"
                                                        className="btn btn-default bg-contact rounded-4"
                                                    >
                                                        Submit
                                                    </button>
                                                    <span
                                                        id="messageContainer"
                                                        className="h4 text-grey ms-5"
                                                    ></span>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-color container-fluid">
                        <div className=" row p-5 justify-content-between my-2">
                            <div className="col-sm-auto">
                                <h2 className="semi-bold md-48">PayBrij</h2>
                                <div className="row justify-content-space-around g-2">
                                    <div className="col-auto">
                                        <i className="bi bi-globe2"></i>
                                    </div>
                                    <div className="col-auto">
                                        <h5 className="h6">Global-English</h5>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-auto">
                                <div className="row g-2">
                                    <div className="col-auto">
                                        <i className="bi bi-youtube"></i>
                                    </div>
                                    <div className="col-auto">
                                        <h6>Youtube</h6>
                                    </div>
                                </div>
                                <div className="row g-2">
                                    <div className="col-auto">
                                        <i className="bi bi-instagram"></i>
                                    </div>
                                    <div className="col-auto">
                                        <h6>Instagram</h6>
                                    </div>
                                </div>
                                <div className="row g-2">
                                    <div className="col-auto">
                                        <i className="bi bi-twitter"></i>
                                    </div>
                                    <div className="col-auto">
                                        <h6>Twitter</h6>
                                    </div>
                                </div>
                                <div className="row g-2">
                                    <div className="col-auto">
                                        <i className="bi bi-facebook"></i>
                                    </div>
                                    <div className="col-auto">
                                        <h6>Facebook</h6>
                                    </div>
                                </div>
                                <div className="row g-2">
                                    <div className="col-auto">
                                        <i className="bi bi-linkedin"></i>
                                    </div>
                                    <div className="col-auto">
                                        <h6>LinkedIn</h6>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="mt-2"></hr>
                        <div className="row my-1 justify-content-center">
                            <div className="col-auto">
                                <div className="tagone row g-1">
                                    <div className="col-auto">
                                        <i className="bi bi-c-circle"></i>
                                    </div>
                                    <div className="col-auto">
                                        <h6 className="h6">2023 PayBrij</h6>
                                    </div>
                                </div>
                            </div>
                            <div className="col-auto">
                                <h6>Consent Preferences</h6>
                            </div>
                            <div className="col-auto">
                                <h6>Terms</h6>
                            </div>
                            <div className="col-auto">
                                <h6>Security</h6>
                            </div>
                            <div className="col-auto">
                                <h6>Privacy</h6>
                            </div>
                            <div className="col-auto">
                                <h6>Do not tell my personal information</h6>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PayBrijSiteContainer;
