// const baseUrl = `http://paybrijapi.copiacs.com/api`;
const baseUrl = `https://paybrijapi.copiacs.com/api`;
//const baseUrl = `http://localhost:63062/api`;

const API = {
    TYPE: {
        GET: 'get',
        POST: 'post',
    },
    LOGIN: {
        LOGIN: `${baseUrl}/Login`,
        RESET_PASSWORD: `${baseUrl}/login/update-password`,
        SING_IN: `${baseUrl}/login/user`,
    },
    BANK: {
        GET_BANK_LIST: `${baseUrl}/bank/list`,
        CREATE_BANK: `${baseUrl}/bank/create`,
        UPDATE_BANK: `${baseUrl}/bank/edit`,
        GET_BANK: `${baseUrl}/bank/edit/data`,
        DELETE_BANK: `${baseUrl}/bank/delete`,
    },
    CUSTOMER: {
        GET_CUSTOMER_LIST: `${baseUrl}/customer/list`,
        CREATE_CUSTOMER: `${baseUrl}/customer/create`,
        UPDATE_CUSTOMER: `${baseUrl}/customer/edit`,
        GET_CUSTOMER: `${baseUrl}/customer/edit/data`,
        DELETE_CUSTOMER: `${baseUrl}/customer/delete`,
    },
    PAYMENT_TYPES: {
        GET_PAYMENT_TYPES_LIST: `${baseUrl}/payment/list`,
        CREATE_PAYMENT_TYPES: `${baseUrl}/payment/create`,
        UPDATE_PAYMENT_TYPES: `${baseUrl}/payment/edit`,
        GET_PAYMENT_TYPES: `${baseUrl}/payment/edit/data`,
        DELETE_PAYMENT_TYPES: `${baseUrl}/payment/delete`,
    },
    BANKFILE: {
        GET_BANK_LIST_DD: `${baseUrl}/bankfile/banknames`,
        GET_PAYMENT_TYPE_LIST: `${baseUrl}/bankfile/paymenttypes`,
        UPLOAD_BANK_FILE: `${baseUrl}/bankfile/upload`,
        GET_BANKFILE_LIST: `${baseUrl}/bankfile/list`,
    },
    CUSTOMERFILE: {
        UPLOAD_CUSTOMER_FILE: `${baseUrl}/customerfile/upload`,
        GET_CUSTOMER_LIST_ID: `${baseUrl}/customerfile/customernames`,
        GET_CUSTOMERFILE_LIST: `${baseUrl}/customerfile/list`,
    },
    CONTACTUS: {
        CONTACT_US: `${baseUrl}/contactus`,
        CONTACT_US_USER: `${baseUrl}/usersetup`,
    },
    TEMPLATE_MAPPER: {
        CUSTOMER_TEMPLATE_HEADER: `${baseUrl}/customer-template-header/list`,
        CUSTOMER_TEMPLATE_CONFIGURED_HEADER: `${baseUrl}/configured-customer-template-header/list`,
        BANK_TEMPLATE: `${baseUrl}/bank-template`,
    },
    LOGIN_USER: {
        LOGIN: `${baseUrl}/login`,
    },
    USER: {
        GET_USER_LIST: `${baseUrl}/user/list`,
        GET_USER_BY_ID: `${baseUrl}/user/edit/data`,
        CREATE_USER: `${baseUrl}/user/create`,
        UPDATE_USER: `${baseUrl}/user/edit`,
    },
    ROLE: {
        GET_ROLE_LIST: `${baseUrl}/role/list`,
        CREATE_ROLE: `${baseUrl}/role/create`,
    },
    BANK_TEMPLATE: {
        URL: `${baseUrl}/bank-template`,
        GET: 'get',
        GET_BANK_TEMP: `${baseUrl}/banktemplate/view/data`,
    },
    CONFIGURED_CUSTOMER_TEMPLATE: {
        URL: `${baseUrl}/configured-customer-template`,
        GET: 'get',
    },
    SAVE_CUSTOMER_TEMPLATE_HEADER: {
        URL: `${baseUrl}/configured-customer-template-header`,
        POST: 'post',
    },
    WORKFLOW: {
        CREATE_WORKFLOW: `${baseUrl}/workflow/create`,
        WORKFLOW_LIST: `${baseUrl}/workflow/list`,
        UPDATE_WORKFLOW: `${baseUrl}/workflow/edit`,
        GET_WORKFLOW: `${baseUrl}/workflow/edit/data`,
        UPDATE_WORKFLOW_STATUS: `${baseUrl}/workflow/status/edit`,
    },
};

export default API;
