import axios from 'axios';
import API from '../../constants/Api';
import { ApiResponse } from '../../model/ApiResponseModel';
import { CustomerTemplateModel } from '../../model/CustomerTemplateModel';
import ReduxError from '../../model/ErrorModel';

export async function findAllCustomerTemplateConfiguredHeadersRequest(
    requestPayload: any
): Promise<ApiResponse> {
    const result: ApiResponse<Array<CustomerTemplateModel>, ReduxError> = {
        data: new Array<CustomerTemplateModel>(),
        error: null,
    };
    const body = {
        CustomerId: requestPayload.id,
    };
    return await axios({
        method: API.TYPE.POST,
        url: API.TEMPLATE_MAPPER.CUSTOMER_TEMPLATE_CONFIGURED_HEADER,
        data: body,
    }).then(
        (response) => {
            console.log(response.data);

            if (response.data && response.data.length) {
                response.data.map((item: string, index: number) => {
                    result.data.push({
                        name: item,
                        id: index,
                        isSelected: false,
                    });
                });
            }
            return result;
        },
        (err) => {
            result.error = {
                message: 'Something went wrong!',
                stackTrace: err,
            };
            return result;
        }
    );
}
