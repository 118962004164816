import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';
import { UserDetails } from '../utility';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../src/sagas/slice/rootReducer';

type CdsNavbarProps = {
    logout: () => void;
    //pageTitle : string;
};

const CdsNavbar: React.FC<CdsNavbarProps> = (props) => {
    const[userName, setUserName] = useState<string>(null);

    const navigate = useNavigate();

    const rows = useSelector((state: RootState) => state.customers.customers);

    // const viewprofile = () => {
    //     navigate('dashboard', { replace: true });
    // };

    useEffect(() => {
        const userData = UserDetails();
        if (userData) {
            setUserName(userData.UserName);
        }
    }, [UserDetails]);

    return (
        <>
            <div className="row sticky-top background p-3">
                <div className="col-2 text-center">
                    <img height={40} src="/logo.png" alt="icon" />
                </div>
                <div className="col-10">
                    <div className="row g-2 h4 justify-content-between">
                        <h3 className="col-auto mt-3 p-0">
                            Welcome: {userName}
                        </h3>
                        <div className="col-auto">
                            <button data-testid = 'btnLogout'
                                onClick={() => props.logout()}
                                className="navbtn"
                            >
                                <div className="row justify-content-end g-2 h4">
                                    <div className="col-auto mt-3">
                                        <i className="fa-lg bi bi-box-arrow-in-right"></i>
                                    </div>
                                    <div className="col-auto mt-3 logoutmarginright">
                                        Logout
                                    </div>
                                </div>
                            </button>
                            <button
                                onClick={() =>
                                    navigate('dashboard', { replace: true })
                                }
                                className="navbtn"
                            >
                                <div className="row justify-content-end g-2 h4">
                                    <div className="col-auto mt-3">
                                        <i className="bi bi-person-circle"></i>
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
export default CdsNavbar;
