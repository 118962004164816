import React, { useEffect } from 'react';
import '../../features/profile/ProfilePage.css';
import CdsTitle from '../../components/CdsTitle';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../sagas/slice/rootReducer';
import {
    FIND_CUSTOMER_BY_ID,
    UPDATE_CUSTOMER_BY_ID,
} from '../../sagas/types/customer';
import Customer from '../../model/CustomerModel';
import { UserDetails } from '../../utility';
import { useFormik } from 'formik';
import CdsButton from '../../components/CdsButton';
import { addProfilePageSchema } from '../schema/ProfilePage';

type EditProfileProps = {};

const EditProfile: React.FC<EditProfileProps> = (props) => {
    const dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();
    const userData = UserDetails();
    const customer: Customer = useSelector(
        (state: RootState) => state.customer.customer
    );

    const initialValue: Customer = {
        id: 0,
        customername: '',
        address: '',
        contact: '',
        city: '',
        pincode: '',
        customermobileNo: '',
        customeremail: '',
        active: '',
    };

    const myFormik = useFormik({
        initialValues: initialValue,
        validationSchema: addProfilePageSchema,
        onSubmit: (values) => {
            console.log(values);
            dispatch({ type: UPDATE_CUSTOMER_BY_ID, customer: values });
            navigate('/cp/dashboard', { replace: true });
        },
    });

    useEffect(() => {
        
        dispatch({ type: FIND_CUSTOMER_BY_ID, id: userData.CompanyID });
    }, [dispatch]);

    useEffect(() => {
        if (customer) {
            onEditSetCustomerValues(customer);
        }
    }, [customer]);

    const onEditSetCustomerValues = (customer: any) => {
        myFormik.setValues({
            id: customer.ID,
            customername: customer.CustomerName,
            address: customer.Address,
            contact: customer.CustomerContact,
            city: customer.City,
            pincode: customer.PinCode,
            customermobileNo: customer.CustomerContact_Mobile,
            customeremail: customer.CustomerContact_Email,
            active: customer.IsActive,
        });
    };

    // const myFormik = useFormik({
    //     initialValues: initialValue,
    //     // validationSchema: addCustomerSchema,
    //     onSubmit: () => {},
    // });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;

    return (
        <form onSubmit={handleSubmit} className="mb-4 container-fluid">
            <CdsTitle
                linkto="/cp/dashboard"
                title="Customer Profile"
                iconClass="bi bi-people"
                link="Back To Profile"
            ></CdsTitle>

            <div className="row">
                <div className=" profile-container col-md-8">
                    <div className="profile-card mb-3">
                        <div className="profile-card-body">
                            <div className="row my-2">
                                <div className="col-md-6">
                                    <h6 className="mb-0">Customer Name</h6>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        name="customername"
                                        value={values.customername}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        id="customerName"
                                        placeholder="Enter Customer Name"
                                    ></input>
                                    {errors.customername &&
                                    touched.customername ? (
                                        <p className="form-val form-error">
                                            {errors.customername}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row my-2">
                                <div className="col-md-6">
                                    <h6 className="mb-0">Contact</h6>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        type="tel"
                                        className="form-control"
                                        id="contactNo"
                                        name="contact"
                                        value={values.contact}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter Contact Number"
                                    ></input>
                                    {errors.contact && touched.contact ? (
                                        <p className="form-val form-error">
                                            {errors.contact}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row my-2">
                                <div className="col-md-6">
                                    <h6 className="mb-0">Address</h6>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="address"
                                        name="address"
                                        value={values.address}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter Address"
                                    ></input>
                                    {errors.address && touched.address ? (
                                        <p className="form-val form-error">
                                            {errors.address}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row my-2">
                                <div className="col-md-6">
                                    <h6 className="mb-0">City</h6>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="city"
                                        name="city"
                                        value={values.city}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter City"
                                    ></input>
                                    {errors.city && touched.city ? (
                                        <p className="form-val form-error">
                                            {errors.city}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row my-2">
                                <div className="col-md-6">
                                    <h6 className="mb-0">Pincode</h6>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="pincode"
                                        name="pincode"
                                        value={values.pincode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter Pincode"
                                    ></input>
                                    {errors.pincode && touched.pincode ? (
                                        <p className="form-val form-error">
                                            {errors.pincode}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row my-2">
                                <div className="col-md-6">
                                    <h6 className="mb-0">Customer Contact</h6>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        type="tel"
                                        className="form-control"
                                        id="customerMobiletNo"
                                        name="customermobileNo"
                                        value={values.customermobileNo}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter Customer Contact"
                                    ></input>
                                    {errors.customermobileNo &&
                                    touched.customermobileNo ? (
                                        <p className="form-val form-error">
                                            {errors.customermobileNo}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row my-2">
                                <div className="col-md-6">
                                    <h6 className="mb-0">Customer Email</h6>
                                </div>
                                <div className="col-md-6">
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="email"
                                        name="customeremail"
                                        value={values.customeremail}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter Customer Email"
                                    ></input>
                                    {errors.customeremail &&
                                    touched.customeremail ? (
                                        <p className="form-val form-error">
                                            {errors.customeremail}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row my-2 pull-right">
                                <div className="col-md-6"></div>
                                <div className="col-md-2">
                                    <CdsButton
                                        title="Back"
                                        type="button"
                                        handleClick={(e) =>
                                            navigate('/cp/dashboard')
                                        }
                                    />
                                </div>
                                <div className="col-md-3 ">
                                    <CdsButton
                                        title="Save Changes"
                                        type="submit"
                                        handleClick={(e) => navigate('')}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default EditProfile;
