import React from 'react';
import AdminEmailTemplate from '../../components/AdminEmailTemplate';
import CustomerEmailTemplate from '../../components/CustomerEmailTemplate';


const App: React.FC = () => {
  return (
    <div>
      <h2>Customer Template:</h2>
      <CustomerEmailTemplate></CustomerEmailTemplate>

      {/* <h2>Admin Template:</h2>
      <AdminEmailTemplate></AdminEmailTemplate> */}
      
    </div>
  );
};

export default App;
