import React, { useEffect, useState } from 'react';
import '../../features/profile/ProfilePage.css';
import CdsTitle from '../../components/CdsTitle';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../sagas/slice/rootReducer';
import {
    FIND_CUSTOMER_BY_ID,
    UPDATE_CUSTOMER_BY_ID,
} from '../../sagas/types/customer';
import Customer from '../../model/CustomerModel';
import { UserDetails } from '../../utility';
import { useFormik } from 'formik';
import CdsButton from '../../components/CdsButton';

type ProfilePageProps = {};

const ProfilePage: React.FC<ProfilePageProps> = (props) => {
    const [userName, setUserName] = useState<string>(null);
    const dispatch = useDispatch();
    const userData = UserDetails();
    const navigate = useNavigate();
    const customer: Customer = useSelector(
        (state: RootState) => state.customer.customer
    );

    const initialValue: Customer = {
        id: 0,
        customername: '',
        address: '',
        contact: '',
        city: '',
        pincode: '',
        customermobileNo: '',
        customeremail: '',
        active: '',
    };

    const myFormik = useFormik({
        initialValues: initialValue,
        //validationSchema: addCustomerSchema,
        onSubmit: (values) => {
            console.log(values);
            //sendData(values, params.ID);
            //dispatch({ type: UPDATE_CUSTOMER_BY_ID, customer: values });
            //navigate('/cp/customer', { replace: true });
        },
    });

    useEffect(() => {
        
        dispatch({ type: FIND_CUSTOMER_BY_ID, id: userData.CompanyID });
    }, [dispatch]);

    useEffect(() => {
        if (customer) {
            onEditSetCustomerValues(customer);
        }
    }, [customer]);

    useEffect(() => {
        const userData = UserDetails();
        if (userData) {
            setUserName(userData.UserName);
        }
    }, [UserDetails]);

    const onEditSetCustomerValues = (customer: any) => {
        myFormik.setValues({
            id: customer.ID,
            customername: customer.CustomerName,
            address: customer.Address,
            contact: customer.CustomerContact,
            city: customer.City,
            pincode: customer.PinCode,
            customermobileNo: customer.CustomerContact_Mobile,
            customeremail: customer.CustomerContact_Email,
            active: customer.IsActive,
        });
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;

    return (
        <form className="mb-4 container-fluid">
            <CdsTitle
                linkto="/cp/users"
                title="Customer Profile"
                iconClass="bi bi-people"
                link="Back To List"
            ></CdsTitle>
            <div className="row">
                <div className="profile-container col-md-8">
                    <div className="profile-card mb-3">
                        <div className="profile-card-body">
                        <div className="row">
                                <div className="pull-right col-sm-11">
                                    <CdsButton
                                        title="Edit"
                                        type="submit"
                                        handleClick={(e) =>
                                            navigate(
                                                `/cp/dashboard/editprofile/${userData.CompanyID}`
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-sm-3">
                                    <h6 className="mb-0">User Name</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                    {userName}
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row">
                                <div className="col-sm-3">
                                    <h6 className="mb-0">Customer Name</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                    {values.customername}
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row">
                                <div className="col-sm-3">
                                    <h6 className="mb-0">Contact</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                    {values.contact}
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row">
                                <div className="col-sm-3">
                                    <h6 className="mb-0">Address</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                    {values.address}
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row">
                                <div className="col-sm-3">
                                    <h6 className="mb-0">City</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                    {values.city}
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row">
                                <div className="col-sm-3">
                                    <h6 className="mb-0">Pincode</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                    {values.pincode}
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row">
                                <div className="col-sm-3">
                                    <h6 className="mb-0">Customer Contact</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                    {values.customermobileNo}
                                </div>
                            </div>
                            <hr></hr>
                            <div className="row">
                                <div className="col-sm-3">
                                    <h6 className="mb-0">Customer Email</h6>
                                </div>
                                <div className="col-sm-9 text-secondary">
                                    {values.customeremail}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default ProfilePage;
