import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ReduxError from "../../../model/ErrorModel";
import PaymentTypeWithId from "../../../model/PaymentTypeWithIdModel";
import store from "../../../Store";

interface PaymentTypeState {
    isFetched: boolean;
    paymentTypeId: Array<PaymentTypeWithId>;
    error: ReduxError | null;
  }

  const initialState: PaymentTypeState = {
    isFetched: false,
    paymentTypeId: new Array<PaymentTypeWithId>(),
    error: null,
  };

  export const paymentTypeWithIdSlice = createSlice({
    name : "paymentType",
    initialState,
    reducers : {
          findAllPaymentTypesWithIdSlice: (state, action: PayloadAction<PaymentTypeWithId[]>) => {
            state.paymentTypeId = action.payload;
            return state;
          },
  }
})
type RootState = ReturnType<typeof store.getState>;

export const {
    findAllPaymentTypesWithIdSlice
  } = paymentTypeWithIdSlice.actions;
  
  // Other code such as selectors can use the imported `RootState` type
  export const selectAllPaymentTypesWithId = (state: RootState) => state.paymentTypeId.paymentTypeId;
  
  export default paymentTypeWithIdSlice.reducer;
