import axios from 'axios';
import API from '../../constants/Api';
import BankFileListModel from '../../model/BankFileListModel';
import BankNames from '../../model/BankNameModel';
import PaymentTypeWithId from '../../model/PaymentTypeWithIdModel';
import UploadBankFile from '../../model/UploadBankFileModel';
import { ApiResponse } from '../../model/ApiResponseModel';

export async function findAllBanksFileRequest(
    requestPayload: any
): Promise<ApiResponse> {
    const result: ApiResponse = {
        data: null,
        error: null,
    };
    const body = {
        BankFileName : requestPayload
    };
    return await axios({
        method: API.TYPE.POST,
        url: API.BANKFILE.GET_BANKFILE_LIST,
        data : body
    }).then((response) => {
        // set response to data
         
        console.log(response.data.BankName);
        result.data = response.data.BankName;
        return result.data;
    },
    (err) => {
        result.error = {
            message: 'some issue',
            stackTrace: err.message,
        };
        return result;
    });
}
export async function findAllBanksNamesRequest(
    requestPayload: any
): Promise<Array<BankNames>> {
    let data: Array<BankNames> = new Array<BankNames>();
    const body = {};
    return await axios({
        method: API.TYPE.GET,
        url: API.BANKFILE.GET_BANK_LIST_DD,
    }).then((response) => {
        // set response to data
        console.log(response.data.BankName);
        data = response.data.BankName;
        return data;
    });
}
export async function findAllPaymentTypesWithIdRequest(
    requestPayload: any
): Promise<Array<PaymentTypeWithId>> {
    let data: Array<PaymentTypeWithId> = new Array<PaymentTypeWithId>();
    const body = {};
    return await axios({
        method: API.TYPE.GET,
        url: API.BANKFILE.GET_PAYMENT_TYPE_LIST,
    }).then((response) => {
        // set response to data
        console.log(response.data.PaymentType);
        data = response.data.PaymentType;
        return data;
    });
}
export async function uploadBankFileRequest(
    payload: UploadBankFile
): Promise<boolean> {
    const body = {
        BankID: payload.BankID,
        PaymentTypeID: payload.PaymentTypeID,
        file: payload.File,
        fileName: payload.FileName,
        CustomerID: payload.CustomerID
    };
    return await axios({
        method: API.TYPE.POST,
        url: API.BANKFILE.UPLOAD_BANK_FILE,
        data: body,
    }).then((response) => {
        // set response to data
        console.log(response.data);
        return response.data;
    });
}
