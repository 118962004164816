import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { UserDetails, isFileEmpty, isValidFileType } from '../../../utility';
import { useDispatch, useSelector } from 'react-redux';
import {
    GET_ALL_BANKS,
    GET_ALL_PAYMENT_TYPES,
    UPLOAD_BANK_FILE,
} from '../../../sagas/types/bank-file';
import { RootState } from '../../../sagas/slice/rootReducer';
import UploadBankFile from '../../../model/UploadBankFileModel';
import CdsTitle from '../../../components/CdsTitle';
import CdsButton from '../../../components/CdsButton';
import { GET_ALL_CUSTOMERS } from '../../../sagas/types/customer-file';

type BankXmlUploadProps = {};
const BankXmlUpload: React.FC<BankXmlUploadProps> = (props) => {
    const initialValue: UploadBankFile = {
        BankID: '',
        PaymentTypeID: '',
        // uploadxml: '',
        files: '', // name
        File: '', // use this for api
        FileName: '', // use this for api
        CustomerID: '',
    };

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch({ type: GET_ALL_BANKS });
        dispatch({ type: GET_ALL_PAYMENT_TYPES });
        dispatch({ type: GET_ALL_CUSTOMERS });
    }, [dispatch]);

    const rows = useSelector((state: RootState) => state.bankNames.bankNames);
    const type = useSelector(
        (state: RootState) => state.paymentTypeName.paymentTypeId
    );
    const customers = useSelector(
        (state: RootState) => state.customerFile.uploadCustomerFile
    );
    console.log(rows, type);

    const fileRef = useRef(null);
    const bankFileUploadSchema = Yup.object({
        BankID: Yup.string().required('Please select your bank name'),
        PaymentTypeID: Yup.string().required('Please select payment type'),
        CustomerID: Yup.string().required('Please select customer name'),
        files: Yup.string()
            .required('Please select file')
            .test(
                'is-file-of-correct-type',
                'File is not of supported type',
                () => {
                    return isValidFileType(fileRef);
                }
            )
            .test('is-file-empty', 'File should not be empty', () => {
                return isFileEmpty(fileRef);
            }),
    });
    const myFormik = useFormik({
        initialValues: initialValue,
        validationSchema: bankFileUploadSchema,
        onSubmit: (values: UploadBankFile) => {
            console.log(values);
            dispatch({ type: UPLOAD_BANK_FILE, upload: values });
            navigate('/cp/banktemplate', { replace: true });
        },
    });
    const { values, errors, handleBlur, touched, handleChange, handleSubmit } =
        myFormik;
    console.log(myFormik);
    const handleFileUpload = () => {
        var reader = new FileReader();
        reader.readAsDataURL(fileRef.current.files[0]);
        reader.onload = (_event) => {
            myFormik.setFieldValue('FileName', fileRef.current.files[0].name);
            myFormik.setFieldValue('File', reader.result);
        };
    };
    return (
        <form onSubmit={handleSubmit} className="container-fluid">
            <CdsTitle
                linkto="/cp/banktemplate"
                title="Bank Template"
                iconClass="bi bi-filetype-xml"
                link="Back To List"
                 headerDataTestId="titleBankFile"
                linkDataTestId="btnBackToList"
            ></CdsTitle>
            <div className="card mt-3 border rounded-4">
                <div className="card-header">
                    <h6 data-testid="titleAddBankXML" className="md-18">Add Bank XML</h6>
                </div>
                <div className="card-body">
                    <div className="row my-4">
                        <div className="col">
                            <label htmlFor="CompanyID" className="form-label">
                                <h6 data-testid="labelBankcustName">Customer Name</h6>
                            </label>
                            {/* <input
                                type="text"
                                className="form-control"
                                value={userInfo.CustomerName}
                                disabled={true}
                            ></input> */}
                            <select
                            data-testid="ddlCustName"
                                id="CustomerID"
                                name="CustomerID"
                                value={values.CustomerID}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-select"
                            >
                                <option selected>Select Customer Name</option>
                                {customers?.map((item: any, index: number) => {
                                    return (
                                        <>
                                            <option value={item.CustomerID}>
                                                {item.CustomerName}
                                            </option>
                                        </>
                                    );
                                })}
                            </select>
                            {errors.CustomerID && touched.CustomerID ? (
                                <p data-testid="errorCustName" className="form-val form-error">
                                    {errors.CustomerID}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="BankID" className="form-label">
                                <h6 data-testid="labelBankName">Bank Name</h6>
                            </label>
                            <select
                            data-testid="ddlBankName"
                                id="BankID"
                                name="BankID"
                                value={values.BankID}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-select"
                            >
                                <option selected>Select Bank Name</option>
                                {rows?.map((item: any, index: number) => {
                                    return (
                                        <>
                                            <option value={item.BankID}>
                                                {item.BankName}
                                            </option>
                                        </>
                                    );
                                })}
                            </select>
                            {errors.BankID && touched.BankID ? (
                                <p data-testid="errorBankName" className="form-val form-error">
                                    {errors.BankID}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label
                                htmlFor="PaymentTypeID"
                                className="form-label"
                            >
                                <h6 data-testid="labelPaymentType">Payment Type</h6>
                            </label>
                            <select
                            data-testid="ddlPaymenttype"
                                className="form-select"
                                id="PaymentTypeID"
                                name="PaymentTypeID"
                                value={values.PaymentTypeID}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <option selected>Select Payment Type</option>
                                {type?.map((item: any, index: number) => {
                                    return (
                                        <>
                                            <option value={item.PaymentTypeID}>
                                                {item.PaymentType}
                                            </option>
                                        </>
                                    );
                                })}
                            </select>
                            {errors.PaymentTypeID && touched.PaymentTypeID ? (
                                <p data-testid="errorPaymentType" className="form-val form-error">
                                    {errors.PaymentTypeID}
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <div className="row my-4">
                        <label className="form-label">
                            <h6 data-testid="labelAddXmlFile">Add Xml File</h6>
                        </label>
                        <div className="input-group col">
                            <input
                            data-testid="inputAddXmlFile"
                                type="file"
                                className="form-control"
                                name="files"
                                ref={fileRef}
                                {...fileRef}
                                // value={values.files}
                                onChange={(e) => {
                                    handleFileUpload();
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                            />
                            {errors.files && touched.files ? (
                                <p data-testid="errorAddXmlFile" className="form-val form-error">
                                    {errors.files}
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <div className="justify-content-end row">
                        <div  data-testid="btnCancel"className="col-auto">
                            <CdsButton
                                title="Cancel"
                                type="button"
                                handleClick={(e) =>
                                    navigate('/cp/banktemplate')
                                }
                            />
                        </div>
                        <div data-testid="btnSubmit" className="col-auto ">
                            <CdsButton
                                title="Submit"
                                type="submit"
                                handleClick={(e) => handleSubmit()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default BankXmlUpload;
