import * as Yup from "yup";

export const addProfilePageSchema = Yup.object({
  customername: Yup.string().min(2).max(25),
  address: Yup.string().min(5).max(80),
  contact: Yup.string().matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),
  city: Yup.string().min(2).max(15),
  pincode:  Yup.string().matches(/^\d{6}$/, "Pincode must be exactly 6 digits"),
  customermobileNo: Yup.string().matches(/^\d{10}$/, "Phone number must be exactly 10 digits"),
  customeremail: Yup.string().email(),
});
