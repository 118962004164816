import { Link } from 'react-router-dom';
import './Navbar.css';
import { useEffect, useState } from 'react';
import { UserDetails } from '../utility';

type CdsSidebarProps = {
    RoleID: string;
};

const CdsSidebar: React.FC<CdsSidebarProps> = (props) => {
    const userData = UserDetails();

    return (
        <>
            {userData.RoleID == '1' && (
                <div>
                    <ul className="list-unstyled ps-0">
                        <li className="my-2 nav-item">
                            <Link
                                to="/cp/users"
                                id="users"
                                type="button"
                                className="sidebarbtn btn "
                            >
                                <div  data-testid='sideBarUser' className="row g-3 h4">
                                    <div className="col-auto">
                                        <i className="bi bi-people"></i>
                                    </div>
                                    <div className="col-auto">
                                        <div>User</div>
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li className="my-2 nav-item">
                            <Link
                                to="customertemplate"
                                id="customertemplate"
                                type="button"
                                className="sidebarbtn btn "
                            >
                                <div data-testid='sideBarCustomer' className="row g-3 h4">
                                    <div className="col-auto">
                                        <i className="bi bi-file-excel"></i>
                                    </div>
                                    <div className="col-auto">
                                      Customer File
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li className="my-2 nav-item">
                            <Link
                                to="workflows"
                                id="workflows"
                                type="button"
                                className="sidebarbtn btn "
                            >
                                <div data-testid='sideBarWorkFlow' className="row g-3 h4">
                                    <div className="col-auto">
                                        <i className="bi bi-filetype-xml"></i>
                                    </div>
                                    <div className="col-auto">WorkFlow</div>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            )}

            {userData.RoleID == '5' && (
                <div>
                    <ul className="list-unstyled ps-0">
                        <li className="my-2 nav-item">
                            <Link
                                to="bank"
                                id="paymentType"
                                type="button"
                                className="sidebarbtn btn "
                            >
                                <div  data-testid='sideBarBank'className="row g-3 h4">
                                    <div className="col-auto">
                                        <i className="bi bi-bank"></i>
                                    </div>
                                    <div className="col-auto">Bank</div>
                                </div>
                            </Link>
                        </li>
                        <li className="my-2 nav-item">
                            <Link
                                to="customer"
                                id="paymentType"
                                type="button"
                                className="sidebarbtn btn "
                            >
                                <div data-testid='sideBarCustomer'className="row g-3 h4">
                                    <div className="col-auto">
                                        <i className="bi bi-people"></i>
                                    </div>
                                    <div className="col-auto">Customer</div>
                                </div>
                            </Link>
                        </li>
                        <li className="my-2 nav-item">
                            <Link
                                to="/cp/users"
                                id="users"
                                type="button"
                                className="sidebarbtn btn "
                            >
                                <div data-testid='sideBarUser' className="row g-3 h4">
                                    <div className="col-auto">
                                        <i className="bi bi-people"></i>
                                    </div>
                                    <div className="col-auto">
                                        <div>User</div>
                                    </div>
                                </div>
                            </Link>
                        </li>

                        <li className="my-2 nav-item">
                            <Link
                                to="/cp/roles"
                                id="role"
                                type="button"
                                className="sidebarbtn btn "
                            >
                                <div  data-testid='sideBarRole' className="row g-3 h4">
                                    <div className="col-auto">
                                        <i className="bi bi-people"></i>
                                    </div>
                                    <div className="col-auto">
                                        <div>Role</div>
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li className="my-2 nav-item">
                            <Link
                                to="/cp/paymenttype"
                                id="paymentType"
                                type="button"
                                className="sidebarbtn btn "
                            >
                                <div data-testid='sideBarPaymentType' className="row g-3 h4">
                                    <div className="col-auto">
                                        <i className="bi bi-cash-coin"></i>
                                    </div>
                                    <div className="col-auto">Payment Type</div>
                                </div>
                            </Link>
                        </li>
                        <li className="my-2 nav-item">
                            <Link
                                to="banktemplate"
                                id="banktemplate"
                                type="button"
                                className="sidebarbtn btn "
                            >
                                <div data-testid='sideBarBankTemplate'className="row g-3 h4">
                                    <div className="col-auto">
                                        <i className="bi bi-filetype-xml"></i>
                                    </div>
                                    <div className="col-auto">
                                        Bank Template
                                    </div>
                                </div>
                            </Link>
                        </li>

                        <li className="my-2 nav-item">
                            <Link
                                to="customertemplate"
                                id="customertemplate"
                                type="button"
                                className="sidebarbtn btn "
                            >
                                <div data-testid='sideBarCustomerTemplate'className="row g-3 h4">
                                    <div className="col-auto">
                                        <i className="bi bi-file-excel"></i>
                                    </div>
                                    <div className="col-auto">
                                        Customer Template
                                    </div>
                                </div>
                            </Link>
                        </li>
                        <li className="my-2 nav-item">
                            <Link
                                to="workflows"
                                id="workflows"
                                type="button"
                                className="sidebarbtn btn "
                            >
                                <div data-testid='sideBarWorkFlow'className="row g-3 h4">
                                    <div className="col-auto">
                                        <i className="bi bi-filetype-xml"></i>
                                    </div>
                                    <div className="col-auto">WorkFlow</div>
                                </div>
                            </Link>
                        </li>
                        <li className="my-2 nav-item">
                            <Link
                                to="/cp/mapper"
                                id="bankXml"
                                type="button"
                                className="sidebarbtn btn "
                            >
                                <div className="row g-3 h4">
                                    <div className="col-auto">
                                        <i className="bi bi-arrow-left-right"></i>
                                    </div>
                                    <div className="col-auto">Mapper</div>
                                </div>
                            </Link>
                        </li>
                    </ul>
                </div>
            )}
        </>
    );
};
export default CdsSidebar;
