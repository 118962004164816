import { all, call, put, takeLatest } from 'redux-saga/effects';
import { findAllCustomerTemplateHeadersRequest } from '../requests/CustomerTemplateHeader';
import {
    findAllCustomerTemplateHeadersSuccess,
    findAllCustomerTemplateHeadersFailed,
} from '../slice/template-mapper/CustomerTemplateHeader';
import { FIND_ALL_CUSTOMER_TEMPLATE_HEADERS } from '../types/template-mapper';

export function* findAllCustomerTemplateHeadersSaga(action) {
    try {
        const { data, error } = yield call(
            findAllCustomerTemplateHeadersRequest,
            action.data
        );
        if (error) {
            yield put(findAllCustomerTemplateHeadersFailed(error));
        } else {
            yield put(findAllCustomerTemplateHeadersSuccess(data));
        }
    } catch (err) {
        yield put(findAllCustomerTemplateHeadersFailed(err));
    }
}

function* customerTemplateHeaderSaga() {
    /// new
    yield all([
        takeLatest(
            FIND_ALL_CUSTOMER_TEMPLATE_HEADERS,
            findAllCustomerTemplateHeadersSaga
        ),
    ]);
}

export default customerTemplateHeaderSaga;
