import { all, fork } from 'redux-saga/effects';
import bankSaga from './handlers/Bank';
import bankFileSaga from './handlers/BankFile';
import customerSaga from './handlers/Customer';
import customerFileSaga from './handlers/CustomerFile';
import customerTemplateConfiguredHeaderSaga from './handlers/CustomerTemplateConfiguredHeader';
import customerTemplateHeaderSaga from './handlers/CustomerTemplateHeader';
import paymentTypeSaga from './handlers/PaymentType';
import bankTemplateSaga from './handlers/BankTemplate';
import userSaga from './handlers/User';
import rolesaga from './handlers/Role';
import workflowSaga from './handlers/Workflow';

export function* rootSaga() {
    yield all([fork(bankSaga)]);
    yield all([fork(customerSaga)]);
    yield all([fork(paymentTypeSaga)]);
    yield all([fork(bankFileSaga)]);
    yield all([fork(customerFileSaga)]);
    yield all([fork(customerTemplateHeaderSaga)]);
    yield all([fork(customerTemplateConfiguredHeaderSaga)]);
    yield all([fork(userSaga)]);
    yield all([fork(rolesaga)]);
    yield all([fork(bankTemplateSaga)]);
    yield all([fork(workflowSaga)])
}
export default rootSaga;
