import axios from 'axios';
//import axios, { AxiosError } from 'axios';
import API from '../../constants/Api';
import { ApiResponse } from '../../model/ApiResponseModel';
import ReduxError from '../../model/ErrorModel';
import UploadBankFile from '../../model/BankFileListModel';


export async function findBankTemplateRequest(
    requestPayload: any
): Promise<ApiResponse> {
    const result: ApiResponse<string, ReduxError> = {
        data: null,
        error: null,
    };
    const body = {
        CustomerId: requestPayload.id,
    };
    return await axios({
        method: API.BANK_TEMPLATE.GET,
        url: API.BANK_TEMPLATE.URL,
        data: body,
    }).then(
        (response) => {
            console.log(response.data);
            if (response.data) {
                result.data = response.data;
                return result;
            }
            return result;
        },
        (err) => {
            result.error = {
                message: 'Something went wrong in fetching bank template!',
                stackTrace: err,
            };
            return result;
        }
    );
}




