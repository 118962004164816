import React from 'react';
import { Modal } from 'react-bootstrap';

type CdsModalProps = {
    show: boolean;
    handleClose: () => void;
    handleShow: () => void;
    size?: 'sm' | 'lg' | 'xl';
    modalTitle: string;
    headerDataTestId?: string;
    children?: any;
};

const CdsModal: React.FC<CdsModalProps> = (props) => {
    const { show, handleClose, size, modalTitle } = props;
    return (
        <Modal
            onClose={handleClose}
            size={size}
            show={show}
            onHide={handleClose}
        >
            <Modal.Header  closeButton>
                <Modal.Title data-testid={props.headerDataTestId}>{modalTitle}</Modal.Title>
                <p className="btn-modal-close" onClick={() => handleClose()}>
                    <i className="fa fa-times text-danger"></i>
                </p>
            </Modal.Header>
            <Modal.Body>{props.children}</Modal.Body>
        </Modal>
    );
};

export default CdsModal;
