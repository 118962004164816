import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { RootState } from '../../../sagas/slice/rootReducer';
import { FIND_ALL_ROLE } from '../../../sagas/types/role';
import { useFormik } from 'formik';
import CdsTitle from '../../../components/CdsTitle';

type RoleListProps = {};

const RoleList: React.FC<RoleListProps> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const goToCreateRole = () => {
        navigate('/cp/roles/createrole', { replace: true });
    };
    const initialValue = {
        RoleName: '',
    };

    useEffect(() => {
        
        dispatch({ type: FIND_ALL_ROLE });
    }, [dispatch]);

    const myFormik = useFormik({
        initialValues: initialValue,
        onSubmit: (values) => {
            
            console.log(values);
            searchRole(values.RoleName);
        },
    });
    const searchRole = (RoleName: any) => {
        
        dispatch({ type: FIND_ALL_ROLE, reqData: RoleName });
    };
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;

    const rows = useSelector((state: RootState) => state.roles.roles);
    console.log('data', rows);

    return (
        <div className="container-fluid">
            <CdsTitle
                linkto="/cp/roles/createrole"
                title="Role Master"
                iconClass="bi bi-people"
                link="Add Role"
                headerDataTestId = 'titleRoleMaster'
                linkDataTestId = 'btnAddRole'
            ></CdsTitle>
            {/* <div className="row justify-content-Start ">
                <div className=" mt-4 ps-5 col">
                    <h3 className="iconClass=bi bi-people">  ROLE MASTER </h3>
                </div>
                <div className=" mt-4 pe-4 col-2 justify-content-end">
                    <button
                        onClick={() => goToCreateRole()}
                        className="w-100 btn btn-default"
                    >
                        Add Role
                    </button>
                </div>
            </div> */}

            <div className="card mt-3  rounded-3">
                <div className="card-header">
                    <form
                        onSubmit={handleSubmit}
                        className="row my-2 justify-content-between"
                    >
                        <div className="col">
                            <div className="input-group">
                                <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                >
                                    <i className="bi bi-search"></i>
                                </span>
                                <input
                                    data-testid='inputSearch'
                                    type="text"
                                    className="form-control"
                                    id="RoleName"
                                    name="RoleName"
                                    value={values.RoleName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Role Name"
                                ></input>
                            </div>
                        </div>
                        <div className="col-2">
                            <button  data-testid='btnSearch' className="w-100 btn btn-default">
                                Search
                            </button>
                        </div>
                    </form>
                </div>
                <div className="card-body">
                    <table   data-testid='roleTable' className="table table-striped">
                        <thead>
                            <tr className="">
                                <th scope="col">
                                    <h6 className="my-2 semi-bold">Role ID</h6>
                                </th>
                                <th scope="col">
                                    <h6 className="my-2 semi-bold">
                                        Role Name
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">
                                        Description
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">Status</h6>
                                </th>
                                {/* <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">Edit</h6>
                                </th> */}
                                {/* <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">View</h6>
                                </th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {rows?.map((item: any,index:number) => {
                                return (
                                    <tr data-testid={`role-row${index}`} >
                                        <td data-testid={`role-Id${index}`} >{item.RoleID}</td>
                                        <td data-testid={`role-name${index}`} >{item.RoleName}</td>
                                        <td data-testid={`role-description${index}`} >{item.Description}</td>
                                        <td data-testid={`role-IsActive${index}`} >{item.IsActive}</td>
                                        {/* <td>
                                            {
                                                <Link to={``}>
                                                    <button className="btn">
                                                        <i className="fa-2x bi bi-pencil-square"></i>
                                                    </button>
                                                </Link>
                                            }
                                        </td> */}
                                        {/* <td>
                                            {
                                                <button className="btn">
                                                    <i className="bi bi-trash"></i>
                                                </button>
                                            }
                                        </td> */}
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default RoleList;
