import React, { useEffect, useState } from 'react';
import '../AddBank.css';
import { useFormik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { addBankSchema } from '../../schema/Bank';
import Bank from '../../../model/BankModel';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_BANK } from '../../../sagas/types/bank';
import { RootState } from '../../../sagas/slice/rootReducer';
import CdsTitle from '../../../components/CdsTitle';
import CdsButton from '../../../components/CdsButton';

type AddBankProps = {};

const AddBank: React.FC<AddBankProps> = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { error, isSaving, status } = useSelector(
        (state: RootState) => state.banks.bank.save
    );

    const initialValue: Bank = {
        id: 0,
        bankname: '',
        address: '',
        contact: '',
        city: '',
        pincode: '',
        bankmobileNo: '',
        bankemail: '',
        active: '',
    };

    useEffect(() => {
        if (status != null && status) {
            console.log('display success message');
            navigate('/cp/bank', { replace: true });
        } else {
            console.log('display failed message');
        }
    }, [status]);

    const myFormik = useFormik({
        initialValues: initialValue,
        validationSchema: addBankSchema,
        onSubmit: (values: Bank) => {
            console.log(values);
            dispatch({ type: CREATE_BANK, bank: values });
            navigate('/cp/bank', { replace: true });
        },
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;

    const toList = () => {
        navigate('/cp/bank', { replace: true });
    };

    return (
        <form onSubmit={handleSubmit} className="mb-4 container-fluid">
            <CdsTitle
                linkto="/cp/bank"
                title="Bank"
                iconClass="bi bi-bank"
                link="Back To List"
                headerDataTestId = 'titlebank'
                linkDataTestId = 'backToList'
            ></CdsTitle>

            <div className="card mt-3 border rounded-4">
                <div className="card-header">
                    <h6 data-testid='titleAddBank' className="md-18">Add Bank Details</h6>
                </div>
                <div className="card-body">
                    <div className="row my-4">
                        <div className="col">
                            <label htmlFor="bankName" className="form-label">
                                <h6 data-testid='labelBankName'>Bank Name</h6>
                            </label>
                            <input
                            data-testid='bankNameInput'
                                type="text"
                                className="form-control"
                                name="bankname"
                                value={values.bankname}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                id="bankName"
                                placeholder="Enter Bank Name"
                            ></input>
                            {errors.bankname && touched.bankname ? (
                                <p  data-Testid='errorBankName' className="form-val form-error">
                                    {errors.bankname}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="address" className="form-label">
                                <h6  data-Testid='labelAddress'>Address</h6>
                            </label>
                            <input
                             data-Testid='inputAddress'
                                type="text"
                                className="form-control"
                                id="address"
                                name="address"
                                value={values.address}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Address"
                            ></input>
                            {errors.address && touched.address ? (
                                <p  data-Testid='errorAddress' className="form-val form-error">
                                    {errors.address}
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <div className="row my-4">
                        {/* <div className="col">
                            <label htmlFor="contactNo" className="form-label">
                                <h6>Contact Number</h6>
                            </label>
                            <input
                                type="tel"
                                className="form-control"
                                id="contactNo"
                                name="contact"
                                value={values.contact}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Contact Number"
                            ></input>
                            {errors.contact && touched.contact ? (
                                <p className="form-val form-error">
                                    {errors.contact}
                                </p>
                            ) : null}
                        </div> */}

                        <div className="col">
                            <label htmlFor="city" className="form-label">
                                <h6  data-Testid='labelCity'>City</h6>
                            </label>
                            <input
                             data-Testid='inputCity'
                                type="text"
                                className="form-control"
                                id="city"
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter City"
                            ></input>
                            {errors.city && touched.city ? (
                                <p  data-Testid='errorCity' className="form-val form-error">
                                    {errors.city}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="pincode" className="form-label">
                                <h6  data-Testid='labelPincode'>Pin Code</h6>
                            </label>
                            <input
                             data-Testid='inputPincode'
                                type="text"
                                className="form-control"
                                id="pincode"
                                name="pincode"
                                value={values.pincode}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter PIN Code"
                            ></input>
                            {errors.pincode && touched.pincode ? (
                                <p  data-Testid='errorPincode' className="form-val form-error">
                                    {errors.pincode}
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <div className="row my-4">
                        <div className="col">
                            <label
                                htmlFor="bankMobileNo"
                                className="form-label"
                            >
                                <h6  data-Testid='labelBankContactNumber'>Bank Contact Number</h6>
                            </label>
                            <input
                             data-Testid='inputContactNumber'
                                type="tel"
                                className="form-control"
                                id="bankMobiletNo"
                                name="bankmobileNo"
                                value={values.bankmobileNo}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Bank Contact Number"
                            ></input>
                            {errors.bankmobileNo && touched.bankmobileNo ? (
                                <p  data-Testid='errorContactNumber' className="form-val form-error">
                                    {errors.bankmobileNo}
                                </p>
                            ) : null}
                        </div>

                        <div className="col">
                            <label htmlFor="email" className="form-label">
                                <h6  data-Testid='labeBankEmail'>Bank Email</h6>
                            </label>
                            <input
                             data-Testid='inputEmail'
                                type="text"
                                className="form-control"
                                id="email"
                                name="bankemail"
                                value={values.bankemail}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Bank Email ID"
                            ></input>
                            {errors.bankemail && touched.bankemail ? (
                                <p   data-Testid='errorBankEmail'className="form-val form-error">
                                    {errors.bankemail}
                                </p>
                            ) : null}
                        </div>
                        {/* <div className=" col-1 my-4 form-check form-switch form-check-reverse ">
                            <div className="slider">
                                <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchCheckChecked"
                                >
                                    <h6>Status</h6>
                                </label>
                                <input
                                    checked
                                    className="form-check-input"
                                    type="checkbox"
                                    name="active"
                                    value={values.active}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    role="switch"
                                    id="flexSwitchCheckDefault"S
                                />
                            </div>
                        </div> */}
                    </div>
                    <div className="justify-content-end row">
                        <div  data-testid='btnCancel' className="col-auto">
                            <CdsButton 
                            
                                title="Cancel"
                                type="button"
                                handleClick={(e) => navigate('/cp/bank')}
                            />
                        </div>
                        <div  data-testid='btnSubmit' className="col-auto">
                            <CdsButton  title="Submit" type="submit" />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default AddBank;
