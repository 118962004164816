import { useState } from 'react';
import CdsButton from '../../components/CdsButton';
import { CustomerAndBankFieldMapperInfo } from '../../model/CustomerAndBankFieldMapperInfo';

type TemplateFieldMapperFormDialogProps = {
    bankFieldName: string;
    data: Array<CustomerAndBankFieldMapperInfo>;
    handleOnSave?: (data: CustomerAndBankFieldMapperInfo) => void;
    handleOnCancel: () => void;
};
const TemplateFieldMapperFormDialog: React.FC<
    TemplateFieldMapperFormDialogProps
> = (props) => {
    // save mapping
    const [selectedField, setSelectedField] =
        useState<CustomerAndBankFieldMapperInfo>();
    const handlerSave = () => {};
    return (
        <div className="container-fluid">
            <form>
                <div className="row">
                    <div className="col">
                        <label htmlFor="email" className="form-label">
                            <h6>Bank Field Name:</h6>
                        </label>
                        <span className="badge bg-light text-dark">
                            <h6>{props.bankFieldName}</h6>
                        </span>
                    </div>
                </div>
                <div className="row mt-4">
                    <label className="form-label">
                        <h6>Customer Field List:</h6>
                    </label>
                    <ol className="list-group list-group-numbered">
                        {props.data.map((item, index) => (
                            <li
                                className="list-group-item d-flex justify-content-between align-items-start"
                                key={index}
                            >
                                <div className="ms-2 me-auto">
                                    {item.customerFieldName}
                                </div>
                            </li>
                        ))}
                    </ol>
                </div>
                <div className="justify-content-end row mt-4">
                    <div className="col-4">
                        <CdsButton
                            title="Cancel"
                            type="button"
                            handleClick={(e) => props.handleOnCancel()}
                        />
                    </div>
                    <div className="col-4">
                        <CdsButton
                            title="Save"
                            type="button"
                            handleClick={(e) => console.log(e)}
                        />
                    </div>
                </div>
            </form>
        </div>
    );
};

export default TemplateFieldMapperFormDialog;
