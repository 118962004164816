import { all, call, put, takeLatest } from "redux-saga/effects";
import { createCustomerRequest, deleteCustomerByIdRequest, findAllCustomersRequest, findCustomerByIdRequest, updateCustomerRequest } from "../requests/Customer";
import {
  addCustomerSlice,
  deleteCustomerSlice,
  editCustomerSlice,
  fetchAllCustomer,
  fetchAllCustomerFailed,
  fetchAllCustomerSuccess,
  findAllCustomersSlice
} from "../slice/customer/Customers";
import Customer from "../../model/CustomerModel";
import { CREATE_CUSTOMER, DELETE_CUSTOMER_BY_ID, FIND_ALL_CUSTOMERS, FIND_CUSTOMER_BY_ID, UPDATE_CUSTOMER_BY_ID } from "../types/customer";
import { setCustomerSlice } from "../slice/customer/Customer";
import { FIND_BANK_BY_ID } from "../types/bank";

// typings for action TBD...
function* fetchCustomerListSaga(action: any) {
  try {
    
    const response: Array<Customer> = yield call(findAllCustomersRequest, action.reqData);
    yield put(fetchAllCustomerSuccess(response));
  } catch (e: any) {
    yield put(fetchAllCustomerFailed(e));
  }
}

function* CustomerListSaga() {
  yield all([takeLatest(fetchAllCustomer, fetchCustomerListSaga)]);
}

//new
export function* findAllCustomersSaga(action){
  
  const response: Array<Customer> = yield call(findAllCustomersRequest , action.reqData)
  yield put(findAllCustomersSlice(response));
}
export function* findCustomerByIdSaga(action) {
  const response = yield findCustomerByIdRequest(action.id);
  yield put(setCustomerSlice(response));
}

export function* updateCustomerSaga(action) {
  
  // error case to be handled
  const response = yield updateCustomerRequest(action.customer);
  yield put(editCustomerSlice(response));
}

export function* deleteCustomerByIdSaga(action) {
  yield deleteCustomerByIdRequest(action.id);
  yield put(deleteCustomerSlice(action));
}
export function* createCustomerSaga(action){
  
  yield createCustomerRequest(action.customer);
  yield put(addCustomerSlice(action));
}

function* customerSaga(){
  yield all([takeLatest(fetchAllCustomer, fetchCustomerListSaga)]);
  
  yield all ([takeLatest(FIND_ALL_CUSTOMERS , findAllCustomersSaga)]);
  yield all ([takeLatest(CREATE_CUSTOMER , createCustomerSaga)]);
  yield all([takeLatest(DELETE_CUSTOMER_BY_ID, deleteCustomerByIdSaga)]);
  yield all([takeLatest(UPDATE_CUSTOMER_BY_ID, updateCustomerSaga)]);
  yield all([takeLatest(FIND_CUSTOMER_BY_ID, findCustomerByIdSaga)]);
}

export default customerSaga;
