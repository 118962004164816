import { all, call, put, takeLatest } from 'redux-saga/effects';
import { getErrorMessage } from '../../utility';
import {
    createWorkflowRequest,
    updateWorkflowStatusRequest,
    workFlowListRequest,
} from '../requests/Workflow';
import {
    addWorkflowFailed,
    addWorkflowSuccess,
    findAllWorkflowFailed,
    findAllWorkflowSlice,
    resetSaveWorkflow,
    statusChangeFailure,
    statusChangeSuccess,
    triggerSaveWorkflow,
} from '../slice/workflow/workflows';
import {
    CREATE_WORKFLOW,
    UPDATE_WORKFLOW_STATUS,
    WORKFLOW_LIST,
} from '../types/workflow';
import {
    setworkflowSlice,
    updateWorkflowFailure,
    updateWorkflowSuccess,
} from '../slice/workflow/workflow';

export function* createWorkflowSaga(action) {
    try {
        
        yield put(triggerSaveWorkflow());
        console.log(action.workflow);
        const response = yield createWorkflowRequest(action.workflow);
        if (response) {
            yield put(addWorkflowSuccess(response));
        } else {
            yield put(
                addWorkflowFailed(
                    getErrorMessage('Error in creating new workflow!')
                )
            );
        }
    } catch (error) {
        yield put(addWorkflowFailed(
            getErrorMessage('Error in creating new workflow!', error)
        ));
    }
}

export function* findAllWorkflows(action) {
    try {
        
        yield put(resetSaveWorkflow());
        const { data, error } = yield call(workFlowListRequest, action.reqData);
        if (error) {
            yield put(findAllWorkflowFailed(error));
        } else {
            yield put(findAllWorkflowSlice(data.data));
        }
    } catch (err) {
        yield put(findAllWorkflowFailed(err));
    }
}
export function* updateWorkflowStatusSaga(action) {
    try {
        
        const { data, error } = yield call(
            updateWorkflowStatusRequest,
            action.workflowStatus
        );
        if (error) {
            
            yield put(statusChangeFailure(error));
        } else {
            yield put(statusChangeSuccess(data.data));
        }
    } catch (err) {
        yield put(statusChangeFailure(err));
    }
}
function* workflowSaga() {
    yield all([takeLatest(CREATE_WORKFLOW, createWorkflowSaga)]);
    yield all([takeLatest(WORKFLOW_LIST, findAllWorkflows)]);
    yield all([takeLatest(UPDATE_WORKFLOW_STATUS, updateWorkflowStatusSaga)]);
}
export default workflowSaga;
