import { all, call, put, takeLatest } from 'redux-saga/effects';
import { findAllCustomerTemplateConfiguredHeadersRequest } from '../requests/CustomerTemplateConfiguredHeader';
import {
    findAllCustomerTemplateConfiguredHeadersFailed,
    findAllCustomerTemplateConfiguredHeadersSuccess,
} from '../slice/template-mapper/CustomerTemplateConfiguredHeader';
import { FIND_ALL_CUSTOMER_TEMPLATE_CONFIGURED_HEADERS } from '../types/template-mapper';

export function* findAllCustomerTemplateConfiguredHeadersSaga(action) {
    try {
        const { data, error } = yield call(
            findAllCustomerTemplateConfiguredHeadersRequest,
            action.data
        );
        if (error) {
            yield put(findAllCustomerTemplateConfiguredHeadersFailed(error));
        } else {
            yield put(findAllCustomerTemplateConfiguredHeadersSuccess(data));
        }
    } catch (err) {
        yield put(findAllCustomerTemplateConfiguredHeadersFailed(err));
    }
}

function* customerTemplateConfiguredHeaderSaga() {
    /// new
    yield all([
        takeLatest(
            FIND_ALL_CUSTOMER_TEMPLATE_CONFIGURED_HEADERS,
            findAllCustomerTemplateConfiguredHeadersSaga
        ),
    ]);
}

export default customerTemplateConfiguredHeaderSaga;
