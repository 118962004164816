import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import ReduxError from '../../../model/ErrorModel';
import { CustomerTemplateModel } from '../../../model/CustomerTemplateModel';

// Define a type for the slice state
interface CustomerTemplateHeaderState {
    isFetched: boolean;
    allHeaders: Array<CustomerTemplateModel>;
    error: ReduxError | null;
}

// Define the initial state using that type
const initialState: CustomerTemplateHeaderState = {
    isFetched: false,
    allHeaders: new Array<CustomerTemplateModel>(),
    error: null,
};

export const customerTemplateHeadersSlice = createSlice({
    name: 'customer-template-header',
    initialState,
    reducers: {
        findAllCustomerTemplateHeadersSuccess: (
            state,
            action: PayloadAction<Array<CustomerTemplateModel>>
        ) => {
            state.allHeaders = action.payload;
            return state;
        },
        findAllCustomerTemplateHeadersFailed: (
            state,
            action: PayloadAction<ReduxError>
        ) => {
            state.isFetched = true;
            state.allHeaders = [];
            state.error = action.payload;
        },
    },
});

export const {
    findAllCustomerTemplateHeadersSuccess,
    findAllCustomerTemplateHeadersFailed,
} = customerTemplateHeadersSlice.actions;
export default customerTemplateHeadersSlice.reducer;
