import axios from "axios";
import Role from "../../model/RoleModel";
import API from "../../constants/Api";
import { ApiResponse } from "../../model/ApiResponseModel";

export async function createRoleRequest(payload: Role): Promise<boolean> {
    
    const body = {
        RoleID : payload.RoleID,
        RoleName : payload.RoleName,
        Description : payload.Description,
        //IsActive : payload.IsActive
    };
    return await axios({
        method: API.TYPE.POST,
        url: API.ROLE.CREATE_ROLE,
        data: body,
    }).then((response) => {
        
        console.log(response.data);
        return response.data;
    });
}

export async function findAllRolesRequest(
    requestPayload: any): Promise<ApiResponse> {
        const result: ApiResponse = {
            data: null,
            error: null,
        };
const body = {
    RoleName : requestPayload
};
return await axios({
    method: API.TYPE.POST,
    url: API.ROLE.GET_ROLE_LIST,
    data: body
}).then((response) => {
    
    // set response to data
    console.log(response);
    result.data = response.data.RoleMasterList;
    return result.data;
});
}
