import { Field } from 'formik';
import CdsFormErrorMessage from '../../components/CdsFormErrorMessage';

type CustomizeTemplateMapperFormProps = {
    form: any;
    index: number;
};
const myInput = ({ field, form, ...props }) => {
    return <input {...field} {...props} />;
};
const CustomizeTemplateMapperForm: React.FC<
    CustomizeTemplateMapperFormProps
> = (props) => {
    return (
        <div key={props.index} className="accordion-item">
            <h2 className="accordion-header" id={`heading_${props.index}`}>
                <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#item_${props.index}`}
                    aria-expanded="false"
                    aria-controls={`item_${props.index}`}
                >
                    Accordion Item #{props.index}
                </button>
            </h2>
            <div
                id={`item_${props.index}`}
                className="accordion-collapse collapse"
                aria-labelledby={`heading_${props.index}`}
                data-bs-parent="#accordionExample"
            >
                <div className="accordion-body">
                    <strong>
                        This is template {props.index} item's accordion body.
                    </strong>{' '}
                    <div>
                        <div className="row my-4">
                            <div className="col">
                                <div className="col-6">
                                    <label className="form-label">
                                        <h6>Bank Email</h6>
                                    </label>
                                    <Field
                                        name={`templateMappingInfo.${props.index}.bankemail`}
                                        placeholder="enter email"
                                        component={myInput}
                                        className="form-control"
                                    />
                                    <CdsFormErrorMessage
                                        name={`templateMappingInfo.${props.index}.bankemail`}
                                        myForm={props.form}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CustomizeTemplateMapperForm;
