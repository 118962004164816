import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../CustomerFile.css';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../sagas/slice/rootReducer';
import { useFormik } from 'formik';
import { GET_CUSTOMERFILE_LIST } from '../../../sagas/types/customer-file';
import CdsTitle from '../../../components/CdsTitle';
import { UserDetails } from '../../../utility';
import CdsButton from '../../../components/CdsButton';

const CustomerFileList = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const uploadTemplate = () => {
        navigate('/cp/customertemplate/customertemplateupload', { replace: true });
    };
    const customerFileList = useSelector(
        (state: RootState) => state.customerFile.customerfileList
    );
    console.log('list', customerFileList);

    const initialValue = {
        FileName: '',
    };

    const downloadfile = (url) => {
        window.open(url);
    };

    useEffect(() => {
        // tbd
        
        const userData = UserDetails();
        if (userData.RoleID === '5') {
            // admin
            dispatch({
                type: GET_CUSTOMERFILE_LIST,
                reqData: {
                    CompanyID: userData.CompanyID,
                    FileName: '',
                },
            });
        } else {
            // customer
            dispatch({
                type: GET_CUSTOMERFILE_LIST,
                reqData: {
                    FileName: '',
                    CompanyID: userData.CompanyID,
                },
            });
        }
    }, [dispatch]);

    const myFormik = useFormik({
        initialValues: initialValue,
        onSubmit: (values) => {
            
            console.log(values);
            searchCustomerFile(values.FileName);
        },
    });
    const searchCustomerFile = (fileName: string) => {
        
        const userData = UserDetails();
        dispatch({
            type: GET_CUSTOMERFILE_LIST,
            reqData: {
                FileName: fileName,
                CompanyID: userData.CompanyID,
            },
        });
    };
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;

    return (
        <div className="container-fluid">
            <CdsTitle
                linkto="/cp/customertemplate/customertemplateupload"
                title="Setup Customer File"
                iconClass="bi bi-file-excel"
                link="Add File"
                headerDataTestId = 'titleSetupCustomerFile'
                linkDataTestId = 'btnAddFile'
            ></CdsTitle>
            {/* <div className="row justify-content-Start ">
        <div className=" mt-4 ps-5 col">
          <h3 className="iconClass=bi bi-file-excel"> CUSTOMER TEMPLATE</h3>
        </div>
        <div className="mt-4 pe-4 col-2 justify-content-end">
          <button onClick={uploadTemplate} className="w-100 btn btn-default">Add Template</button>
        </div>
      </div> */}
            <div className="card mt-3 rounded-3">
                <div className="card-header">
                    <form
                        onSubmit={handleSubmit}
                        className="row my-2 justify-content-between"
                    >
                        <div className="col">
                            <div className="input-group">
                                <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                >
                                    <i className="bi bi-search"></i>
                                </span>
                                <input
                                data-testid='inputSearch'
                                    type="text"
                                    className="form-control"
                                    id="customerName"
                                    name="FileName"
                                    placeholder="Enter File Name"
                                    value={values.FileName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                ></input>
                            </div>
                        </div>
                        <div className="col-2">
                            <button
                            data-testid='btnSearch'
                                type="submit"
                                className="w-100 btn btn-default"
                            >
                                Search
                            </button>
                        </div>
                    </form>
                </div>
                <div className="card-body">
                    <table  data-testid='tableCustomer' className="table table-striped">
                        <thead>
                            <tr className="">
                                <th scope="col">
                                    <h6 className="my-2 semi-bold">
                                        Customer Name
                                    </h6>
                                </th>
                                <th scope="col">
                                    <h6 className="my-2 semi-bold">
                                        Bank Name
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">
                                        Payment Type
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">
                                        File Name
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">Download</h6>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {customerFileList?.map(
                                (item: any, index: number) => {
                                    return (
                                        <>
                                            <tr data-testid={`customer-row${index}`}  key={index}>
                                                <td data-testid={`customer-Name${index}`} >{item.CustomerName}</td>
                                                <td data-testid={`customer-BankName${index}`} >{item.BankName}</td>
                                                <td data-testid={`customer-PaymentType${index}`} >{item.PaymentType}</td>
                                                <td data-testid={`customer-FileName${index}`} >{item.fileName}</td>
                                                <td data-testid={`btnDownload${index}`}>
                                                <CdsButton
                                                    title=""
                                                    type="button"
                                                    customClass="bi bi-download"
                                                    handleClick={() => {
                                                        const formattedCustomerName = item.CustomerName.replace(/\s+/g, '_');
                                                        downloadfile(
                                                            `https://paybrijapi.copiacs.com/UploadedFile/${formattedCustomerName}_${item.CustomerID}/Config/Input/${item.fileName}`
                                                        )
                                                    }}
                                                ></CdsButton>
                                            </td>
                                            </tr>
                                        </>
                                    );
                                }
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CustomerFileList;
