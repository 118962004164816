import { createContext, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from './useLocalStorage';
import API from '../constants/Api';
import axios from 'axios';

const AuthContext = createContext(null);

export const AuthProvider = ({ children, userData }) => {
    const [user, setUser] = useLocalStorage('user', userData);
    const [roleId, setRoleID] = useLocalStorage('roleID', userData);
    const navigate = useNavigate();
    // const login = async (reqData) => {
    //     debugger;
    //     //axios.post('http://localhost:63062/api/login/user',{
    //     url: axios
    //         .post(`${API.LOGIN.SING_IN}`, {
    //             Email: reqData.Email,
    //             Password: reqData.Password,
    //         })
    //         .then(function (response) {
    //             console.log('set', response.data.LoginResponse[0]);

    //             if (response.data.LoginResponse[0].loginResult) {
    //                 setUser(response.data.LoginResponse);
    //                 setRoleID(response.data.LoginResponse[0].RoleID);

    //                 if (response.data.LoginResponse[0].RoleID == '1') {
    //                     navigate('/cp/users', { replace: true });
    //                 } else if (response.data.LoginResponse[0].RoleID == '5') {
    //                     navigate('/cp/bank', { replace: true });
    //                 }
    //             } else {
    //                 alert('Invalid Username or Password');
    //             }
    //             //alert("login successful");
    //         })
    //         .catch(function (error) {
    //             console.log(error);
    //             alert('login failed');
    //         });
    // };

    const login = async (reqData) => {
        try {
            const response = await axios.post(`${API.LOGIN.SING_IN}`, {
                Email: reqData.Email,
                Password: reqData.Password,
            });

            if (response.data.LoginResponse[0].loginResult) {
                setUser(response.data.LoginResponse);
                setRoleID(response.data.LoginResponse[0].RoleID);

                if (response.data.LoginResponse[0].RoleID == '1') {
                    navigate('/cp/users', { replace: true });
                } else if (response.data.LoginResponse[0].RoleID == '5') {
                    navigate('/cp/bank', { replace: true });
                }
            } else {
                return 'Invalid Username or Password';
            }
        } catch (error) {
            return 'Login failed. Please try again.';
        }
    };

    const logout = () => {
        setUser(null);
        navigate('/', { replace: true });
    };

    const resetPassword = async (userData) => {
        try {
            const response: any = await axios({
                method: API.TYPE.POST,
                url: API.LOGIN.RESET_PASSWORD,
                data: userData,
            });
            if (response.data) {
                navigate('/loginpage', { replace: true });
                alert('Password reset successfuly');
            } else {
                alert('Failed to reset password');
            }
        } catch (error) {
            alert('Failed to reset password');
        }
    };

    const value = useMemo(
        () => ({
            user,
            login,
            logout,
            resetPassword,
        }),
        [user]
    );

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
