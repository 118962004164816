import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from '../../../sagas/slice/rootReducer';
import { useFormik } from 'formik';
import User from '../../../model/UserModel';
import { addUserSchema } from '../../schema/User';
import { UPDATE_USER, FIND_USER_BY_ID } from '../../../sagas/types/user';
import CdsTitle from '../../../components/CdsTitle';
import CdsButton from '../../../components/CdsButton';
import { UserDetails } from '../../../utility';
type EditUserProps = {};

const EditUser: React.FC<EditUserProps> = (props) => {
    //==
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const params = useParams();
    const user: User = useSelector((state: RootState) => state.user.user);
    const { status } = useSelector((state: RootState) => state.users.user.save);
    const userData = UserDetails();
    const initialValue: User = {
        //==
        //BankID :'',
        RoleID: '',
        UserID: params.id,
        CustomerID: '',
        CustomerName: '',
        UserName: '',
        UserEmail: '',
        UserMobile: '',
        UserStatus: '',
        RoleName: '',
        //Password:''
        // Date:''
    };

    const myFormik = useFormik({
        //==
        initialValues: initialValue,
        validationSchema: addUserSchema,
        onSubmit: (values) => {
            const data = {
                UserID: params.id,
                UserName: values.UserName,
                UserMobile: values.UserMobile,
                RoleID: userData.RoleID,
                ComapnyId: userData.CompanyID,
            };
            dispatch({ type: UPDATE_USER, user: data });
            navigate('/cp/users', { replace: true });
        },
    });

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;

    useEffect(() => {
        //==
        dispatch({ type: FIND_USER_BY_ID, id: params.id });
    }, [dispatch]);

    useEffect(() => {
        //==
        if (user) {
            onEditSetUserValues(user);
        }
    }, [user]);

    useEffect(() => {
        if (status != null && status) {
            console.log('display success message');
            navigate('/cp/user', { replace: true });
        } else {
            console.log('display failed message');
        }
    }, [status]);

    const onEditSetUserValues = (user: any) => {
        //==
        myFormik.setValues({
            //BankID :'',
            UserID: params.id,
            CustomerID: userData.CompanyID,
            CustomerName: user.CustomerName,
            UserName: user.UserName,
            UserEmail: user.UserEmail,
            UserMobile: user.UserMobile,
            UserStatus: user.UserStatus,
            RoleName: userData.RoleName,
            //Password:user.Password,
            // Date:user.Date,
            RoleID: user.RoleID,
        });
    };

    return (
        // <div></div>
        <div>
            <div className="container-fluid">
                <CdsTitle
                    linkto="/cp/users"
                    title="User"
                    iconClass="bi bi-people"
                    link="Back To List"
                    headerDataTestId = 'titleuser'
                linkDataTestId = 'backtolist'
                ></CdsTitle>
                <form
                    onSubmit={handleSubmit}
                    className="card mt-3 border rounded-4"
                >
                    <div className="card-header">
                        <h6 data-testid='titleEditUser' className="md-18">Add User Details</h6>
                        {/* <span className="gray-font h6">Enter details carefully:</span> */}
                    </div>
                    {user ? (
                        <div className="card-body">
                            <div className="row my-1">
                                <div className="col">
                                    <label
                                        htmlFor="customerName"
                                        className="form-label"
                                    >
                                        <h6 data-testid='editCustomerName'>Customer Name</h6>
                                    </label>
                                    <input
                                    data-testid='inputEditCustname'
                                        type="text"
                                        className="form-control"
                                        name="customername"
                                        value={values.CustomerName}
                                        id="customerName"
                                        placeholder="Enter Customer Name"
                                        disabled
                                    ></input>
                                </div>
                                <div className="col">
                                    <label
                                        htmlFor="address"
                                        className="form-label"
                                    >
                                        <h6 data-testid='editName'>Name</h6>
                                    </label>
                                    <input
                                    data-testid='inputEditName'
                                        type="text"
                                        className="form-control"
                                        id="UserName"
                                        name="UserName"
                                        value={values.UserName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter Name"
                                    ></input>
                                    {errors.UserName && touched.UserName ? (
                                        <p data-testid='errorEditName' className="form-val form-error">
                                            {errors.UserName}
                                        </p>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row my-4">
                                <div className="col">
                                    <label
                                        htmlFor="contactNo"
                                        className="form-label"
                                    >
                                        <h6 data-testid='editContactNumber'>User Contact Number</h6>
                                    </label>
                                    <input
                                    data-testid='inputEditContactNo'
                                        type="number"
                                        className="form-control"
                                        id="UserMobile"
                                        name="UserMobile"
                                        value={values.UserMobile}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter Contact Number"
                                    ></input>
                                    {errors.UserMobile && touched.UserMobile ? (
                                        <p data-testid='errorEditContactNo' className="form-val form-error">
                                            {errors.UserMobile}
                                        </p>
                                    ) : null}
                                </div>

                                <div className="col">
                                    <label
                                        htmlFor="email"
                                        className="form-label"
                                    >
                                        <h6 data-testid='editEmail'>User Email</h6>
                                    </label>
                                    <input
                                    data-testid='inputEditEmail'
                                        type="text"
                                        className="form-control"
                                        id="UserEmail"
                                        name="UserEmail"
                                        value={values.UserEmail}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter Email"
                                        disabled={true}
                                    ></input>
                                    {errors.UserEmail && touched.UserEmail ? (
                                        <p data-testid='errorEditEmail' className="form-val form-error">
                                            {errors.UserEmail}
                                        </p>
                                    ) : null}
                                </div>
                                {/* <div className="col">
                                <label
                                    htmlFor="Password"
                                    className="form-label"
                                >
                                    <h6>Password</h6>
                                </label>
                                <input
                                    type="Password"
                                    className="form-control"
                                    id="Password"
                                    name="Password"
                                    value={values.Password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Password"
                                ></input>
                                {errors.Password && touched.Password ? (
                                    <p className="form-val form-error">
                                        {errors.Password}
                                    </p>
                                ) : null}
                            </div> */}
                            </div>
                            <div className="row justify-content-start my-4">
                                {/* <div className="col-6">
                                <label htmlFor="role" className="form-label">
                                    <h6>Role</h6>
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="Role"
                                    name="Role"
                                    value={userData.RoleName} 
                                    disabled
                                ></input>
                            </div> */}

                                {/* <div className="col-3">
                                <label htmlFor="date" className="form-label">
                                    <h6>Date</h6>
                                </label>
                                <input
                                    type="date"
                                    className="form-control"
                                    id="date"
                                    name="date"
                                    value={values.Date}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder=""
                                ></input>
                                {errors.Date && touched.Date ? (
                                    <p className="form-val form-error">
                                        {errors.Date}
                                    </p>
                                ) : null}
                            </div> */}
                                {/* <div className=" col-1 my-4 form-check form-switch form-check-reverse ">
                                <div className="slider">
                                    <label
                                        className="form-check-label"
                                        htmlFor="flexSwitchCheckChecked"
                                    >
                                        <h6>Status</h6>
                                    </label>
                                    <input
                                        checked
                                        className="form-check-input"
                                        type="checkbox"
                                        name="active"
                                        value={values.UserStatus}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        role="switch"
                                        id="flexSwitchCheckDefault"
                                    />
                                </div>
                            </div> */}
                            </div>
                            <div className="justify-content-end  row">
                                <div data-testid='editCancel' className="col-auto">
                                    <CdsButton
                                        title="Cancel"
                                        type="button"
                                        handleClick={(e) =>
                                            navigate('/cp/users', {
                                                replace: true,
                                            })
                                        }
                                    />
                                </div>
                                <div data-testid='editSubmit' className="col-auto">
                                    <CdsButton
                                        title="Submit"
                                        type="submit"
                                        handleClick={(e) => handleSubmit()}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : null}
                </form>
            </div>
        </div>
    );
};

export default EditUser;
