import { CustomerTemplateModel } from '../model/CustomerTemplateModel';
import ReduxError from '../model/ErrorModel';
import { data } from '../model/FaqModel';
import loginUser from '../model/LoginUserModel';

export const validFileTypes = [
    'xml',
    'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'xlsx',
    'png',
];

export const isFileEmpty = (fileRef): boolean => {
    let valid = true;
    if (fileRef) {
        const files = fileRef?.current?.files;
        if (files) {
            const fileArr = Array.from(files);
            fileArr.forEach((file: any) => {
                if (file.size <= 0) {
                    valid = false;
                    return valid;
                }
            });
        }
    }
    return valid;
};

export const isValidFileType = (fileRef): boolean => {
    
    let valid = true;
    if (fileRef) {
        const files = fileRef?.current?.files;
        if (files) {
            const fileArr = Array.from(files);
            fileArr.forEach((file: any) => {
                const type = file.type.split('/')[1];
                if (!validFileTypes.includes(type)) {
                    valid = false;
                    return valid;
                }
            });
        }
    }
    return valid;
};

export const getErrorMessage = (
    message: string,
    stackTrace?: any
): ReduxError => {
    return {
        message,
        stackTrace,
    };
};

export const generateFakeBankTemplateFields =
    (): Array<CustomerTemplateModel> => {
        const list = new Array<CustomerTemplateModel>();
        for (let index = 1; index < 25; index++) {
            const element: CustomerTemplateModel = {
                id: index,
                isSelected: false,
                name: `header-field-${index}`,
            };
            list.push(element);
        }
        return list;
    };

export const generateFakeBankTemplateMappedFields =
    (): Array<CustomerTemplateModel> => {
        const list = new Array<CustomerTemplateModel>();
        for (let index = 1; index < 10; index++) {
            const element: CustomerTemplateModel = {
                id: index,
                isSelected: false,
                name: `header-field-${index}`,
            };
            list.push(element);
        }
        return list;
    };

export const fakeAuth = () => {
    new Promise((resolve) => {
        setTimeout(() => resolve('2342f2f1d131rf12'), 250);
    });
};

export const UserDetails = (): loginUser => {
    
    const data: loginUser = JSON.parse(localStorage.getItem('user'));
    if (data != null) {
        return data[0];
    }
};
