import axios from "axios";
import UserModel from "../../model/UserModel";
import API from "../../constants/Api";
import { ApiResponse } from "../../model/ApiResponseModel";
import User from "../slice/user/User";

export async function findAllUsersRequest(
    requestPayload: any
): Promise<ApiResponse> {
    
    const result: ApiResponse = {
        data: null,
        error: null,
    };
    const body = {
        UserName: requestPayload.UserName,
        CompanyID: requestPayload.CompanyID,
    };
    return await axios({
        method: API.TYPE.POST,
        url: API.USER.GET_USER_LIST,
        data: body,
    }).then((response) => {
        
        // set response to data
        console.log(response);
        result.data = response.data;
        return result.data;
    });
}
export async function findUserByIdRequest(
    selectedBankId: number
): Promise<UserModel> {
    
    return await axios({
        method: API.TYPE.POST,
        url: API.USER.GET_USER_BY_ID,
        data: { UserID: selectedBankId },
    }).then((response) => {
        
        console.log(response.data);
        // status code , add if
        return response.data.UserDetails[0];
    });
}
export async function createUserRequest(payload: UserModel): Promise<boolean> {
    const body = {
        CompanyID: payload.CustomerID,
        UserName: payload.UserName,
        UserEmail: payload.UserEmail,
        UserMobile: payload.UserMobile,
        //Password: payload.Password,
        RoleID: payload.RoleID,
    };
    return await axios({
        method: API.TYPE.POST,
        url: API.USER.CREATE_USER,
        data: body,
    }).then((response) => {
        console.log(response.data);
        return response.data;
    });
}
export async function updateUserRequest(
    payload: UserModel
): Promise<UserModel> {
    const body = {
        UserID: payload.UserID,
        UserName: payload.UserName,
        UserEmail: payload.UserEmail,
        UserMobile: payload.UserMobile,
        //Password: payload.Password,
        RoleID: payload.RoleID,
    };
    return await axios({
        method: API.TYPE.POST,
        url: API.USER.UPDATE_USER,
        data: body,
    }).then(
        (response) => {
            console.log(response.data);
            return response.data;
        },
        (err) => {
            return false;
        }
    );
}
