import { getIn } from 'formik';

type CdsFormErrorMessageProps = {
    myForm: any;
    name: string;
};
const CdsFormErrorMessage: React.FC<CdsFormErrorMessageProps> = (props) => {
    console.log(props.myForm.errors);
    const error = getIn(props.myForm.errors, props.name);
    const touch = getIn(props.myForm.touched, props.name);
    return touch && error ? (
        <label className="form-label" style={{ color: 'red' }}>
            {error}
        </label>
    ) : null;
};
export default CdsFormErrorMessage;
