import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import '../PaymentType.css';
import { useFormik } from 'formik';
import { addNewPaymentTypeSchema } from '../../schema/PaymentType';
import axios from 'axios';
import PaymentTypes from '../../../model/PaymentTypesModel';
import { useDispatch, useSelector } from 'react-redux';
import { CREATE_PAYMENT_TYPE } from '../../../sagas/types/payment-types';
import CdsTitle from '../../../components/CdsTitle';
import CdsButton from '../../../components/CdsButton';
import { GET_ALL_BANKS } from '../../../sagas/types/bank-file';
import { RootState } from '../../../sagas/slice/rootReducer';

type AddPaymentTypeProps = {};
const AddNewPaymentType: React.FC<AddPaymentTypeProps> = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const initialValue: PaymentTypes = {
        id: 0,
        paymentType: '',
        description: '',
        // bankname: '',
        //paymentid: '',
        // active: '',
    };
    const myFormik = useFormik({
        initialValues: initialValue,
        validationSchema: addNewPaymentTypeSchema,
        onSubmit: (values: PaymentTypes) => {
            
            console.log(values);
            dispatch({ type: CREATE_PAYMENT_TYPE, paymentType: values });
            navigate('/cp/paymenttype');
        },
    });
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;

    //console.log(values);
    return (
        <form onSubmit={handleSubmit} className="container-fluid">
            <CdsTitle
                linkto="/cp/paymenttype"
                title="Payment Type"
                iconClass="bi bi-cash-coin"
                link="Back To List"
                headerDataTestId = 'titlePaymentType'
                linkDataTestId = 'btnbackToList'
            ></CdsTitle>
            <div className="card mt-3 border rounded-4">
                <div className="card-header">
                    <h6  data-testid='titleAddPayment' className="md-18">Add Payment Type</h6>
                </div>
                <div className="card-body">
                    <div className="row my-4">
                        {/* <div className="col">
                            <label htmlFor="bankName" className="form-label">
                                <h6>Bank Name</h6>
                            </label>
                            <select
                                id="BankID"
                                name="BankID"
                                value={values.BankID}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-select"
                            >
                                <option selected>Select Bank Name</option>
                                {rows?.map((item: any, index: number) => {
                                    return (
                                        <>
                                            <option value={item.BankID}>
                                                {item.BankName}
                                            </option>
                                        </>
                                    );
                                })}
                            </select>
                            {errors.BankID && touched.BankID ? (
                                <p className="form-val form-error">
                                    {errors.BankID}
                                </p>
                            ) : null}
                        </div> */}
                        <div className="col">
                            <label htmlFor="paymentType" className="form-label">
                                <h6 data-testid='labelPaymentType'>Payment Type</h6>
                            </label>
                            <input
                            data-testid='inputPaymentType'
                                type="text"
                                className="form-control"
                                id="paymentType"
                                placeholder="Enter Payment Type"
                                name="paymentType"
                                value={values.paymentType}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            ></input>
                            {errors.paymentType && touched.paymentType ? (
                                <p  data-testid='errorPaymentType' className="form-val form-error">
                                    {errors.paymentType}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label htmlFor="description" className="form-label">
                                <h6 data-testid='labelDescription'>Description</h6>
                            </label>
                            <input
                            data-testid='inputDescription'
                                type="text"
                                className="form-control"
                                id="description"
                                name="description"
                                value={values.description}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Description"
                            ></input>
                            {errors.description && touched.description ? (
                                <p data-testid='errorDescription' className="form-val form-error">
                                    {errors.description}
                                </p>
                            ) : null}
                        </div>
                        {/* <div className="col">
                            <label htmlFor="paymentId" className="form-label">
                                <h6>Payment ID</h6>
                            </label>
                            <input
                                type="number"
                                className="form-control"
                                id="paymentId"
                                name="paymentid"
                                value={values.paymentid}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="Enter Payment ID"
                            ></input>
                            {errors.paymentid && touched.paymentid ? (
                                <p className="form-val form-error">
                                    {errors.paymentid}
                                </p>
                            ) : null}
                        </div> */}
                    </div>
                    <div className="row justify-content-evenly my-4">
                        <div className="col">
                            <div className="justify-content-end row">
                                <div data-testid='btnCancel' className="col-auto">
                                    <CdsButton
                                        title="Cancel"
                                        type="button"
                                        handleClick={(e) =>
                                            navigate('/cp/paymenttype')
                                        }
                                    />
                                </div>
                                <div  data-testid='btnSubmit' className="col-auto">
                                    <CdsButton
                                        title="Submit"
                                        type="submit"
                                        handleClick={(e) => handleSubmit()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default AddNewPaymentType;
