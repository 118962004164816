import * as Yup from "yup";

export const addBankSchema = Yup.object().shape({
  bankname: Yup.string().min(2).max(25).required("Please enter your bank name"),
  address: Yup.string().min(5).max(80).required("Please enter your address"),
  // contact: Yup.string()
  // .matches(/^[0-9]+$/, "Must be only digits")
  // .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
  // .required("Please enter your contact number"),
  city: Yup.string().min(2).max(15).required("Please enter your city"),
  pincode: Yup.string()
  .required("Please enter your pincode")
  .matches(/^[0-9]+$/, "Must be only digits")
  .matches(/^\d{6}$/, "Pincode must be exactly 6 digits"),
  bankemail: Yup.string().email().required("Please enter your bank email"),
  bankmobileNo: Yup.string()
  .matches(/^[0-9]+$/, "Must be only digits")
  .matches(/^\d{10}$/, "Phone number must be exactly 10 digits")
  .required("Please enter your bank contact number"),
});
