export const data = [
    {
        question :'Q1. We have SAP system, do these adapters work with SAP ?',
        key : "1",
        answer : 'Yes, we have standard adapters  for SAP and SAP S/4 HANA. You just need to copy the code inyour SAP system and it would be compatible with your ERP. Apart from SAP, we also have standard adapters for Tally &amp; Oracle ERPs.'
    },
    {
        question :'Q2. We use our homegrown ERP solution will these work with this ERP? ',
        key : "2",
        answer :'Yes, these adapters can work with any ERP or for that matter any IT systems. All you need is extract the relevant file from your ERP in .XLS format and rest will be automatic.'
    },
    {
        question :'Q3. We need to have reports on daily basis and monthly basis, how can it support it? ',
        key : "3",
        answer :'There are multiple reports which are generated on daily and monthly basis. You can select and choose relevant reports needed and system will automatically place these reports on your system at a set frequency'
    },
    {
        question :'Q4. We use Linux operating system, do these adapters work on Linux? ',
        key : "4",
        answer :'Yes, these are OS agnostic and it can work on any Windows or Linux system'
    },
    {
        question :'Q5. Can these adapters send emails with report attachments?',
        key : "5",
        answer :'Yes, this is a standard feature available. You will need to provide a dedicated email address with credentials and allow email this email through your gateway. With that it can be setup to send reports emails as well as many other emails.'
    },
]