import React, { useState } from 'react';

import CdsTitle from '../../components/CdsTitle';

import Workflow from '../../model/WorkflowModel';

import { Link, useNavigate, useParams } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { RootState } from '../../sagas/slice/rootReducer';

import WorkflowList from '../../model/WorkflowlistModel';

import { string } from 'yup';

// const UserData = JSON.parse(localStorage.getItem('user'));

// const initialValue: Workflow = {

//     UserID: UserData[0].UserID,

//     FileTransmission: null,

//     FileTransmissionID: null,

//     NeedEncryption: null,

//     FilesInBanksFormat: null,

//     OperatingSystemID: null,

//     ErpComment: '',

//     AdditionalRequirement: '',

//     WorkflowStatus: 1,

// };

// const handleshow = (event) => {

//     initialValue.FileTransmission = event.target.value;

//     const getdropdownValue = event.target.value;

//     setShow(getdropdownValue);

// };

// const [show, setShow] = useState(null);

type VieweWorkFlowsProps = {};

const ViewWorkFlow: React.FC<VieweWorkFlowsProps> = (props) => {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    const params = useParams();

    // const workflow : Workflow = useSelector((state:RootState)=> state.workflow. workflow)

    const WorkflowList: Workflow = useSelector(
        (state: RootState) => state.workflow.workflow
    );

    // const bank: Bank = useSelector((state: RootState) => state.bank.bank);

    return (
        <div>
            <CdsTitle
                linkto="/cp/workflows"
                title="Workflow View"
                iconClass="bi bi-filetype-xml"
                link="Back To List"
            ></CdsTitle>

            <div className="card mt-3 border rounded-4">
                <div className="card-header">
                    <h6 className="md-18">Workflow View</h6>
                </div>

                <div className="card-body">
                    <div className="row my-4">
                        <div className="col-6">
                            <label
                                htmlFor="FileTransmission"
                                className="form-label"
                            >
                                <h6>Do you need File Transmission? *</h6>
                            </label>
                            <br />

                            <label
                                // onChange={(e) => handleshow(e)}

                                id="FileTransmission"
                            >
                                <h6>Input from user</h6>
                            </label>
                        </div>

                        <div className="col-6">
                            <label
                                htmlFor="FileTransmissionID"
                                className="form-label"
                            >
                                <h6>Select Transmission Type *</h6>
                            </label>
                            <br />

                            <label>
                                <h6>Input from user</h6>
                            </label>
                        </div>
                    </div>

                    <div className="row justify-content-start my-4">
                        <div className="col">
                            <label
                                htmlFor="NeedEncryption"
                                className="form-label"
                            >
                                <h6>Need Encryption? *</h6>
                            </label>

                            <br />

                            <label>
                                <h6>Input from user</h6>
                            </label>
                        </div>

                        <div className="col">
                            <label
                                htmlFor="OperatingSystemID"
                                className="form-label"
                            >
                                <h6>Server Operating System *</h6>
                            </label>{' '}
                            <br />
                            <label>
                                <h6>Input from user</h6>
                            </label>
                        </div>
                    </div>

                    <div className="row my-4">
                        <div className="col">
                            <label
                                htmlFor="FilesInBanksFormat"
                                className="form-label"
                            >
                                <h6>
                                    Does your ERP provide files in Bank Required
                                    format? *
                                </h6>
                            </label>{' '}
                            <br />
                            <label>
                                <h6>Input from user</h6>
                            </label>
                        </div>

                        <div className="col">
                            <label htmlFor="Textarea" className="form-label">
                                <h6>ERP Specific Comments</h6>
                            </label>{' '}
                            <br />
                            <label>
                                <h6>Input from user</h6>
                            </label>
                        </div>
                    </div>

                    <div className="row my-4">
                        <div>
                            <label htmlFor="Textarea1" className="form-label">
                                <h6>Additional Requirements</h6>
                            </label>{' '}
                            <br />
                            <label>
                                <h6>Input from user</h6>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ViewWorkFlow;
