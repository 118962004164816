import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { UserDetails, isFileEmpty, isValidFileType } from '../../../utility';
import { useDispatch, useSelector } from 'react-redux';
import {
    GET_ALL_BANKS,
    GET_ALL_PAYMENT_TYPES,
} from '../../../sagas/types/bank-file';
import { RootState } from '../../../sagas/slice/rootReducer';
import {
    GET_ALL_CUSTOMERS,
    UPLOAD_CUSTOMER_FILE,
} from '../../../sagas/types/customer-file';
import UploadCustomerFile from '../../../model/UploadCustomerFileModal';
import CdsTitle from '../../../components/CdsTitle';
import CdsButton from '../../../components/CdsButton';
import { userInfo } from 'os';

type CustomerFileUploadProps = {};
const CustomerFileUpload: React.FC<CustomerFileUploadProps> = (props) => {
    const userData = UserDetails();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userInfo = UserDetails();
    const initialValue: UploadCustomerFile = {
        BankID: '',
        PaymentTypeID: '',
        File: '',
        CustomerID: userData.CompanyID,
        files: '',
        FileName: '',
        CustomerName: userData.CustomerName,
        //ID: '',
        //BankName: '',
        //PaymentType: '',
        //CustomerName: '',
    };
    useEffect(() => {
        dispatch({ type: GET_ALL_BANKS });
        dispatch({ type: GET_ALL_PAYMENT_TYPES });
        dispatch({ type: GET_ALL_CUSTOMERS });
    }, [dispatch]);

    const rows = useSelector((state: RootState) => state.bankNames.bankNames);
    const type = useSelector(
        (state: RootState) => state.paymentTypeName.paymentTypeId
    );
    const customers = useSelector(
        (state: RootState) => state.customerFile.uploadCustomerFile
    );

    console.log(rows, type);

    const fileRef = useRef(null);

    const customerFileUploadSchema = Yup.object({
        BankID: Yup.string().required('Select your Bank Name'),
        PaymentTypeID: Yup.string().required('Please select Payment Type'),
        //CustomerID: Yup.string().required('Select Customer'),
        files: Yup.string()
            .required('Please select file')
            .test(
                'is-file-of-correct-type',
                'File is not of supported type',
                () => {
                    return isValidFileType(fileRef);
                }
            )
            .test('is-file-empty', 'File should not be empty', () => {
                return isFileEmpty(fileRef);
            }),
    });

    const myFormik = useFormik({
        initialValues: initialValue,
        validationSchema: null,
        onSubmit: (values: UploadCustomerFile) => {
            console.log(values, errors);
            dispatch({ type: UPLOAD_CUSTOMER_FILE, upload: values });
            navigate('/cp/customertemplate', { replace: true });
        },
    });
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;
    console.log(myFormik);

    const handleFileUpload = () => {
        var reader = new FileReader();
        reader.readAsDataURL(fileRef.current.files[0]);
        reader.onload = (_event) => {
            myFormik.setFieldValue('FileName', fileRef.current.files[0].name);
            myFormik.setFieldValue('File', reader.result);
        };
    };
    return (
        <form onSubmit={handleSubmit} className="container-fluid">
            <CdsTitle
                linkto="/cp/customertemplate"
                title="Setup Customer File"
                iconClass="bi bi-file-excel"
                link="Back To List"
                headerDataTestId="titlesetupCustomerFile"
                linkDataTestId="btnBackToList"
            ></CdsTitle>
            <div className="card mt-3 border rounded-4">
                <div className="card-header">
                    <h6 data-testid="titleAddCustomer" className="md-18">
                        Add Customer File
                    </h6>
                </div>
                <div className="card-body">
                    <div className="row my-4">
                        {userData.RoleID == '1' && (
                            <div className="col">
                                <label
                                    htmlFor="CompanyID"
                                    className="form-label"
                                >
                                    <h6 data-testid="labelCustomerName1">
                                        Customer Name
                                    </h6>
                                </label>
                                <input
                                    data-testid="inputCustomerName1"
                                    type="text"
                                    className="form-control"
                                    value={userInfo.CustomerName}
                                    // disabled={true}
                                ></input>
                                {/* <select
                                    id="CustomerID"
                                    name="CustomerID"
                                    value={values.CustomerID}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="form-select"
                                >
                                    <option selected>
                                        Select Customer Name
                                    </option>
                                    {customers?.map(
                                        (item: any, index: number) => {
                                            return (
                                                <>
                                                    <option
                                                        value={item.CustomerID}
                                                    >
                                                        {item.CustomerName}
                                                    </option>
                                                </>
                                            );
                                        }
                                    )}
                                </select> */}
                                {errors.CustomerID && touched.CustomerID ? (
                                    <p
                                        data-testid="errorCustomerName1 "
                                        className="form-val form-error"
                                    >
                                        {errors.CustomerID}
                                    </p>
                                ) : null}
                            </div>
                        )}
                        {userData.RoleID == '5' && (
                            <div className="col">
                                <label
                                    htmlFor="CompanyID"
                                    className="form-label"
                                >
                                    <h6 data-testid="labelCustomerName">
                                        Customer Name
                                    </h6>
                                </label>
                                {/* <input
                                type="text"
                                className="form-control"
                                value={userInfo.CustomerName}
                               // disabled={true}
                            ></input> */}
                                <select
                                    data-testid="selectCustomer"
                                    id="CustomerID"
                                    name="CustomerID"
                                    value={values.CustomerID}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    className="form-select"
                                >
                                    <option selected>
                                        Select Customer Name
                                    </option>
                                    {customers?.map(
                                        (item: any, index: number) => {
                                            return (
                                                <>
                                                    <option
                                                        value={item.CustomerID}
                                                    >
                                                        {item.CustomerName}
                                                    </option>
                                                </>
                                            );
                                        }
                                    )}
                                </select>
                                {errors.CustomerID && touched.CustomerID ? (
                                    <p
                                        data-testid="errorCustomerName"
                                        className="form-val form-error"
                                    >
                                        {errors.CustomerID}
                                    </p>
                                ) : null}
                            </div>
                        )}
                        <div className="col">
                            <label htmlFor="BankID" className="form-label">
                                <h6 data-testid="labelBankName">Bank Name</h6>
                            </label>
                            <select
                                data-testid="selectBankId"
                                id="BankID"
                                name="BankID"
                                value={values.BankID}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                className="form-select"
                            >
                                <option selected>Select Bank Name</option>
                                {rows?.map((item: any, index: number) => {
                                    return (
                                        <>
                                            <option value={item.BankID}>
                                                {item.BankName}
                                            </option>
                                        </>
                                    );
                                })}
                            </select>
                            {errors.BankID && touched.BankID ? (
                                <p
                                    data-testid="errorBankName"
                                    className="form-val form-error"
                                >
                                    {errors.BankID}
                                </p>
                            ) : null}
                        </div>
                        <div className="col">
                            <label className="form-label">
                                <h6 data-testid="labelPaymentType">
                                    Payment Type
                                </h6>
                            </label>
                            <select
                                data-testid="selectPaymentType"
                                className="form-select"
                                id="PaymentTypeID"
                                name="PaymentTypeID"
                                value={values.PaymentTypeID}
                                onChange={handleChange}
                                onBlur={handleBlur}
                            >
                                <option selected>Select Payment Type</option>
                                {type?.map((item: any, index: number) => {
                                    return (
                                        <>
                                            <option value={item.PaymentTypeID}>
                                                {item.PaymentType}
                                            </option>
                                        </>
                                    );
                                })}
                            </select>
                            {errors.PaymentTypeID && touched.PaymentTypeID ? (
                                <p
                                    data-testid="errorPaymentType"
                                    className="form-val form-error"
                                >
                                    {errors.PaymentTypeID}
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <div className="row my-4">
                        <label htmlFor="addFile" className="form-label">
                            <h6 data-testid="labelAddFile">Add File</h6>
                        </label>
                        <div className="input-group col">
                            <input
                                data-testid="inputAddFile"
                                type="file"
                                className="form-control"
                                name="File"
                                ref={fileRef}
                                {...fileRef}
                                onChange={(e) => {
                                    handleFileUpload();
                                    handleChange(e);
                                }}
                                onBlur={handleBlur}
                            />
                            {errors.File && touched.File ? (
                                <p
                                    data-testid="errorAddFile"
                                    className="form-val form-error"
                                >
                                    {errors.File}
                                </p>
                            ) : null}
                        </div>
                    </div>
                    <div className="justify-content-end row">
                        <div data-testid="btnCancel" className="col-auto">
                            <CdsButton
                                title="Cancel"
                                type="button"
                                handleClick={(e) =>
                                    navigate('/cp/customertemplate')
                                }
                            />
                        </div>
                        <div data-testid="btnSubmit" className="col-auto">
                            <CdsButton
                                title="Submit"
                                type="submit"
                                handleClick={(e) => handleSubmit()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default CustomerFileUpload;
