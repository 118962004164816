import axios from 'axios';
import API from '../../constants/Api';
import UploadCustomerFile from '../../model/UploadCustomerFileModal';
import { ApiResponse } from '../../model/ApiResponseModel';

export async function findAllCustomerFileRequest(
    requestPayload: any
): Promise<ApiResponse> {
    const result: ApiResponse = {
        data: null,
        error: null,
    };
    
    const body = {
        CompanyId: requestPayload.CompanyID,
        CustomerFileName: requestPayload.FileName,
    };
    return await axios({
        method: API.TYPE.POST,
        url: API.CUSTOMERFILE.GET_CUSTOMERFILE_LIST,
        data: body,
    }).then(
        (response) => {
            // set response to data
            
            console.log(response.data.CustomerFileList);
            result.data = response.data.CustomerFileList;
            return result.data;
        },
        (err) => {
            result.error = {
                message: 'some issue',
                stackTrace: err.message,
            };
            return result;
        }
    );
}
export async function uploadCustomerFileRequest(
    payload: UploadCustomerFile
): Promise<boolean> {
    const body = {
        BankID: payload.BankID,
        PaymentTypeID: payload.PaymentTypeID,
        CustomerID: payload.CustomerID,
        file: payload.File,
        fileName: payload.FileName,
        customerName: payload.CustomerName
    };
    
    return await axios({
        method: API.TYPE.POST,
        url: API.CUSTOMERFILE.UPLOAD_CUSTOMER_FILE,
        data: body,
    }).then((response) => {
        // set response to data
        console.log(response.data);
        return response.data;
    });
}
export async function findAllCustomerNamesWithIdRequest(
    requestPayload: any
): Promise<Array<UploadCustomerFile>> {
    
    let data: Array<UploadCustomerFile> = new Array<UploadCustomerFile>();
    const body = {};
    return await axios({
        method: API.TYPE.GET,
        url: API.CUSTOMERFILE.GET_CUSTOMER_LIST_ID,
    }).then((response) => {
        // set response to data
        console.log(response.data.CustomerNames);
        data = response.data.CustomerNames;
        return data;
    });
}
