import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
    findBankTemplateSuccess,
    findBankTemplateFailed,
} from '../slice/template-mapper/BankTemplate';
import { FIND_BANK_TEMPLATE, FIND_BANK_TEMPLATE_BY_ID } from '../types/template-mapper';
import {  findBankTemplateRequest } from '../requests/BankTemplate';
import { resetSaveBankTemp } from '../slice/template-mapper/BankTemplates';

export function* findBankTemplateSaga(action) {
    try {
        const { data, error } = yield call(
            findBankTemplateRequest,
            action.data
        );
        if (error) {
            yield put(findBankTemplateFailed(error));
        } else {
            yield put(findBankTemplateSuccess(data));
        }
    } catch (err) {
        yield put(findBankTemplateFailed(err));
    }
}



function* bankTemplateSaga() {
    /// new
    yield all([takeLatest(FIND_BANK_TEMPLATE, findBankTemplateSaga)]);
    
}

export default bankTemplateSaga;
