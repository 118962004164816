import axios from 'axios';
import { useFormik } from 'formik';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Route, Router, useNavigate } from 'react-router-dom';
import { fetchAllBanks } from '../../../sagas/slice/bank/Banks';
import { RootState } from '../../../sagas/slice/rootReducer';
import { FIND_ALL_BANKS } from '../../../sagas/types/bank';
import '../AddBank.css';
import CdsTitle from '../../../components/CdsTitle';
import CdsButton from '../../../components/CdsButton';

const BankList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    //const { banks } = useSelector((state: RootState) => state.banks);
    const rows = useSelector((state: RootState) => state.banks.banks);

    const handleDelete = (ID: number) => {
        console.log('delete');
    };
    const initialValue = {
        BankName: '',
    };

    useEffect(() => {
        
        dispatch({ type: FIND_ALL_BANKS });
    }, [dispatch]);

    const myFormik = useFormik({
        initialValues: initialValue,
        onSubmit: (values) => {
            console.log(values);
            searchBank(values.BankName);
        },
    });

    const searchBank = (BankName: any) => {
        
        dispatch({ type: FIND_ALL_BANKS, reqData: BankName });
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;

        // const searchbutton = () => {
        //     const navigate = useNavigate();
        // }

    return (
        <div className="container-fluid">
            <CdsTitle 
                linkto="/cp/bank/createbank"
                title="Bank"
                iconClass="bi bi-bank"
                link="Add Bank"
                headerDataTestId = 'bankTitle'
                linkDataTestId = 'addBanklink'
            ></CdsTitle>
            <div className="card mt-3 rounded-3">
                <div className="card-header">
                    <form
                        onSubmit={handleSubmit}
                        className="row my-2 justify-content-between"
                    >
                        <div className="col ">
                            <div className="input-group">
                                <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                >
                                    <i className="bi bi-search"></i>
                                </span>
                                <input 
                                    data-testid="searchBank"
                                    type="text"
                                    className="form-control"
                                    name="BankName"
                                    value={values.BankName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="BankName"
                                    placeholder="Enter Bank Name"
                                ></input>
                            </div>
                        </div>

                        <div className="col-2">
                        {/* <CdsButton
                                title="Cancel"
                                type="button"
                                handleClick={(e) => navigate()}
                            /> */}
                                
                            <button data-testid="btnSearch"
                                type="submit"
                                className="w-100 btn btn-default"
                            >
                                Search
                            </button>
                        </div>
                    </form>
                </div>
                <div className="card-body">
                    <table data-testid='BankTable' className="table table-striped">
                        <thead>
                            <tr className="">
                                <th scope="col">
                                    <h6 className="my-2 semi-bold">
                                        Bank Name{' '}
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">
                                        Bank Contact
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">
                                        Bank Email
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">City</h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">Pin Code</h6>
                                </th>

                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">Edit</h6>
                                </th>
                            </tr>
                        </thead>
                        <tbody data-testid='tableBank'>
                            {rows?.map((item: any, index: number) => {
                                return (
                                    <>
                                        <tr data-testid={`bank-row-${index}`} key={index}>
                                            <td data-testid={`bank-name-${index}`}>{item.BankName}</td>

                                            <td data-testid={`bank-mobile-${index}`}>{item.Contact_Mobile}</td>
                                            <td data-testid={`bank-email-${index}`}>{item.Contact_Email}</td>
                                            <td data-testid={`bank-city-${index}`}>{item.City}</td>
                                            <td data-testid={`bank-pincode-${index}`}>{item.PinCode}</td>

                                            <td>
                                                <Link data-testid={`btnEdit-${index}`}
                                                    to={`/cp/bank/editbank/${item.ID}`}
                                                >
                                               
                                                    <button  className="btn ">
                                                        
                                                        <i className="fa-2x bi bi-pencil-square"></i>
                                                    </button>
                                                  
                                                </Link>
                                            </td>
                                        </tr>
                                    </>
                                );
                                
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default BankList;
