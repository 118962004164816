import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { RootState } from '../../../sagas/slice/rootReducer';
import { GET_BANKFILE_LIST } from '../../../sagas/types/bank-file';
import '../BankXmlList.css';
import { useFormik } from 'formik';
import CdsTitle from '../../../components/CdsTitle';
import CdsButton from '../../../components/CdsButton';

const BankXmlList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const bankFileList = useSelector(
        (state: RootState) => state.bankFileList.bankFileList
    );
    console.log('list', bankFileList);

    const initialValue = {
        BankFileName: '',
    };

    const downloadfile = (url) => {
        window.open(url);
    };

    useEffect(() => {
        dispatch({ type: GET_BANKFILE_LIST });
    }, [dispatch]);

    const myFormik = useFormik({
        initialValues: initialValue,
        onSubmit: (values) => {
            console.log(values);
            searchBankFile(values.BankFileName);
        },
    });
    const searchBankFile = (BankFileName: any) => {
        dispatch({ type: GET_BANKFILE_LIST, reqData: BankFileName });
    };
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;

    // const downloadfile = (fileName) => {
    //     // Replace the URL with your file download endpoint
    //     const downloadUrl =`http://paybrijapi.copiacs.com/UploadedFile/${fileName}`
    //     window.open(downloadUrl, '_blank');
    //   };

    return (
        <div className="container-fluid">
            <CdsTitle
                linkto="/cp/banktemplate/banktemplateupload"
                title="Bank Template"
                iconClass="bi bi-filetype-xml"
                link="Add Template"
                headerDataTestId = 'titleBankTemplate'
                linkDataTestId = 'btnAddFile'
            ></CdsTitle>
            {/* <div className="row justify-content-Start ">
                <div className=" mt-4 ps-5 col">
                    <h3 className="iconClass=bi bi-filetype-xml"> BANK TEMPLATE </h3>
                </div>
                <div className=" mt-4 pe-4 col-2 justify-content-end">
                    <Link
                        to="/cp/banktemplate/banktemplateupload"
                        className="w-100 btn btn-default"
                    >
                        Add Template
                    </Link>
                </div>
            </div> */}
            <div className="card mt-3 rounded-3">
                <div className="card-header">
                    <form
                        onSubmit={handleSubmit}
                        className="row my-2 justify-content-between"
                    >
                        <div className="col">
                            <div className="input-group">
                                <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                >
                                    <i className="bi bi-search"></i>
                                </span>
                                <input
                                    data-testid='inputSearch'
                                    type="text"
                                    className="form-control"
                                    name="BankFileName"
                                    value={values.BankFileName}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="BankFileName"
                                    placeholder="Enter File Name"
                                ></input>
                            </div>
                        </div>
                        <div className="col-2">
                            <button
                             data-testid='btnSearch'
                                type="submit"
                                className="w-100 btn btn-default"
                            >
                                Search
                            </button>
                        </div>
                    </form>
                </div>
                <div className="card-body">
                    <table data-testid='tableBankFile' className="table table-striped">
                        <thead>
                            <tr className="">
                                <th scope="col">
                                    <h6 className="my-2 semi-bold">
                                        Customer Name
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">
                                        Bank Name
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">
                                        Payment Type
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">File Name</h6>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {bankFileList?.map((item: any, index: number) => {
                                return (
                                    <>
                                        <tr data-testid={`bank-row${index}`} key={index}>
                                            <td data-testid={`bank-CustName${index}`}>{item.CustomerName}</td>
                                            <td data-testid={`bank-bankName${index}`}>{item.BankName}</td>
                                            <td data-testid={`bank-payment${index}`}>{item.PaymentType}</td>
                                            <td data-testid={`bank-filename${index}`}>{item.fileName}</td>
                                            <td>
                                                <CdsButton
                                                data-testid={`btnDownload${index}`}
                                                    title=""
                                                    type="button"
                                                    customClass="bi bi-download"
                                                    handleClick={() =>{
                                                        const formattedBankName = item.CustomerName.replace(/\s+/g, '_');
                                                        downloadfile(
                                                            `https://paybrijapi.copiacs.com/UploadedFile/${formattedBankName}_${item.CustomerID}/Config/Output/${item.fileName}`
                                                        )
                                                    }}
                                                ></CdsButton>
                                                {/* <Link to={`banktemplate/viewbank/${item.ID}`}>
                                            <button className="btn ">
                                            <i className="fa-2x bi bi-eye"></i>
                                            </button>
                                            </Link> */}
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default BankXmlList;
