import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import '../PaymentType.css';
import { useDispatch, useSelector } from 'react-redux';
import {
    DELETE_PAYMENT_TYPE_BY_ID,
    FIND_ALL_PAYMENT_TYPES,
} from '../../../sagas/types/payment-types';
import { RootState } from '../../../sagas/slice/rootReducer';
import CdsTitle from '../../../components/CdsTitle';

const PaymentType = () => {
    const dispatch = useDispatch();
    // useEffect(() => {
    //   //dispatch(fetchAllBanks());
    //   dispatch({ type: FIND_ALL_PAYMENT_TYPES });
    // }, [dispatch]);
    const initialValue = {
        PaymentType: '',
    };

    useEffect(() => {
        dispatch({ type: FIND_ALL_PAYMENT_TYPES });
    }, [dispatch]);

    const myFormik = useFormik({
        initialValues: initialValue,
        onSubmit: (values) => {
            console.log(values);
            searchPaymentType(values.PaymentType);
        },
    });
    const searchPaymentType = (PaymentType: any) => {
        dispatch({ type: FIND_ALL_PAYMENT_TYPES, reqData: PaymentType });
    };
    const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
        myFormik;
    const rows = useSelector(
        (state: RootState) => state.paymentTypes.paymentTypes
    );

    const handleDelete = (ID: number) => {
        dispatch({ type: DELETE_PAYMENT_TYPE_BY_ID, reqData: ID });
        dispatch({ type: FIND_ALL_PAYMENT_TYPES });
    };

    return (
        <div className="container-fluid">
            <CdsTitle
                linkto="/cp/paymenttype/createpaymenttype"
                title="Payment Type"
                iconClass="bi bi-cash-coin"
                link="Add New Type"
                headerDataTestId="titlePaymentType"
                linkDataTestId="btnAddNewType"
            ></CdsTitle>
            <div className="card mt-3 rounded-3">
                <div className="card-header">
                    <form
                        onSubmit={handleSubmit}
                        className="row my-2 justify-content-between"
                    >
                        <div className="col ">
                            <div className="input-group">
                                <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                >
                                    <i className="bi bi-search"></i>
                                </span>
                                <input
                                    data-testid="searchPayment"
                                    type="text"
                                    className="form-control"
                                    name="PaymentType"
                                    value={values.PaymentType}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    id="PaymentType"
                                    placeholder="Enter Payment Type"
                                ></input>
                            </div>
                        </div>
                        <div className="col-2">
                            <button
                                data-testid="btnSearch"
                                type="submit"
                                className="w-100 btn btn-default"
                            >
                                Search
                            </button>
                        </div>
                    </form>
                </div>
                <div className="card-body">
                    <table
                        data-testid="tablePayment"
                        className="table table-striped"
                    >
                        <thead>
                            <tr className="">
                                <th scope="col">
                                    <h6 className="my-2 semi-bold">
                                        Payment Type ID
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">
                                        Payment Type
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">
                                        Description
                                    </h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">Edit</h6>
                                </th>
                                <th className="" scope="col">
                                    <h6 className="my-2 semi-bold">Delete</h6>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows?.map((item: any, index: number) => {
                                return (
                                    <>
                                        <tr
                                            data-testid={`payment-row-${index}`}
                                            key={index}
                                        >
                                            <td
                                                data-testid={`payment-Id${index}`}
                                            >
                                                {item.ID}
                                            </td>
                                            <td
                                                data-testid={`payment-Type-${index}`}
                                            >
                                                {item.PaymentType}
                                            </td>
                                            <td
                                                data-testid={`payment-Description-${index}`}
                                            >
                                                {item.Description}
                                            </td>
                                            <td>
                                                <Link
                                                    to={`/cp/paymenttype/editpaymenttype/${item.ID}`}
                                                >
                                                    <button
                                                        data-testid={`btnEdit-${index}`}
                                                        className="btn "
                                                    >
                                                        <i className="fa-2x bi bi-pencil-square"></i>
                                                    </button>
                                                </Link>
                                            </td>
                                            <td>
                                                <button
                                                    data-testid={`btnDelete-${index}`}
                                                    className="btn"
                                                    onClick={() => handleDelete(item.ID)
                                                    }
                                                >
                                                    <i className="fa-2x bi bi-trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default PaymentType;
