import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ReduxError from "../../../model/ErrorModel";
import UploadCustomerFile from "../../../model/UploadCustomerFileModal";
import store from "../../../Store";
import CustomerFileList from "../../../model/CustomerFileListModel";

interface UploadCustomerFileState {
    isFetched: boolean;
    uploadCustomerFile: Array<UploadCustomerFile>;
    customerfileList: Array<CustomerFileList>
    error: ReduxError | null;
  }

  const initialState: UploadCustomerFileState = {
    isFetched: false,
    uploadCustomerFile: new Array<UploadCustomerFile>(),
    customerfileList : new Array<CustomerFileList>(),
    error: null,
  };

  export const CustomerFileSlice = createSlice({
    name: 'customerFile',
    initialState,
    reducers:{
      findAllCustomerFileSlice: (state, action: PayloadAction<CustomerFileList[]>) => {
        state.customerfileList = action.payload;
        return state;
    },
        uploadCustomerFileSlice:(state  , action: PayloadAction<UploadCustomerFile> ) => {
            state.uploadCustomerFile.push(action.payload);
            return state;
          },
          findAllCustomerNamesSlice: (state, action: PayloadAction<UploadCustomerFile[]>) => {
            state.uploadCustomerFile = action.payload;
            return state;
          },
    }
  });

  type RootState = ReturnType<typeof store.getState>;
  export const {
    uploadCustomerFileSlice,
    findAllCustomerNamesSlice,
    findAllCustomerFileSlice
  } = CustomerFileSlice.actions;
  
  // Other code such as selectors can use the imported `RootState` type
  export const selectAllCustomerFile = (state: RootState) => state.uploadCustomerFile.uploadCustomerFile;
  
  export default CustomerFileSlice.reducer;