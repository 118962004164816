import React from 'react';
import SortableTree from '@nosferatu500/react-sortable-tree';
import CdsButton from '../../components/CdsButton';

export default class XmlMapper extends React.Component<{
    treeData: any;
    currentStepId: number;
    customerTemplateFields: Array<string>;
    onStepperPrevious: (currentStepId: number) => void;
    onStepperNext: (data: any, currentStepId: number) => void;
}> {
    constructor(props) {
        super(props);
        this.state = {
            treeData: [],
        };
    }

    componentDidMount() {
        this.setState({ treeData: this.props.treeData });
    }

    render() {
        return (
            <div className="container-fluid">
                <form>
                    <div className="card mt-3 border rounded-4">
                        <div className="card-header">
                            <h6 className="md-18">Template Field Mapping</h6>
                        </div>
                        <div className="card-body">
                            <div className="row justify-content-evenly my-4">
                                <div className="col-8">
                                    <label
                                        htmlFor="bankContactNo"
                                        className="form-label"
                                    >
                                        <h6>Map XML Field</h6>
                                    </label>
                                    <div>
                                        {this.props.treeData.length ? (
                                            <div
                                                style={{
                                                    height: 810,
                                                    width: '100%',
                                                }}
                                            >
                                                <SortableTree
                                                    treeData={
                                                        this.props.treeData
                                                    }
                                                    onChange={(treeData) =>
                                                        this.setState({
                                                            treeData,
                                                        })
                                                    }
                                                />
                                            </div>
                                        ) : (
                                            <div>Template not exist</div>
                                        )}
                                    </div>
                                </div>
                                <div className="col-4">
                                    <label
                                        htmlFor="email"
                                        className="form-label"
                                    >
                                        <h6>Customer Template Field</h6>
                                    </label>
                                    <div>
                                        <ol className="list-group list-group-numbered">
                                            {this.props.customerTemplateFields.map(
                                                (item, index) => (
                                                    <li
                                                        className="list-group-item d-flex justify-content-between align-items-start"
                                                        key={index}
                                                    >
                                                        <div className="ms-2 me-auto">
                                                            {item}
                                                        </div>
                                                        <span className="badge bg-success rounded-pill">
                                                            Mapped
                                                        </span>
                                                    </li>
                                                )
                                            )}
                                            <li className="list-group-item d-flex justify-content-between align-items-start">
                                                <div className="ms-2 me-auto">
                                                    Dummy Field
                                                </div>
                                                <span className="badge bg-danger rounded-pill">
                                                    Not mapped
                                                </span>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                            <div className="justify-content-end  row">
                                <div className="col-2">
                                    <CdsButton
                                        title="Previous"
                                        type="button"
                                        handleClick={(e) =>
                                            this.props.onStepperPrevious(
                                                this.props.currentStepId
                                            )
                                        }
                                    />
                                </div>
                                <div className="col-2">
                                    <CdsButton
                                        title="Next"
                                        type="button"
                                        handleClick={(e) =>
                                            this.props.onStepperNext(
                                                null,
                                                this.props.currentStepId
                                            )
                                        }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
