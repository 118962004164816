import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import ReduxError from "../../../model/ErrorModel";
import PaymentTypes from "../../../model/PaymentTypesModel";
import store from "../../../Store";

interface PaymentTypeState {
    isFetched: boolean;
    paymentTypes: Array<PaymentTypes>;
    error: ReduxError | null;
  }
  
  // Define the initial state using that type
  const initialState: PaymentTypeState = {
    isFetched: false,
    paymentTypes: new Array<PaymentTypes>(),
    error: null,
  };

  export const paymentTypesSlice = createSlice({
    name: "paymentTypes",
    initialState,
    reducers: {
      // old
    //   fetchAllBanks: (state, action: PayloadAction) => {
    //     state.isFetched = false;
    //     state.banks = [];
    //     state.error = null;
    //   },
    //   fetchAllBanksSuccess: (state, action: PayloadAction<Bank[]>) => {
    //     state.isFetched = true;
    //     state.banks = action.payload;
    //   },
    //   fetchAllBanksFailed: (state, action: PayloadAction<ReduxError>) => {
    //     state.isFetched = true;
    //     state.banks = [];
    //     state.error = action.payload;
    //   },
      // new
      // how to handle error: tbd
      findAllPaymentTypesSlice: (state, action: PayloadAction<PaymentTypes[]>) => {
        state.paymentTypes = action.payload;
        return state;
      },
      addPaymentTypeSlice: (state, action: PayloadAction<PaymentTypes>) => {
        state.paymentTypes.push(action.payload);
        return state;
      },
      editPaymentTypeSlice: (state, action: PayloadAction<PaymentTypes>) => {
        state.paymentTypes = state.paymentTypes.map((item) =>
          item.id == action.payload.id ? action.payload : item
        );
        return state;
      },

      deletePaymentSlice: (state, action: PayloadAction<PaymentTypes>) => {
        // soft delete
        // TBD -> active/inactive
        state.paymentTypes = state.paymentTypes.map((item) =>
          item.id == action.payload.id ? action.payload : item
        );
        return state;
      },
    },
  });
  
  type RootState = ReturnType<typeof store.getState>;
  export const {
    findAllPaymentTypesSlice,
    addPaymentTypeSlice,
    deletePaymentSlice,
    editPaymentTypeSlice,
  } = paymentTypesSlice.actions;
  
  // Other code such as selectors can use the imported `RootState` type
  export const selectAllPaymentTypes = (state: RootState) => state.paymentTypes.paymentTypes;
  
  export default paymentTypesSlice.reducer;